import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";

import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";

const Home = () => {
  const { errors, register, handleSubmit } = useForm();
  const [id] = React.useState("form-1");
  const [file, setFile] = React.useState('')
  const [file20, setFile20] = React.useState('')
  const [file24, setFile24] = React.useState('')
  const [detailsList, setDetailsList] = React.useState({})
  const history = useHistory();


  const onFormSubmit = (e) => {
    const dataid = history.location.state?.detail._id
    const token = localStorage.getItem("token");

    const method = 'home/'
    const data = e


    if (dataid) {
      data.id = dataid
      if (file) {
        const formData = new FormData();
        formData.append("file", file, file.name);
        new ServiceStore().UploadFile('home/ImageUpload', formData,token).then((res) => {
          data.line16 = res.data.data.imagename
          new ServiceStore().UpdateData('home/updatedata', data,token).then((res) => {
            console.log("res", res)
            
          });
        })
      } else {
        data.line16 = detailsList.line16
      }
      if (file20) {
        const formData20 = new FormData();
        formData20.append("file", file20, file20.name);
        new ServiceStore().UploadFile('home/ImageUpload', formData20,token).then((res) => {
          data.line20 = res.data.data.imagename
          new ServiceStore().UpdateData('home/updatedata', data,token).then((res) => {
            console.log("res", res)
    
          });
        })
      } else {
        data.line20 = detailsList.line20
      }
      if (file24) {
        const formData24 = new FormData();
        formData24.append("file", file24, file24.name);
        new ServiceStore().UploadFile('home/ImageUpload', formData24,token).then((res) => {
          data.line24 = res.data.data.imagename
          new ServiceStore().UpdateData('home/updatedata', data,token).then((res) => {
            console.log("res", res)
    
          });
        })
      } else {
        data.line24 = detailsList.line24
      }
      console.log("data", data)
      new ServiceStore().UpdateData('home/updatedata', data,token).then((res) => {
        console.log("res", res)
        alert ("Data Updated Successfully")
        history.push("/homelist");
      });


    } else {

      const formData = new FormData();
      formData.append("file", file, file.name);


      const formData20 = new FormData();
      formData20.append("file", file20, file20.name);


      const formData24 = new FormData();
      formData24.append("file", file24, file24.name);

      new ServiceStore().UploadFile('home/ImageUpload', formData20,token).then((res) => {
        data.line20 = res.data.data.imagename

        new ServiceStore().UploadFile('home/ImageUpload', formData24,token).then((res) => {
          data.line24 = res.data.data.imagename

          new ServiceStore().UploadFile('home/ImageUpload', formData,token).then((res) => {
            console.log('imagename', res.data.data)
            data.line16 = res.data.data.imagename

            new ServiceStore().InsertData(method, data,token).then((res) => {
              console.log("res", res)
              alert ("data updated successfully")
              history.push("/homelist");

            });

          })
        })
      })
    }


  };
  const formClass = classNames({
    "form-validate": true

  });
  const onFileSelect = event => {
    const file = event.target.files[0];
    console.log("file", file)
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // toBase64
      setFile(file)
    } else {
      this.imgeerror = 'Please Select Max 100MB Size File'
    }
  }

  const onFileSelect20 = event => {
    const file = event.target.files[0];
    console.log("file", file)
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // toBase64
      setFile20(file)
    } else {
      this.imgeerror = 'Please Select Max 100MB Size File'
    }
  }
  const onFileSelect24 = event => {
    const file = event.target.files[0];
    console.log("file", file)
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // toBase64
      setFile24(file)
    } else {
      this.imgeerror = 'Please Select Max 100MB Size File'
    }
  }
  React.useEffect(() => {
    const dataid = history.location.state?.detail._id
    // console.log("data",dataid)
    if (dataid) {
      new ServiceStore().GetDataByID('home/', dataid).then((res) => {
        // console.log("res",res.data)
        setDetailsList(res.data)

      })
    }
  }, [])

  return (
    <React.Fragment>
      {/* <Head title="Form Validation"></Head> */}
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BlockTitle tag="h2" className="fw-normal">
              Home
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>

            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <React.Fragment>
              <Form className={formClass} onSubmit={handleSubmit(onFormSubmit)}>
                <Row className="g-gs">
                  {!history.location.state?.detail._id &&
                    <Col lg="7">
                      <div className="form-group">
                        <ul className="custom-control-group g-3 align-center flex-wrap">
                          <li>
                            <div className="custom-control custom-radio">
                              <input
                                ref={register({ required: true })}
                                type="radio"
                                className="custom-control-input"
                                defaultChecked
                                name="language"
                                id="reg-enable"
                                value="english"
                              />
                              <label className="custom-control-label" htmlFor="reg-enable">
                                English
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-radio">
                              <input
                                ref={register({ required: true })}
                                type="radio"
                                className="custom-control-input"
                                name="language"
                                id="reg-disable"
                                value="marathi"

                              />
                              <label className="custom-control-label" htmlFor="reg-disable">
                                Marathi
                              </label>
                            </div>
                          </li>

                        </ul>
                      </div>
                    </Col>
                  }
                  <div className="banner-tab">
                    <h3>Banner</h3>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner Line 1
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            defaultValue={detailsList?.line1}
                            type="text"
                            id="fv-full-name"
                            name="line1"
                            className="form-control"
                          />
                          {errors.line1 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">

                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner Line 2
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-full-name"
                            name="line2"
                            className="form-control"
                            defaultValue={detailsList?.line2}
                          />
                          {errors.line2 && <span className="invalid">This field is required</span>}
                        </div>

                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                        Banner  Line 3
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line3"
                            className="form-control"
                            defaultValue={detailsList?.line3}
                          />
                          {errors.line3 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                        Banner Button Name Line 4
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line4"
                            className="form-control"
                            defaultValue={detailsList?.line4}
                          />
                          {errors.line4 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                  </div>
                  <div className="banner-tab">
                    <h3>Achivements</h3>
                    <Col md="6">
                      <div className="form-group">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Banner 2 Column 1 Count
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line5"
                            className="form-control"
                            defaultValue={detailsList?.line5}
                          />
                          {errors.line5 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Banner 2 Column 1 Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line9"
                            className="form-control"
                            defaultValue={detailsList?.line9}
                          />
                          {errors.line9 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Banner 2 Column 2 Count
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line6"
                            className="form-control"
                            defaultValue={detailsList?.line6}
                          />
                          {errors.line6 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Banner 2 Column 2 Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line10"
                            className="form-control"
                            defaultValue={detailsList?.line10}
                          />
                          {errors.line10 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Banner 2 Column 3 Count
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line7"
                            className="form-control"
                            defaultValue={detailsList?.line7}
                          />
                          {errors.line7 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Banner 2 Column 3 Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line11"
                            className="form-control"
                            defaultValue={detailsList?.line11}
                          />
                          {errors.line11 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Banner 2 Column 4 Count
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line8"
                            className="form-control"
                            defaultValue={detailsList?.line8}
                          />
                          {errors.line8 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Banner 2 Column 4 Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line12"
                            className="form-control"
                            defaultValue={detailsList?.line12}
                          />
                          {errors.line12 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                  </div>
                  
                  <div className="banner-tab">
                    <h3>Project Details</h3>
                    <Col md="6" className="margin-muthai">
                      <div className="form-group">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Main Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line22"
                            className="form-control"
                            defaultValue={detailsList?.line22}
                          />
                          {errors.line22 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>

                    <div className="banner-tab2">
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 1 Column 1 Title
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line23"
                              className="form-control"
                              defaultValue={detailsList?.line23}
                            />
                            {errors.line23 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 1 Column 1 Description
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line25"
                              className="form-control"
                              defaultValue={detailsList?.line25}
                            />
                            {errors.line25 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                    </div>

                    <div className="banner-tab2">
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 1 Column 2 Title
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line26"
                              className="form-control"
                              defaultValue={detailsList?.line26}
                            />
                            {errors.line26 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 1 Column 2 Description
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line27"
                              className="form-control"
                              defaultValue={detailsList?.line27}
                            />
                            {errors.line27 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                    </div>

                    <div className="banner-tab2">
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 2 Title
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line28"
                              className="form-control"
                              defaultValue={detailsList?.line28}
                            />
                            {errors.line28 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 2 Description
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line29"
                              className="form-control"
                              defaultValue={detailsList?.line29}
                            />
                            {errors.line29 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                   
                    </div>
                  </div>
                  <div className="banner-tab">
                    <h3>Muthai Legacy</h3>
                    <Col md="6" className="margin-muthai">
                      <div className="form-group">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Main Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line13"
                            className="form-control"
                            defaultValue={detailsList?.line13}
                          />
                          {errors.line13 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <div className="banner-tab2">
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 1 Title
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line14"
                              className="form-control"
                              defaultValue={detailsList?.line14}
                            />
                            {errors.line14 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 1 Description
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line15"
                              className="form-control"
                              defaultValue={detailsList?.line15}
                            />
                            {errors.line15 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 1 Image
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: false })}
                              type="file"
                              id="fv-subject"
                              name="line16"
                              className="form-control"
                              onChange={onFileSelect}
                              defaultValue={detailsList?.line16}
                            />


                            {errors.line16 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                    </div>

                    <div className="banner-tab2">
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 2 Title
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line17"
                              className="form-control"
                              defaultValue={detailsList?.line17}
                            />
                            {errors.line17 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 2 Description
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line18"
                              className="form-control"
                              defaultValue={detailsList?.line18}
                            />
                            {errors.line18 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 2 Image
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: false })}
                              type="file"
                              id="fv-subject"
                              name="line20"
                              className="form-control"
                              onChange={onFileSelect20}
                              defaultValue={detailsList?.line20}
                            />
                            {errors.line20 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                    </div>

                    <div className="banner-tab2">
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 3 Title
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line19"
                              className="form-control"
                              defaultValue={detailsList?.line19}
                            />
                            {errors.line19 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 3 Description
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line21"
                              className="form-control"
                              defaultValue={detailsList?.line21}
                            />
                            {errors.line21 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 4 Row 3 Image
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: false })}
                              type="file"
                              id="fv-subject"
                              name="line24"
                              className="form-control"
                              defaultValue={detailsList?.line24}
                              onChange={onFileSelect24}
                            />
                            {errors.line24 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                      
                    </div>
                  </div>
                  <div className="banner-tab">
                    <h3>Our Vision</h3>
                    <Col md="6" className="margin-muthai">
                      <div className="form-group">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Banner 5 Main Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line30"
                            className="form-control"
                            defaultValue={detailsList?.line30}
                          />
                          {errors.line30 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>

                    <div className="banner-tab2">
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 5 Main Description
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line31"
                              className="form-control"
                              defaultValue={detailsList?.line31}
                            />
                            {errors.line31 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                    </div>

                    <div className="banner-tab2">
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 5 Column 1 Title
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line32"
                              className="form-control"
                              defaultValue={detailsList?.line32}
                            />
                            {errors.line32 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 5 Column 1 Description
                        </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line37"
                              className="form-control"
                              defaultValue={detailsList?.line37}
                            />
                            {errors.line37 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                    </div>

                    <div className="banner-tab2">
                    <Col md="6">
                    <div className="form-group">
                    <Label className="form-label" htmlFor="fv-full-name">
                        Banner 5 Column 2 Title
                        </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="text"
                          id="fv-subject"
                          name="line33"
                          className="form-control"
                          defaultValue={detailsList?.line33}
                        />
                        {errors.line33 && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col>
                    <Col md="6">
                    <div className="form-group">
                    <Label className="form-label" htmlFor="fv-full-name">
                        Banner 5 Column 2 Description
                        </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="text"
                          id="fv-subject"
                          name="line38"
                          className="form-control"
                          defaultValue={detailsList?.line38}
                        />
                        {errors.line38 && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col>

                    </div>
                    <div className="banner-tab2">
                    
                  <Col md="6">
                    <div className="form-group">
                    <Label className="form-label" htmlFor="fv-full-name">
                        Banner 5 Column 3 Title
                        </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="text"
                          id="fv-subject"
                          name="line34"
                          className="form-control"
                          defaultValue={detailsList?.line34}
                        />
                        {errors.line34 && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col>
                    <Col md="6">
                    <div className="form-group">
                    <Label className="form-label" htmlFor="fv-full-name">
                        Banner 5 Column 3 Description
                        </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="text"
                          id="fv-subject"
                          name="line39"
                          className="form-control"
                          defaultValue={detailsList?.line39}
                        />
                        {errors.line39 && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col>
                    </div>
                    <div className="banner-tab2">
                    <Col md="6">
                    <div className="form-group">
                    <Label className="form-label" htmlFor="fv-full-name">
                        Banner 5 Column 4 Title
                        </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="text"
                          id="fv-subject"
                          name="line35"
                          className="form-control"
                          defaultValue={detailsList?.line35}
                        />
                        {errors.line35 && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                    <Label className="form-label" htmlFor="fv-full-name">
                        Banner 5 Column 4 Description
                        </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="text"
                          id="fv-subject"
                          name="line40"
                          className="form-control"
                          defaultValue={detailsList?.line40}
                        />
                        {errors.line40 && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col>
                    </div>
                    <div className="banner-tab2">

                    <Col md="6">
                    <div className="form-group">
                    <Label className="form-label" htmlFor="fv-full-name">
                        Banner 5 Column 5 Title
                        </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="text"
                          id="fv-subject"
                          name="line36"
                          className="form-control"
                          defaultValue={detailsList?.line36}
                        />
                        {errors.line36 && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col>
                  
                  <Col md="6">
                    <div className="form-group">
                    <Label className="form-label" htmlFor="fv-full-name">
                        Banner 5 Column 5 Description
                        </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="text"
                          id="fv-subject"
                          name="line41"
                          className="form-control"
                          defaultValue={detailsList?.line41}
                        />
                        {errors.line41 && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col>
                    </div>
                  </div>
                  {/* <Col md="6">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-subject">
                        Line 42
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="text"
                          id="fv-subject"
                          name="line42"
                          className="form-control"
                          defaultValue={detailsList?.line42}
                        />
                        {errors.line42 && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-subject">
                        Line 43
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="text"
                          id="fv-subject"
                          name="line43"
                          className="form-control"
                          defaultValue={detailsList?.line43}
                        />
                        {errors.line43 && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-subject">
                        Line 44
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="text"
                          id="fv-subject"
                          name="line44"
                          className="form-control"
                          defaultValue={detailsList?.line44}
                        />
                        {errors.line44 && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col> */}

                  <Col md="12">
                    <div className="form-group">
                      <Button color="primary" size="lg">
                        Save Information
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </PreviewCard>
        </Block>


      </Content>
    </React.Fragment>
  );
};
export default Home;
