import React, { useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";
import imgeurl from "../../../utils/constant";
import MultiImageInput from "react-multiple-image-input";

import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";
import { Data } from "@react-google-maps/api";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import WebViewer from "@pdftron/webviewer";
import PDFJS from "pdfjs-dist/webpack";
import { pdfjs } from "react-pdf";
import { saveAs } from "file-saver";
import PSPDFKit from "pspdfkit";
import { toast } from "react-toastify";
import AuthModal from "../../auth/AuthModal";

const Publication = () => {
  const { errors, register, handleSubmit } = useForm();
  const [title, settitle] = React.useState("");
  const [dataList, setDataList] = React.useState({});
  const [dataListError, setDataListError] = React.useState("");
  const history = useHistory();
  const windowRef = React.useRef();
  const [viewPdf, setViewPdf] = React.useState(null);
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const token = localStorage.getItem("token");

  const [authCheckModal, setAuthCheckModal] = useState(false);

  const authCheckToggle = () => setAuthCheckModal(!authCheckModal);


  const onFormSubmit = (e) => {
    const dataid = history.location.state?.detail._id;

    const method = "publication/";
    const data = e;



    if (dataList !== null) {
      console.log(dataList)

      const bodyData = {
        language: e.language,
        title: title,
        pdfname: dataList.data[0].filename,
      };
      new ServiceStore().InsertData("publication/", bodyData, token).then((res) => {
        console.log(res)
        alert("Uploaded Successfully")
        history.push({ pathname: '/publicationlist' })
      });

    } else {
    }

  };
  const fileType = ["application/pdf"];
  const handlePdfAdd = (e) => {
    console.log(e.target)
    let fileName = e.target.files[0];
    if (fileName) {
      if (fileName && fileType.includes(fileName.type)) {
        const formData = new FormData();
        formData.append("file", fileName, fileName.name?.replaceAll(" ", "")?.toLowerCase());
        new ServiceStore().UploadFile("publication/PdfUpload", formData, token).then((res) => {
          setDataList(res.data);
          setDataListError("");
        })
          .catch((err) => {
            if (err?.response?.data?.error === "Please authenticate.") {
              setAuthCheckModal(true)
            }
          })
      } else {
        setDataListError("Please select valid pdf file");
      }
    } else {
      setDataList("");
      setDataListError("");
    }
  };

  const formClass = classNames({
    "form-validate": true,
  });

  React.useEffect(() => {
    const dataid = history.location.state?.detail._id;
    if (dataid) {
      new ServiceStore().GetDataByID("publication/", dataid).then((res) => {
        setDataList(res.data);
      });
    }
  }, []);

  return (
    <React.Fragment>
      {/* <Head title="Form Validation"></Head> */}
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BlockTitle tag="h2" className="fw-normal">
              Publication
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <BlockHead>
            <BlockHeadContent></BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <React.Fragment>
              <Form className={formClass} onSubmit={handleSubmit(onFormSubmit)}>
                <Row className="g-gs">
                  <Col lg="7">
                    <div className="form-group">
                      <ul className="custom-control-group g-3 align-center flex-wrap">
                        <li>
                          <div className="custom-control custom-radio">
                            <input
                              ref={register({ required: true })}
                              type="radio"
                              className="custom-control-input"
                              defaultChecked
                              name="language"
                              id="reg-enable"
                              value="english"
                            />
                            <label className="custom-control-label" htmlFor="reg-enable">
                              English
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-radio">
                            <input
                              ref={register({ required: true })}
                              type="radio"
                              className="custom-control-input"
                              name="language"
                              id="reg-disable"
                              value="marathi"
                            />
                            <label className="custom-control-label" htmlFor="reg-disable">
                              Marathi
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>

                  <Col lg="7">
                    <div className="row">
                      <div className="form-group">
                        <label>Title</label>

                        <input
                          className="form-control"
                          type="text"
                          name="title"
                          id="title"
                          placeholder="Enter Pdf Title"
                          defaultValue={dataList?.title}
                          required
                          onChange={(e) => settitle(e.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                  {/* <div className="banner-tab2">
                    <h3>Upload Pdf</h3>
                  </div> */}

                  <Col lg="7">
                    <div className="row">
                      <div className="form-group">
                        <label>Upload Pdf</label>
                        <input
                          className="form-control"
                          type="file"
                          name="pdf"
                          id="pdf"
                          required
                          onChange={handlePdfAdd}
                        />
                        {dataListError && (
                          <div style={{ width: "100%", color: "red", fontSize: "14px", fontWeight: "600" }}>
                            {dataListError}
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <Button className="btn btn-success btn-lg">Upload</Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </PreviewCard>
        </Block>
      </Content>

      {
        authCheckModal &&
        <AuthModal modalOpen={authCheckModal} modelClose={authCheckToggle} />
      }

    </React.Fragment>
  );
};

export default Publication;
