import { TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PreviewCard, RSelect } from "../../../components/Component";
import imgeurl, { RequestListLanguageFilters } from "../../../utils/constant";
import { Content } from "antd/es/layout/layout";
import ServiceStore from "../../../utils/ServiceStore";
import { debounce } from "../../../utils/commonFunctions";
import { useRef } from "react";
import { Button, Col, Form, Modal, ModalBody } from "reactstrap";
import Icon from "../../../components/icon/Icon";
import Popup from "reactjs-popup";
import axios from "axios";
import "./SurveyList.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthModal from "../../auth/AuthModal";

const SurveyList = () => {
  const initialController = {
    page: 1,
    rowsPerPage: 10,
    language: "",
  };

  const [datalist, setdatalist] = React.useState([]);
  const [filteredDatalist, setFilteredDatalist] = React.useState([]);
  const [datalistCount, setdatalistCount] = React.useState(0);
  const [controller, setController] = React.useState(initialController);
  const [deletedata, setdeletedata] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [getData, setGetData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [inpVal, setInpVal] = useState("");
  const [postImg, setPostImg] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [formData, setFormData] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [Id, setId] = useState('');
  const [updateData, setUpdateData] = useState("");
  const searchRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState();

  const [authCheckModal, setAuthCheckModal] = useState(false);

  const authCheckToggle = () => setAuthCheckModal(!authCheckModal);

  useEffect(() => {
    getAlldataFile();
  }, [refresh, controller]);

  function handle(e) {
    const newdata = { ...controller };
    newdata[e.target.id] = e.target.value;
    setController({ ...newdata, page: 1 });
  }
  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage + 1,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setController({
      //   ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1,
    });
  };

  const resetForm = () => {
    searchRef.current.value = "";
    setSearch("");
    setController({ ...initialController });
  };
  const getAlldataFile = () => {
    const methods = "survey/surveylist";
    const bodyData = {
      page: controller.page,
      limit: controller.rowsPerPage,
      language: controller.language,
    };
    new ServiceStore().GetDataBYFilter(methods, bodyData).then((res) => {
      console.log("res.response", res.response);
      setGetData(res.response.data.list);
      setTotalCount(res.response.data.count)
    });
  };

  const deleteSurvey = (index, e) => {

    const methods = "/survey/deletesurvey/";

    new ServiceStore().DeleteData(methods, index, token).then((res) => {
      console.log("Delete:", res);
      setRefresh(!refresh);
      alert("survey deleted successfully")
      // setdeletedata("true");
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })

  };

  const handleSearch = (e) => debounceSearch(e?.target?.value);
  const debounceSearch = debounce((query) => setSearch(query), 350);

  const [modal, setModal] = useState({
    add: false,
  });
  const onFormCancel = () => {
    setModal({ add: false });
    setModal({ edit: false });
  };

  const handlesearch1 = (e) => {
    const searchTerm = e.target.value;

    if (searchTerm) {
      const filterData = getData.filter((data) => {
        const regex = new RegExp(searchTerm, 'i');
        return data.title.match(regex);
      });
      setGetData(filterData);
    } else {
      getAlldataFile();
    }
  }

  const [currentData, setCurrentData] = useState();

  // onchange events
  const handleChange = (event) => {
    const { name, value, type, files } = event.target;
    if (type === 'file') {
      console.log("file : ")
      console.log(files[0]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: event.target.files[0],
      }));
    }
    console.log(formData)
    setFormData((prevFormData) => ({

      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (event) => {
    console.log("date", event.target.value);
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   imageInput: file,
    // }));
    setInpVal(file)
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value.value);
  };

  const token = localStorage.getItem("token");


  const handleSubmit = (e) => {
    e.preventDefault();
    const methods = "/survey/addsurvey";
    const NewFormData = new FormData();
    NewFormData.append("image", inpVal);
    NewFormData.append("title", formData.title);
    NewFormData.append("description", formData.description);
    NewFormData.append("link", formData.link);
    NewFormData.append("endDate", formData.endDate);
    NewFormData.append("language", selectedValue);

    if (selectedValue !== undefined && inpVal && formData.title && formData.description && formData.link && formData.endDate) {
      new ServiceStore().InsertData(methods, NewFormData, token).then((res) => {
        console.log(res);
        setRefresh(!refresh);
        setFormData([])
        setSelectedValue()
        setModal({ add: false });
      }, (err) => {
        console.log("error : ", err)
      });
    }
    else {
      alert("Please fill all empty fields");
    }
  };

  const editData = (item) => {
    setId(item._id)
    setEditedData({
      title: item.title,
      description: item.description,
      endDate: item.endDate,
      link: item.link,
      language: selectedValue,
      image: item.image
    });
  };

  const editDataHandler = (event) => {
    console.log(event.target.value);
    const { name, value, type, files } = event.target;
    // setEditedData({language: event.target.value})
    if (type === "file") {
      console.log("name : ", name);
      console.log("files : ", files);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0],
      }));
    }
    setEditedData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const editDataImageHandler = (e) => {
    const file = e.target.files[0];
    // setEditedData((prevFormData) => ({
    //   ...prevFormData,
    //   image: file,
    // }));
    // setEditedData({
    //   ...editedData,
    //   image: e.target.files[0],
    // });
    setUpdateData(file);
  };

  const updateStatus = () => toast("Record updated successfully !.");

  const handleUpdateSubmit = (e) => {
    e.preventDefault();

    const date = editedData.endDate
    const newDate = date.split("-").reverse().join("/")
    const bodyData = {
      file: updateData,
      title: editedData?.title,
      description: editedData?.description,
      link: editedData?.link,
      endDate: editedData?.endDate,
      language: editedData?.language?.value,
    };
    const NewFormData = new FormData();
    NewFormData.append("image", updateData);
    NewFormData.append("title", editedData?.title);
    NewFormData.append("description", editedData?.description);
    NewFormData.append("link", editedData?.link);
    NewFormData.append("endDate", newDate);
    // NewFormData.append("language", selectedValue);
    NewFormData.append("language", editedData?.language ? editedData?.language : 'english');
    const methods = `/survey/updatesurvey/${Id}`;
    new ServiceStore().UpdateData(methods, NewFormData, token).then((res) => {
      try {
        if (res.response && res.response.message) {
          const message = res.response.message;
          updateStatus()
          setModal({ edit: false })
          getAlldataFile()
        } else {
          console.error("No message property found in the JSON data.");
        }
      } catch (error) {
        console.error("Error accessing response properties:", error);
      }
      // setRefresh(!refresh);
      // setdeletedata("true");
      // onFormCancel();
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })
  };

  const filterStatus = [
    { value: "english", label: "English" },
    { value: "marathi", label: "Marathi" },
  ];


  return (
    <React.Fragment>
      <ToastContainer />
      <Content page="component"></Content>
      <div>
        <form
        // onSubmit={(e) => {}}
        >
          <div className="row" style={{ marginBottom: "10px", marginLeft: "16px", marginTop: "8rem" }}>
            <div style={{ marginBottom: "1rem" }}>
              <h3>Survey</h3>
            </div>
            <div className="col-md-3">
              <label style={{ marginLeft: "4px" }}>Language</label>
              <select
                name="language"
                // onChange={(e) => handle(e)}
                id="language"
                style={{ marginLeft: "5px" }}
                className="form-control"
              // value={controller?.language}
              >
                {RequestListLanguageFilters?.map((ele) => (
                  <option value={ele?.value} key={ele?.key}>
                    {ele?.label}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className="col-md-3">
              <label>Status</label>
              <select
                name="status"
                onChange={(e) => handle(e)}
                id="status"
                style={{ marginLeft: "5px" }}
                className="form-control"
                value={controller?.status}
              >
                <option value="All">All</option>
                <option value="Approve">Accepted</option>
                <option value="Pending" selected>
                  Pending
                </option>
                <option value="Reject">Rejected</option>
              </select>
            </div> */}
            <div className="col-md-3">
              <label>Search</label>
              <input
                className="form-control"
                placeholder="Search..."
                onChange={handlesearch1}
                defaultValue={search}
                ref={searchRef}
              />
            </div>

            <div className="col-md-3">
              <button style={{ marginTop: "23px" }} type="button" className="btn btn-danger" onClick={resetForm}>
                Reset
              </button>
              <button
                style={{ marginTop: "23px", marginLeft: "1rem" }}
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setModal({ add: true });
                }}
              >
                Create
              </button>
            </div>
            <div className="col-md-3"></div>
          </div>
        </form>
      </div>

      <PreviewCard className="card-table">
        <table className="table table-orders">
          <thead className="tb-odr-head">
            <tr className="tb-odr-item">
              <th className="tb-odr-info">
                <span className="tb-odr-id">language</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Title</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Image</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">End Date</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Description</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Link</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Action</span>
              </th>
            </tr>
          </thead>
          <tbody className="tb-odr-body">
            {getData?.length > 0 ? (
              getData?.map((item, index) => {
                return (
                  <tr className="tb-odr-item" key={"item._id"}>
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">
                        <a
                          href="#id"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          {item.language}
                        </a>
                      </span>
                    </td>
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">
                        <a
                          href="#id"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          {/* {item.language === 'english' ? 'English' : 'मराठी'} */}
                          {item.title}
                        </a>
                      </span>
                    </td>
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">
                        <a
                          href="#id"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <img src={imgeurl + item.image} alt="" width="50" height="50" />
                        </a>
                      </span>
                    </td>

                    <td className="tb-odr-info">
                      <span style={{ color: "#007bff" }} className="tb-odr-id">
                        {item.endDate}
                      </span>
                    </td>

                    <td className="tb-odr-info">
                      <Popup className="popup"
                        // contentStyle={{ width: "auto", height: "auto"}}
                        trigger={<button className=" btn btn-info" style={{ padding: '5px' }}>View</button>}
                        position="center center"
                      >
                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                      </Popup>
                    </td>

                    <td className="tb-odr-info">
                      <div className="link-cell">
                        <span className="tb-odr-id">
                          <a
                            href="#id"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                          >
                            {item.link}
                          </a>
                        </span>
                      </div>
                    </td>


                    <td className="tb-odr-info">
                      <Icon
                        onClick={() => {
                          setModal({ edit: true });
                          editData(item);
                        }}
                        name="edit-alt-fill"
                        style={{ cursor: "pointer", paddingLeft: "9px", paddingRight: "10px" }}
                      // onClick={e => Editdata(item, e) }
                      ></Icon>
                      <Icon
                        name="trash-empty-fill"
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteSurvey(item._id)}
                      ></Icon>
                    </td>

                    <Modal isOpen={modal.view} toggle={() => setModal({ view: false })} className="tb-odr-info">
                      <ModalBody>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="close"
                        >
                          <Icon name="cross-sm"></Icon>
                        </a>
                        <h5 className="title"> Description</h5>
                        <span className="tb-odr-id">{item.description}</span>
                      </ModalBody>
                    </Modal>


                    {/* <Modal isOpen={modal.view} toggle={() => setModal({ view: false })} className="tb-odr-info">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }} className="close">
              <Icon name="cross-sm"></Icon>
            </a>
              <h5 className="title"> Description</h5>
              <span className="tb-odr-id">
                {item.description}
                </span>
          </ModalBody>
        </Modal> */}

                    {/* <td className="tb-odr-action" onClick={surveyDelete(getData)}>Edit</td>
                    <td className="tb-odr-action" onClick={()=>deleteSurvey(item._id)}>Delete</td> */}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  <h3>No Record Found</h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalCount !== 0 && getData.length > 0 ? (
          <TablePagination
            style={{ marginTop: "10px" }}
            component="div"
            onPageChange={handlePageChange}
            page={controller.page - 1}
            count={totalCount}
            rowsPerPage={controller.rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </PreviewCard>
      {/* </Block> */}

      {/* </Content> */}
      {/* add data  */}
      <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#close"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Add Survey</h5>
            <div className="mt-4">
              <Form className="row gy-4" autoComplete="off" onSubmit={handleSubmit}>
                <Col md="6">

                  <div className="form-group">

                    <label className="form-label">Select Language</label><span className="star"> *</span>
                    <RSelect options={filterStatus} placeholder="Select Language" onChange={handleSelectChange} />

                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Title</label><span className="star"> *</span>
                    <input
                      className="form-control"
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                      required
                    // value={editItem.id}
                    // defaultValue={editItem.id}
                    // placeholder={editItem.id}
                    // readOnly={true}
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Image</label><span className="star"> *</span>
                    <input
                      className="form-control"
                      type="file"
                      // name="id"
                      // onChange={handleImageChange}
                      name="imageInput"
                      onChange={handleImageChange}
                      required
                    // value={editItem.id}
                    // defaultValue={editItem.id}
                    // placeholder={editItem.id}
                    // readOnly={true}
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">End Date</label><span className="star"> *</span>
                    <input
                      className="form-control"
                      type="date"
                      //                 value={selectedDate}
                      // onChange={handleDateChange}
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleDateChange}
                      required
                    // value={editItem.id}
                    // defaultValue={editItem.id}
                    // placeholder={editItem.id}
                    // readOnly={true}
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Description</label><span className="star"> *</span>
                    <input
                      className="form-control"
                      type="text"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      required
                    // value={editItem.id}
                    // defaultValue={editItem.id}
                    // placeholder={editItem.id}
                    // readOnly={true}
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Link</label><span className="star"> *</span>
                    <input
                      className="form-control"
                      type="text"
                      name="link"
                      value={formData.link}
                      onChange={handleChange}
                      required
                    // value={editItem.id}
                    // defaultValue={editItem.id}
                    // placeholder={editItem.id}
                    // readOnly={true}
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="md"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* // edit form Modal   */}
      <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Edit Survey</h5>
            <div className="mt-4">
              <form className="row gy-4">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Select Language</label>
                    <RSelect options={filterStatus} placeholder="Select Language" onChange={handleSelectChange}
                      value={selectedValue?.value} />

                    {/* <input
                      className="form-control"
                      type="text"
                      name="language"
                      value={editedData.language}
                      onChange={editDataHandler}

                      ref={register({ required: "This field is required" })}
                    /> */}
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Title</label>
                    <input
                      className="form-control"
                      type="text"
                      name="title"
                      value={editedData.title}
                      onChange={editDataHandler}

                    // ref={register({
                    //   required: "This field is required",
                    //   pattern: {
                    //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    //     message: "invalid email address",
                    //   },
                    // })}
                    />
                    {/* {errors.email && <span className="invalid">{errors.email.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Image</label>
                    <input
                      className="form-control"
                      type="file"
                      name="image"
                      // defaultValue={'formData.email'}
                      // value={editedData.image}
                      // onChange={editDataHandler}
                      onChange={editDataImageHandler}
                    // ref={register({
                    //   required: "This field is required",
                    //   pattern: {
                    //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    //     message: "invalid email address",
                    //   },
                    // })}
                    />
                    {/* {errors.email && <span className="invalid">{errors.email.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">End Date</label>
                    <input
                      className="form-control"
                      type="date"
                      name="endDate"
                      value={editedData.endDate}
                      onChange={editDataHandler}
                      required
                    // defaultValue={parseFloat(formData.balance.replace(/,/g, ""))}

                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.balance && <span className="invalid">{errors.balance.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <input
                      className="form-control"
                      type="text"
                      name="description"
                      value={editedData.description}
                      onChange={editDataHandler}
                      required
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.phone && <span className="invalid">{errors.phone.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Link</label>
                    <input
                      className="form-control"
                      type="text"
                      name="link"
                      value={editedData.link}
                      onChange={editDataHandler}
                      required
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.phone && <span className="invalid">{errors.phone.message}</span>} */}
                  </div>
                </Col>
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button color="primary" size="md" type="submit" onClick={(e) => handleUpdateSubmit(e)}>
                        Submit
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </form>
            </div>
          </div>
        </ModalBody>


      </Modal>

      {
        authCheckModal &&
        <AuthModal modalOpen={authCheckModal} modelClose={authCheckToggle} />
      }

    </React.Fragment>
  );
};
export default SurveyList;