import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import ServiceStore from '../../../utils/ServiceStore';
import { BlockBetween, BlockHead, BlockHeadContent } from '../../../components/Component';
import { Button } from 'reactstrap';
import { Content } from 'antd/es/layout/layout';

const AdminBlogCreate = () => {
    const [datalist, setdatalist] = useState([]);
    const [conservationtopiclist, setconservationtopiclist] = useState([]);

    const [language, setlanguage] = useState("English");
    const [image, setImage] = useState("");

    const [categoryId, setCategoryId] = useState("");
    const [title, settitle] = useState("");
    const [subtitle, setsubtitle] = useState("");
    const [subtitleError, setSubtitleError] = useState("");

    const history = useHistory();

    useEffect(() => {
        getAlldataFile();
    }, [language]);
    const logEvent = (e) => {
        console.log(e.editor.getData())
        setsubtitle(e.editor.getData());
        setSubtitleError("");
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!language) {
            alert("Please select a language")
            return;
        }

        if (!image) {
            alert("Please select an image")
            return;
        }

        if (!categoryId) {
            alert("Please select a category")
            return;
        }

        if (!title) {
            alert("Please enter a title")
            return;
        }
        if (!subtitle) {
            setSubtitleError("Please enter body text.!!"); // Set the subtitle error message
            return;
        } else {
            setSubtitleError(""); // Clear the subtitle error message if the subtitle is not empty
        }
        // const auth = JSON.parse(localStorage.getItem("items"));
        const auth =  JSON.parse(sessionStorage.getItem("user"));
       console.log("auth",auth)

        const methods = "writeblogtopic/";
        const data = {
            language: language,
            username: auth.email,
            title: title,
            categoryId: categoryId,
            subtitle: subtitle,
            status: "Pending",
            userID: auth._id,
        };

        console.log("data : ",data)

        new ServiceStore().InsertData(methods, data).then((res) => {
            // history.push("/English/Blog");
        });
        const ImageData = new FormData();
        ImageData.append("file", image);
        new ServiceStore()
            .InsertData("writeblogtopic/ImageUpload", ImageData)
            .then((res) => {
                if (res.response.status === 1) {
                    alert("Your blog request added successfully")
                    history.push("/blogrequest");
                }
            });
    };
    const handleIdChange = (event) => {
        const categoryId = event.target.value;
        // const categoryId = event.target.getAttribute('data-tag');

        setCategoryId(categoryId);
    };
    const getAlldataFile = () => {
        const methods = `/blogcategory/${language}`;
        new ServiceStore().GetAllData(methods).then((res) => {
            setdatalist(res.data);
        });
    };
    const langaugeChange = (event) => {
        const language = event.target.value;
        setlanguage(language);
    };

    const ImagehandleIdChange = (e) => {
        setImage(e.target.files[0]);
    };
    return (
        <React.Fragment>
      <Content page="component"></Content>
            <BlockHead size="sm">
                <BlockBetween>
                    <BlockHeadContent><h3>Blog Request</h3></BlockHeadContent>
                    <BlockHeadContent> <Button color="primary" size="lg" >
                        Create
                    </Button></BlockHeadContent>
                </BlockBetween>
            </BlockHead>

            <div className="container-fluid Btp-body">
              
                    <div className="Btp-header">
                        <div style={{ marginBottom: "2rem", marginTop: '3rem' }}>
                            <h3>Write Blog</h3>
                        </div>
                    </div>
                    <form
                        onSubmit={handleSubmit}
                    >
                        <div className="row Btp-row">
                            <div className="col-xl-3 col-md-3">
                                <div className="form-group input-font">
                                    <label>
                                        Language
                                        <select
                                            className="form-control input-font"
                                            onChange={langaugeChange}
                                            required
                                        >
                                            <option value="">Select Language</option>

                                            <option value="English">English</option>
                                            <option value="Marathi">Marathi</option>
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-3">
                                <div className="form-group">
                                    <label>Image</label>
                                    <input
                                        className="form-control input-font"
                                        style={{ padding: "5px 55px" }}
                                        type="file"
                                        onChange={ImagehandleIdChange}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-3">
                                <div className="form-group">
                                    <label>
                                        Category
                                        <select
                                            className="form-control input-font"
                                              onChange={handleIdChange}
                                            required
                                        >
                                            <option value="">Select Blog Category</option>
                                            {datalist.map((item) => (
                                                <option
                                                    key={item._id}
                                                    value={item._id}
                                                    data-tag={item._id}
                                                >
                                                    {item.category}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4">
                                <div className="form-group">
                                    <label>Title</label>

                                    <input
                                        className="form-control input-font"
                                        type="text"
                                        name="otp"
                                        id="otp"
                                        placeholder="Enter Topic Title"
                                        required
                                    onChange={(e) => settitle(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="Btp-editorheader">Body Text</div>
                        <div className="row Btp-row">
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group input-font">
                                    <CKEditor
                                        style={{ fontSize: "15px" }}
                                        onSelectionChange={logEvent}
                                        data={subtitle}
                                        required
                                    />
                                </div>
                                {subtitleError && (
                                    <div className="error-message" style={{ fontSize: "16px", color: "red" }}>{subtitleError}</div>
                                )}
                            </div>
                        </div>

                        <div className="Btp-button">
                            <input type="submit" value="submit" className="Btp-btn2 btn btn-primary" style={{ marginTop: "23px", marginLeft: "1rem" }} />
                        </div>
                    </form>
            
            </div>
        </React.Fragment>
    )
}

export default AdminBlogCreate;