export const removeFromArray = (arr, index) => {
  // copying array
  const copyArray = [...arr];
  // only splice array when item is found
  if (index > -1) {
    copyArray.splice(index, 1); // 2nd parameter means remove one item only
  }
  //   returning new array
  console.log({ copyArray });
  return copyArray;
};

export const debounce = (callback, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), delay);
  };
};
