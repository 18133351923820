import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";
import Icon from "../../../components/icon/Icon";
import { loginData, orderData, transactionData } from "../../../components/table/TableData";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";
import "./common.css";

import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  BlockBetween,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";
import axios from "axios";

const BlogInfoList = () => {
  const { errors, register, handleSubmit } = useForm();

  const [id] = React.useState("form-1");
  const [file, setFile] = React.useState("");
  const [file20, setFile20] = React.useState("");
  const [file24, setFile24] = React.useState("");
  const [datalist, setdatalist] = React.useState([]);
  const [deletedata, setdeletedata] = React.useState("");

  const history = useHistory();
  const home = () => {
    history.push("/home/English");
  };

  const Deletedata = (e, index) => {
    console.log("indexxx : ", index);
    const methods = "home/";
    new ServiceStore().DeleteData(methods, index._id).then((res) => {
      setdeletedata("true");
    });
  };

  const Editdata = (index, e) => {
    console.log("e", index);
    // history.push("/demo1/home");
    history.push({
      pathname: "/blogsInfo",
      search: "?language=" + index.language,
      state: { detail: index },
    });
  };
  React.useEffect(() => {
    getAlldataFile();
  }, [deletedata]);

  const getAlldataFile = async () => {
    // const method = "http://localhost:3001/admin/blogInfo";
    const methods = "blogInfo/";

    new ServiceStore().GetAllData(methods).then((res) => {
      const array = [];
      res.data.filter((data) => {
        data.updatedAt = data.updatedAt.split("T")[0];
        array.push(data);
      });
      console.log("res.response", res);
      setdatalist(res.data);
    });
  };

  const DropdownTrans = () => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu end>
          <ul className="link-list-plain">
            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                View
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Invoice
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Print
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <React.Fragment>
      {/* <Head title="Form Validation">
   
      </Head> */}
      <Content page="component">
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <h3>Blog Information</h3>
            </BlockHeadContent>
            {/* <BlockHeadContent> <Button color="primary" size="lg" onClick={home}>
              Create
              </Button></BlockHeadContent> */}
          </BlockBetween>
        </BlockHead>
        {/*
            <div className="form-group" style={{"float":"right"}}>
              <Button color="primary" size="lg" onClick={home}>
              Create
              </Button>
            </div> */}
      </Content>
      {/* <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
            
            </BlockHeadContent>
          </BlockHead> */}
      <PreviewCard className="card-table">
        <table className="table table-orders">
          <thead className="tb-odr-head">
            <tr className="tb-odr-item">
              <th className="tb-odr-info">
                <span className="tb-odr-id">Language</span>
              </th>
              <th className="tb-odr-info">{/* <span className="tb-odr-id">Line 1</span> */}</th>
              <th className="tb-odr-amount">{/* <span className="tb-odr-id">Updated At</span> */}</th>
              <th className="tb-odr-amount">
                <span className="tb-odr-id">Updated At</span>
              </th>
              <th className="tb-odr-amount">{/* <span className="tb-odr-id">Line 3</span> */}</th>
              <th className="tb-odr-action">
                &nbsp;
                <span className="tb-odr-id">Action</span>
              </th>
            </tr>
          </thead>
          <tbody className="tb-odr-body">
            {datalist.length > 0 ? (
              datalist.map((item) => {
                return (
                  <tr className="tb-odr-item" key={item._id}>
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">
                        <a
                          href="#id"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          {item.language === "english" ? "English" : "मराठी"}
                        </a>
                      </span>
                    </td>
                    <td className="tb-odr-info">{/* <span className="tb-odr-id">{item.line1}</span> */}</td>
                    <td className="tb-odr-amount">
                      <span className="tb-odr-id">{/* <span className="amount">{item.line2}</span> */}</span>
                    </td>
                    <td className="tb-odr-amount">
                      <span className="tb-odr-id">
                        <span className="amount">{item.updatedAt}</span>
                      </span>
                    </td>
                    <td className="tb-odr-amount">
                      <span className="tb-odr-id">
                        <Badge className="badge-dot">{/* {item.line3} */}</Badge>
                      </span>
                    </td>
                    <td className="tb-odr-action">
                      <div className="tb-odr-id">
                        {/* <Button color="primary" className="btn-sm" onClick={e => Editdata(item, e) }>
                    Edit
                  </Button> */}
                        <Icon
                          name="edit-alt-fill"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => Editdata(item, e)}
                        ></Icon>{" "}
                        &nbsp;&nbsp;&nbsp;
                        {/* <Icon
                          name="trash-empty-fill"
                          style={{ cursor: "pointer" }}
                          onClick={e => Deletedata(e, item) }
                        ></Icon> */}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  <h3>No Record Found</h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </PreviewCard>
      {/* </Block> */}

      {/* </Content> */}
    </React.Fragment>
  );
};
export default BlogInfoList;
