import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { ProductContextProvider } from "../pages/pre-built/products/ProductContext";
import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";
import { RedirectAs404 } from "../utils/Utils";
import AdminUserList from "../pages/components/admin/adminuserlist";
import AdminUser from "../pages/components/admin/adminuser";
import Home from "../pages/components/admin/home";
import HomeList from "../pages/components/admin/homeList";
import AboutUs from "../pages/components/admin/aboutus";
import AboutusList from "../pages/components/admin/aboutusList";
import Facilities from "../pages/components/admin/facilities";
import FacilitiesList from "../pages/components/admin/facilitiesList";
import Gallary from "../pages/components/admin/gallary";
import GallaryList from "../pages/components/admin/gallaryList";
import Blogs from "../pages/components/admin/blogs";
import BlogsInfo from "../pages/components/admin/blogsInfo";
import BlogsList from "../pages/components/admin/blogsList";
import CommunityForum from "../pages/components/admin/communityforum";
import CommunityForumList from "../pages/components/admin/communityforumList";

import CommunityTopicRequestList from "../pages/components/admin/communitytopicrequestList";
import BlogCategory from "../pages/components/admin/blogcategory";
import BlogCategoryList from "../pages/components/admin/blogcategorylist";
import BlogRequestList from "../pages/components/admin/BlogRequestList";

import Publication from "../pages/components/admin/publication";
import PublicationList from "../pages/components/admin/publicationsList";
import surveylist from "../pages/components/admin/SurveyList";
import Users from "../pages/components/admin/Users";
import UserUploadImageRequest from "../pages/components/admin/UserUploadImageRequest";
import AdminBlogCreate from "../pages/components/admin/AdminBlogCreate";
import ContactEnquiry from "../pages/components/admin/ContactEnquiry";
import AuditTrailUser from "../pages/components/admin/AuditTrailUser";
import AuditTrailAdmin from "../pages/components/admin/AuditTrailAdmin";
import subscribers from "../pages/components/admin/subscribers";
import notifications from "../pages/components/admin/notifications";
import Notifications from "../pages/components/admin/notifications";
import DonationList from "../pages/components/admin/DonationList";
import SpacebookingList from "../pages/components/admin/SpacebookingList";
import ReportAbuse from "../pages/components/admin/ReportAbuse";
import Homepage from "../pages/Homepage";
import BlogInfoList from "../pages/components/admin/blogsInfoList";


const Pages = () => {
  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  return (
    // <Suspense fallback={<div />}>
    <Switch>
      <Route path={`/adminuserlist`} component={AdminUserList}></Route>

      <Route path={`/adminuser`} component={AdminUser}></Route>

      <Route path={`/aboutuslist`} component={AboutusList}></Route>
      <Route path={`/aboutus`} component={AboutUs}></Route>

      <Route path={`/home`} component={Home}></Route>
      <Route path={`/homelist`} component={HomeList}></Route>
      <Route path={`/homepage`} component={Homepage}></Route>


      <Route path={`/facilities`} component={Facilities}></Route>
      <Route path={`/facilitieslist`} component={FacilitiesList}></Route>

      <Route path={`/gallary`} component={Gallary}></Route>
      <Route path={`/gallarylist`} component={GallaryList}></Route>

      <Route path={`/blogs`} component={Blogs}></Route>
      <Route path={`/blogsInfo`} component={BlogsInfo}></Route>
      <Route path={`/blogslist`} component={BlogsList}></Route>
      <Route path={`/blogsInfolist`} component={BlogInfoList}></Route>

      <Route path={`/communityforum`} component={CommunityForum}></Route>
      <Route path={`/communityforumlist`} component={CommunityForumList}></Route>
      <Route path={`/communitytopicrequestlist`} component={CommunityTopicRequestList}></Route>

      <Route path={`/blogcategory`} component={BlogCategory}></Route>
      <Route path={`/blogcategorylist`} component={BlogCategoryList}></Route>
      <Route path={`/blogrequest`} component={BlogRequestList}></Route>

      <Route path={`/publication`} component={Publication}></Route>
      <Route path={`/publicationlist`} component={PublicationList}></Route>
      <Route path={`/surveylist`} component={surveylist}></Route>
      <Route path={`/users`} component={Users}></Route>
      <Route path={`/useruploadimagerequest`} component={UserUploadImageRequest}></Route>
      <Route path={`/admin-blog-create`} component={AdminBlogCreate}></Route>

      <Route path={`/audit-trails-user`} component={AuditTrailUser}></Route>
      <Route path={`/audit-trails-admin`} component={AuditTrailAdmin}></Route>
      <Route path={`/contact-enquiry`} component={ContactEnquiry}></Route>
      <Route path={`/subscribers`} component={subscribers}></Route>
      <Route path={`/notifications`} component={Notifications}></Route>
      <Route path={`/donation`} component={DonationList}></Route>
      <Route path={`/spacebooking`} component={SpacebookingList}></Route>
      <Route path={`/ReportAbuse`} component={ReportAbuse}></Route>



    </Switch>
    // </Suspense>
  );
};
export default Pages;
