import React, { useRef, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";
import Icon from "../../../components/icon/Icon";
import { loginData, orderData, transactionData } from "../../../components/table/TableData";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge, Modal, ModalBody } from "reactstrap";
import { Card, Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from "@mui/material";
import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  BlockBetween,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { RequestListLanguageFilters, RequestListSearchKeys } from "../../../utils/constant";
import { debounce } from "../../../utils/commonFunctions";
import fuzzysort from "fuzzysort";
import "./BlogRequestList.css"
import { Link } from "react-router-dom";
import AuthModal from "../../auth/AuthModal";
const ImageUrl = "https://api.puneri.foxberry.link/public/uploads/image/";

// const BlogRequestList = () => {
//     const { errors, register, handleSubmit } = useForm();

//     const [id] = React.useState("form-1");
//     const [file, setFile] = React.useState('')
//     const [file20, setFile20] = React.useState('')
//     const [file24, setFile24] = React.useState('')
//     const [datalist, setdatalist] = React.useState([])

//     const history = useHistory();
//     const home = () => {
//         history.push("/communityforum");
//     }

//     const Editdata = (index,e) => {
//         console.log("e",index)
//         // history.push("/demo1/home");
//         history.push({
//             pathname: '/communityforum',
//             search: '?id='+index._id,
//             state: { detail: index },
//           })
//     }
//     const Approvedata = (index,e) => {
//         const data = {"status":"Approve","id":index._id}
//         new ServiceStore().UpdateData('writeblogtopic/updatedata',data).then((res) => {
//             getAlldataFile()
//           });
//     }
//     const Rejectdata = (index,e) => {
//         const data = {"status":"Reject","id":index._id}
//         new ServiceStore().UpdateData('writeblogtopic/updatedata',data).then((res) => {
//             getAlldataFile()
//           });
//     }
//     React.useEffect(() => {

//         getAlldataFile()

//       }, []);

//       const getAlldataFile = () => {
//           const methods = "writeblogtopic/"
//               new ServiceStore().GetAllData(methods).then((res) => {
//                 console.log("res.response",res)
//                 setdatalist(res.data)
//               });
//       }

//     const DropdownTrans = () => {
//         return (
//           <UncontrolledDropdown>
//             <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
//               <Icon name="more-h"></Icon>
//             </DropdownToggle>
//             <DropdownMenu end>
//               <ul className="link-list-plain">
//                 <li>
//                   <DropdownItem
//                     tag="a"
//                     href="#dropdownitem"
//                     onClick={(ev) => {
//                       ev.preventDefault();
//                     }}
//                   >
//                     View
//                   </DropdownItem>
//                 </li>
//                 <li>
//                   <DropdownItem
//                     tag="a"
//                     href="#dropdownitem"
//                     onClick={(ev) => {
//                       ev.preventDefault();
//                     }}
//                   >
//                     Invoice
//                   </DropdownItem>
//                 </li>
//                 <li>
//                   <DropdownItem
//                     tag="a"
//                     href="#dropdownitem"
//                     onClick={(ev) => {
//                       ev.preventDefault();
//                     }}
//                   >
//                     Print
//                   </DropdownItem>
//                 </li>
//               </ul>
//             </DropdownMenu>
//           </UncontrolledDropdown>
//         );
//       };

//   return (
//     <React.Fragment>
//     <Content page="component">

//    </Content>
//     <PreviewCard className='card-table'>

//           <table className="table table-orders">
//       <thead className="tb-odr-head">
//         <tr className="tb-odr-item">
//         <th className="tb-odr-info">
//             <span className="tb-odr-id">Language</span>

//           </th>
//           <th className="tb-odr-info">
//             <span className="tb-odr-id">User</span>

//           </th>
//           <th className="tb-odr-info">
//           <span className="tb-odr-id">Title</span>
//           </th>
//           <th className="tb-odr-info">
//           <span className="tb-odr-id">Status</span>
//           </th>
//           <th className="tb-odr-action">&nbsp;
//           <span className="tb-odr-id">Action</span>
//           </th>
//           <th className="tb-odr-action">&nbsp;
//           <span className="tb-odr-id">Action</span>
//           </th>
//         </tr>
//       </thead>
//       <tbody className="tb-odr-body">
//         {datalist.map((item) => {
//           return (
//             <tr className="tb-odr-item" key={item._id}>
//                  <td className="tb-odr-info">
//               <span className="tb-odr-id">{item.language}</span>
//               </td>
//               <td className="tb-odr-info">
//                 <span className="tb-odr-id">

//                     {item.username}

//                 </span>

//               </td>
//               <td className="tb-odr-info">
//               <span className="tb-odr-id">{item.title}</span>
//               </td>
//               <td className="tb-odr-info">
//               <span className="tb-odr-id">{item.status}</span>
//               </td>

//               <td className="tb-odr-action">
//               <div className="tb-odr-id">
//                   <Button color="primary" className="btn-sm" onClick={e => Approvedata(item, e) }>
//                     Approve
//                   </Button>
//                 </div>

//               </td>
//               <td className="tb-odr-action">
//                 <div className="tb-odr-id">
//                     <Button color="primary" className="btn-sm" onClick={e => Rejectdata(item, e) }>
//                         Reject
//                     </Button>
//                     </div>
//                 </td>
//             </tr>
//           );
//         })}
//       </tbody>
//     </table>

//     </PreviewCard>
//         {/* </Block> */}

//       {/* </Content> */}
//     </React.Fragment>
//   );
// };
// export default BlogRequestList;

const BlogRequestList = () => {
  const initialController = {
    page: 1,
    rowsPerPage: 10,
    // rowsPerPage: 10,
    language: "",
    status: "Pending",
  };
  const [datalist, setdatalist] = React.useState([]);
  const [filteredDatalist, setFilteredDatalist] = React.useState([]);
  const [datalistCount, setdatalistCount] = React.useState(0);
  const [controller, setController] = React.useState(initialController);

  const [authCheckModal, setAuthCheckModal] = useState(false);

  const authCheckToggle = () => setAuthCheckModal(!authCheckModal);

  const [search, setSearch] = React.useState("");
  const [flag, setFlag] = React.useState(false);
  const [viewItem, setViewItem] = useState([]);
  // const [token, setToken] = useState();
  const token = localStorage.getItem("token");
  console.log("Token: " + token);

  const searchRef = useRef(null);

  const Approvedata = (index, e) => {
    const data = { status: "Approve", id: index._id };
    new ServiceStore().UpdateData("writeblogtopic/updatedata", data, `Bearer ${token}`).then((res) => {
      console.log("token :::", token)
      getAlldataFile();
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })

    const notification = {
      notification: "Your blog request is approved"
    }
    new ServiceStore().InsertData(`notification/addnotification/${index.userID}`, notification, token).then((res) => {
      getAlldataFile();
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })
  };
  const Rejectdata = (index, e) => {
    const data = { status: "Reject", id: index._id };
    new ServiceStore().UpdateData("writeblogtopic/updatedata", data, `Bearer ${token}`).then((res) => {
      getAlldataFile();
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })
    const notification = {
      notification: "Your blog request is rejected"
    }
    new ServiceStore().InsertData(`notification/addnotification/${index.userID}`, notification, token).then((res) => {
      getAlldataFile();
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })
  };
  const Deletedata = (index, e) => {
    const method = `writeblogtopic/delete/`
    const token = localStorage.getItem("token")
    new ServiceStore().DeleteData(method, index._id, token).then((res) => {
      getAlldataFile();
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })
    const notification = {
      notification: "Your blog request is deleted"
    }
    new ServiceStore().InsertData(`notification/addnotification/${index.userID}`, notification, token).then((res) => {
      getAlldataFile();
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })
  };

  React.useEffect(
    () => getAlldataFile(),

    // setToken(localStorage.getItem("token"))
    [controller, flag]
  );
  React.useEffect(() => {
    if (search?.trim()) {
      const searchResults = fuzzysort
        .go(search, datalist?.rows, { keys: RequestListSearchKeys })
        ?.map((result) => result?.obj)
        ?.filter((e) => e);

      setFilteredDatalist([...searchResults]);
    } else {
      const defaultValues = datalist?.rows ? [...datalist?.rows] : [];
      console.log("datalist : ", datalist)
      setFilteredDatalist([...defaultValues]);
    }
  }, [search, datalist]);

  const getAlldataFile = () => {
    const methods = "writeblogtopic/filterRequest";
    const bodyData = {
      page: controller.page,
      limit: controller.rowsPerPage,
      language: controller.language,
      status: controller.status,
    };
    new ServiceStore().GetDataBYFilter(methods, bodyData).then((res) => {
      console.log("mydata : ", res)
      setdatalist(res.response.data);
      setdatalistCount(res.response.data.count);
    });
  };

  function handle(e) {
    const newdata = { ...controller };
    newdata[e.target.id] = e.target.value;
    setController({ ...newdata, page: 1 });
  }

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage + 1,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1,
    });
  };

  const resetForm = () => {
    searchRef.current.value = "";
    setSearch("");
    setController({ ...initialController });
  };

  const debounceSearch = debounce((query) => setSearch(query), 350);

  const handleSearch = (e) => debounceSearch(e?.target?.value);


  const [modal, setModal] = useState({
    view: false,
  });

  const onFormCancel = () => {
    setModal({ view: false });
  };
  const [currentData, setCurrentData] = useState()

  // const deleteData = (item) => {
  // console.log(item)
  // const method = `writeblogtopic/`
  // new ServiceStore().DeleteData(method,item._id).then((res) => {
  //   console.log("mydata : ",res)
  //   setFlag(!flag)
  // });
  // }

  const viewImages = (item) => {
    console.log('view images', item.image)
    let array = []
    array.push(item.image)
    setViewItem(array)
  };

  return (
    <React.Fragment>
      <Content page="component"></Content>
      {/* <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent><h3>Blog Request</h3></BlockHeadContent>
          <BlockHeadContent> <Button color="primary" size="lg" onClick={home}>
              Create
              </Button></BlockHeadContent>
        </BlockBetween>
        </BlockHead> */}
      <div>
        <form onSubmit={(e) => { }}>
          <div className="row" style={{ marginBottom: "10px", marginLeft: "16px" }}>
            <div style={{ marginBottom: "1rem" }}>
              <h3>Blog Request</h3>
            </div>
            <div className="col-md-3">
              <label style={{ marginLeft: "4px" }}>Language</label>
              <select
                name="language"
                onChange={(e) => handle(e)}
                id="language"
                style={{ marginLeft: "5px" }}
                className="form-control"
                value={controller?.language}
              >
                {RequestListLanguageFilters?.map((ele) => (
                  <option value={ele?.value} key={ele?.key}>
                    {ele?.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <label>Status</label>
              <select
                name="status"
                onChange={(e) => handle(e)}
                id="status"
                style={{ marginLeft: "5px" }}
                className="form-control"
                value={controller?.status}
              >
                <option value="All">All</option>
                <option value="Approve">Accepted</option>
                <option value="Pending" selected>
                  Pending
                </option>
                <option value="Reject">Rejected</option>
              </select>
            </div>
            <div className="col-md-3">
              <label>Search</label>
              <input
                className="form-control"
                placeholder="Search..."
                onChange={handleSearch}
                defaultValue={search}
                ref={searchRef}
              />
            </div>

            <div className="col-md-3">
              <button style={{ marginTop: "23px" }} type="button" className="btn btn-danger" onClick={resetForm}>
                Reset
              </button>
              <Link to="/admin-blog-create">
                <button
                  style={{ marginTop: "23px", marginLeft: "1rem" }}
                  type="button"
                  className="btn btn-primary"
                // onClick={() => {
                //   setModal({ add: true });
                // }}
                >
                  Create
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>
      <PreviewCard className="card-table table-responsive-md">
        <table className="table table-orders" >
          <thead className="tb-odr-head">
            <tr className="tb-odr-item">
              {/* <th className="tb-odr-info">
                <span className="tb-odr-id">Language</span>
              </th> */}
              <th className="tb-odr-info">
                <span className="tb-odr-id">User</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Title</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Status</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Image</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Description</span>
              </th>
              <th className="tb-odr-info" style={{ textAlign: "center" }}>
                <span className="tb-odr-id">Date & Time</span>
              </th>
              <th className="tb-odr-action" style={{ textAlign: "center" }}>
                &nbsp;
                <span className="tb-odr-id" style={{ textAlign: "center", width: "210px" }}>Action</span>
              </th>
              {/* <th className="tb-odr-action">
                &nbsp;
                <span className="tb-odr-id">Action</span>
              </th> */}
            </tr>
          </thead>

          <tbody className="tb-odr-body">
            {filteredDatalist?.length > 0 ? (
              filteredDatalist?.map((item) => {
                return (
                  <tr className="tb-odr-item" key={item._id}>
                    {/* <td className="tb-odr-info">
                      <span style={{ color: "#007bff" }} className="tb-odr-id">
                        {item.language === "english" ? "English" : "मराठी"}
                      </span>
                    </td> */}
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">{item.username}</span>
                    </td>
                    <td
                      style={{
                        whiteSpace: "nowrap !important",
                        overflow: "hidden !important",
                        textOverflow: "ellipsis !important",
                      }}
                      className="tb-odr-info"
                    >
                      <span className="tb-odr-id">{item.title}</span>
                    </td>
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">{item.status}</span>
                    </td>
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">
                        <button
                          onClick={() => viewImages(item)}
                          className="btn btn-info"
                          data-bs-toggle="modal"
                          data-bs-target="#modal2"
                          style={{ padding: '5px' }}
                        >
                          View
                        </button>
                      </span>
                    </td>
                    <td className="tb-odr-info">
                      <Popup
                        contentStyle={{ width: "auto", height: "auto" }}
                        trigger={<button className=" btn btn-info" style={{ padding: '5px' }}>View</button>}
                        position="center center"
                      >
                        <div dangerouslySetInnerHTML={{ __html: item.subtitle }} />

                      </Popup>

                      {/* <button className=" btn btn-info" onClick={()=>{setModal({view:true});setCurrentData(item)}} style={{ padding: "5px" }}>
                            View
                          </button> */}
                    </td>
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">{new Date(item.createdAt).toLocaleString()}</span>
                    </td>
                    <td className="tb-odr-action" style={{ textAlign: 'center' }}>
                      {/* <div className="tb-odr-id"> */}
                      <Row>
                        {item.status == "Pending" &&
                          <Col xs="12" sm="12" md="4" lg="4" >
                            <Button
                              color="primary"
                              className="btn-sm btn-success"
                              onClick={(e) => Approvedata(item, e)}
                              style={{ padding: "5px", marginBottom: 6 }}
                            >
                              Approve
                            </Button>
                          </Col>
                        }
                        {item.status == "Pending" &&
                          <Col xs="12" sm="12" md="4" lg="4">
                            <Button
                              color="primary"
                              className="btn-sm btn-danger"
                              onClick={(e) => Rejectdata(item, e)}
                              style={{ padding: "5px", marginBottom: 6 }}
                            >
                              Reject
                            </Button>
                          </Col>
                        }

                        <Col xs="12" sm="12" md="4" lg="4">
                          <Button
                            color="primary"
                            className="btn-sm"
                            onClick={(e) => Deletedata(item, e)}
                            style={{ padding: "5px", marginBottom: 10 }}
                          >
                            Delete
                          </Button>

                        </Col>
                      </Row>

                    </td>
                    {/* <td className="tb-odr-action">
                      <div className="tb-odr-id">
                        <Button color="primary" className="btn-sm" onClick={(e) => Rejectdata(item, e)}>
                          Reject
                        </Button>
                      </div>
                    </td> */}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  <h3>No Record Found</h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {datalist?.count !== 0 && datalist?.rows?.length > 0 ? (
          <>
            <div className="row">
              <div className="col" style={{ textAlign: "end", paddingTop: "1rem" }}>Current Page : {controller.page}</div>
              <div className="col">
                <TablePagination
                  style={{ marginTop: "10px" }}
                  component="div"
                  onPageChange={handlePageChange}
                  page={controller.page - 1}
                  count={datalistCount}
                  rowsPerPage={controller.rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /></div>
            </div>
          </>
        ) : null}
      </PreviewCard>
      {/* </Block> */}

      {/* </Content> */}

      {/* <Modal isOpen={modal.view} toggle={() => setModal({ view: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title"> Description</h5>
              <div className="mt-4">
              </div>
              Sint quis sit duis ullamco dolor nostrud quis incididunt sunt et mollit nostrud. Tempor quis ullamco anim nulla ut laboris eiusmod. Ad dolor laboris eu irure sunt enim sunt. Minim nisi incididunt
               elit sunt mollit reprehenderit laboris deserunt ex irure fugiat commodo non aliquip.
               Est ad deserunt ipsum sint id non deserunt. Proident cupidatat sunt cillum fugiat esse. Do eu consequat sunt 
               exercitation aliqua elit officia dolor id ipsum pariatur officia.
               Sint quis sit duis ullamco dolor nostrud quis incididunt sunt et mollit nostrud. Tempor quis ullamco anim nulla ut laboris eiusmod. Ad dolor laboris eu irure sunt enim sunt. Minim nisi incididunt
               elit sunt mollit reprehenderit laboris deserunt ex irure fugiat commodo non aliquip.
               Est ad deserunt ipsum sint id non deserunt. Proident cupidatat sunt cillum fugiat esse. Do eu consequat sunt 
               exercitation aliqua elit officia dolor id ipsum pariatur officia.
            </div>
          </ModalBody>
        </Modal> */}

      {/* view images */}

      <div className="modal fade" id="modal2" tabindex="-1" aria-labelledby="modal2Label" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal2Label">
                Images
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>
                {viewItem.map((img, i) => {
                  return (
                    <>
                      <div key={i + 1}>
                        {/* <button className="btn btn-secondary" onClick={() => handleDeleteImage(i)}>
                          X
                        </button> */}
                        <img src={ImageUrl + img} />
                      </div>
                    </>
                  );
                })}
              </p>
            </div>
            {/* <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div> */}
          </div>
        </div>
      </div>

      {
        authCheckModal &&
        <AuthModal modalOpen={authCheckModal} modelClose={authCheckToggle} />
      }


    </React.Fragment>
  );
};
export default BlogRequestList;
