import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";

import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";

const AboutUs = () => {
  const { errors, register, handleSubmit } = useForm();
  const [id] = React.useState("form-1");
  const [file, setFile] = React.useState("");
  const [file9, setFile9] = React.useState("");
  const [file11, setFile11] = React.useState("");

  const [detailsList, setDetailsList] = React.useState({});
  const history = useHistory();

  const onFormSubmit = (e) => {
    const dataid = history.location.state?.detail._id;
    const token = localStorage.getItem("token");

    const data = e;

    if (dataid) {
      data.id = dataid;
      if (file) {
        const formData = new FormData();
        for (const single_file of file) {
          formData.append("file", single_file);
        }
        new ServiceStore().UploadFile("aboutus/ImageUpload", formData).then((res) => {
          data.line6 = res.data.data;
          new ServiceStore().UpdateData("aboutus/updatedata", data,token).then((res) => {
          });
        });
      } else {
        data.line6 = detailsList.line6;
        new ServiceStore().UpdateData("aboutus/updatedata", data,token).then((res) => {
        });
      }
      if (file9) {
        const formData9 = new FormData();
        for (const single_file of file9) {
          formData9.append("file", single_file);
        }
        new ServiceStore().UploadFile("aboutus/ImageUpload", formData9).then((res) => {
          data.line9 = res.data.data;
          new ServiceStore().UpdateData("aboutus/updatedata", data,token).then((res) => {
          });
        });
      } else {
        data.line9 = detailsList.line9;
        new ServiceStore().UpdateData("aboutus/updatedata", data,token).then((res) => {
        });
      }

      if (file11) {
        const formData11 = new FormData();
        for (const single_file of file11) {
          formData11.append("file", single_file);
        }
        new ServiceStore().UploadFile("aboutus/ImageUpload", formData11).then((res) => {
          data.line11 = res.data.data;
          new ServiceStore().UpdateData("aboutus/updatedata", data,token).then((res) => {
          });
        });
      } else {
        data.line11 = detailsList.line11;
        new ServiceStore().UpdateData("aboutus/updatedata", data,token).then((res) => {
        });
      }
      new ServiceStore().UpdateData("aboutus/updatedata", data,token).then((res) => {
        alert("Data updated successfully");
        history.push("/aboutuslist");
      });
    } else {
      const formData = new FormData();
      const method = "aboutus/";
      for (const single_file of file) {
        formData.append("file", single_file);
      }

      const formData9 = new FormData();
      for (const single_file of file9) {
        formData9.append("file", single_file);
      }

      const formData11 = new FormData();
      for (const single_file of file11) {
        formData11.append("file", single_file);
      }
      new ServiceStore().UploadFile("aboutus/ImageUpload", formData).then((res) => {
        data.line6 = res.data.data;
        new ServiceStore().UploadFile("aboutus/ImageUpload", formData9).then((res) => {
          data.line9 = res.data.data;

          new ServiceStore().UploadFile("aboutus/ImageUpload", formData11).then((res) => {
            data.line11 = res.data.data;
            new ServiceStore().InsertData(method, data).then((res) => {
              alert("Data updated successfully");
              history.push("/aboutuslist");
            });
          });
        });
      });
    }
  };
  const formClass = classNames({
    "form-validate": true,
  });
  const onFileSelect = (event) => {
    const file = event.target.files;
    if (file.length < 2 || file.length > 2) {
      window.alert("Please select only TWO files...");
    } else {
      setFile(file);
    }

    // if (event.target.files[0].size < 100000000) {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file); // toBase64
    //     setFile(file)
    // }else{
    //   this.imgeerror = 'Please Select Max 100MB Size File'
    // }
  };
  const onFileSelect9 = (event) => {
    const file = event.target.files;
    if (file.length < 2 || file.length > 2) {
      window.alert("Please select only TWO files...");
    } else {
      setFile9(file);
    }
  };
  const onFileSelect11 = (event) => {
    const file = event.target.files;
    if (file.length < 2 || file.length > 2) {
      window.alert("Please select only TWO files...");
    } else {
      setFile11(file);
    }
  };
  React.useEffect(() => {
    const dataid = history.location.state?.detail._id;
    if (dataid) {
      new ServiceStore().GetDataByID("aboutus/", dataid).then((res) => {
        setDetailsList(res.data);
      });
    }
  }, []);

  return (
    <React.Fragment>
      {/* <Head title="Form Validation"></Head> */}
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BlockTitle tag="h2" className="fw-normal">
              About Us
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <BlockHead>
            <BlockHeadContent></BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <React.Fragment>
              <Form className={formClass} onSubmit={handleSubmit(onFormSubmit)}>
                <Row className="g-gs">
                  {!history.location.state?.detail._id && (
                    <Col lg="7">
                      <div className="form-group">
                        <ul className="custom-control-group g-3 align-center flex-wrap">
                          <li>
                            <div className="custom-control custom-radio">
                              <input
                                ref={register({ required: true })}
                                type="radio"
                                className="custom-control-input"
                                defaultChecked
                                name="language"
                                id="reg-enable"
                                value="english"
                              />
                              <label className="custom-control-label" htmlFor="reg-enable">
                                English
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-radio">
                              <input
                                ref={register({ required: true })}
                                type="radio"
                                className="custom-control-input"
                                name="language"
                                id="reg-disable"
                                value="marathi"
                              />
                              <label className="custom-control-label" htmlFor="reg-disable">
                                Marathi
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  )}

                  <div className="banner-tab">
                    <h3>Banner</h3>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Banner 1 Main Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            defaultValue={detailsList?.line1}
                            type="text"
                            id="fv-full-name"
                            name="line1"
                            className="form-control"
                          />
                          {errors.line1 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Banner 1 Button 1 Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-full-name"
                            name="line2"
                            className="form-control"
                            defaultValue={detailsList?.line2}
                          />
                          {errors.line2 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Banner 1 Button 2 Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line3"
                            className="form-control"
                            defaultValue={detailsList?.line3}
                          />
                          {errors.line3 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Banner 1 Description
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line4"
                            className="form-control"
                            defaultValue={detailsList?.line4}
                          />
                          {errors.line4 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                  </div>

                  <div className="banner-tab">
                    <h3>Transformation</h3>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Slider Main Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line5"
                            className="form-control"
                            defaultValue={detailsList?.line5}
                          />
                          {errors.line5 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Slider 1 Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line7"
                            className="form-control"
                            defaultValue={detailsList?.line7}
                          />
                          {errors.line7 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Slider 1 Images
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: false })}
                            type="file"
                            accept="image/*"
                            id="fv-subject"
                            name="line16"
                            className="form-control"
                            onChange={onFileSelect}
                            multiple
                            defaultValue={detailsList?.line16}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Slider 2 Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line8"
                            className="form-control"
                            defaultValue={detailsList?.line8}
                          />
                          {errors.line8 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Slider 2 Images
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: false })}
                            type="file"
                            accept="image/*"
                            id="fv-subject"
                            name="line9"
                            className="form-control"
                            onChange={onFileSelect9}
                            multiple
                            defaultValue={detailsList?.line9}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Slider 3 Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line10"
                            className="form-control"
                            defaultValue={detailsList?.line10}
                          />
                          {errors.line10 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Slider 3 Images
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: false })}
                            type="file"
                            accept="image/*"
                            id="fv-subject"
                            name="line11"
                            className="form-control"
                            onChange={onFileSelect11}
                            multiple
                            defaultValue={detailsList?.line11}
                          />
                        </div>
                      </div>
                    </Col>
                  </div>

                  <Col md="12">
                    <div className="form-group">
                      <Button color="primary" size="lg">
                        Save Information
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default AboutUs;
