// import { Icon } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Form, Modal, ModalBody } from "reactstrap";
import { BlockBetween, BlockHead, BlockHeadContent, PreviewCard, RSelect } from "../../../components/Component";
import { Content } from "antd/es/layout/layout";
import Icon from "../../../components/icon/Icon";
import ServiceStore from "../../../utils/ServiceStore";

const Users = () => {
  const [datalist, setdatalist] = React.useState([])
  const [deletedata, setdeletedata] = React.useState("");
  const [editData, setEditData] = React.useState("");
  const [selectedValue, setSelectedValue] = useState('');
  const [refresh, setRefresh] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState()

  const token = localStorage.getItem("token");

  const onFormCancel = () => {
    setModal({ add: false });
    setModal({ edit: false });
  };
  const filterStatus = [
    { value: "english", label: "English" },
    { value: "marathi", label: "Marathi" },
  ];
  const selectgender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" }
  ]
  const [modal, setModal] = useState({
    add: false,
  });

  React.useEffect(() => {

    getAlldataFile()


  }, [refresh]);

  const getAlldataFile = () => {
    const methods = "user/"
    new ServiceStore().GetAllData(methods).then((res) => {
      setdatalist(res.data)
    });
  }

  const Deletedata = (e, index) => {
    const methods = "user/";
    new ServiceStore().DeleteData(methods, deleteId, token).then((res) => {
      setdeletedata("true");
      setModal({ deleteUser: false })
      setRefresh(!refresh)
    });
  };
  const handleSelectChange = (value) => {
    console.log(value)
    setSelectedValue(value.value);
  };
  const handleChange = (e) => {
    console.log(e.target.value)
    const { name, value } = e.target
    setEditData((prevdata) => ({
      ...prevdata,
      [name]: value
    }))
  }
  const handleEditdata = (e) => {
    e.preventDefault();
    console.log(e, editData)
    const data = {
      name: editData.name,
      email: editData.email,
      mobile: editData.mobile,
      gender: selectedValue,
      _id: editData._id,
      id: editData._id
    }
    const methods = "/user/updatedata";
    new ServiceStore().UpdateData(methods, data).then((res) => {
      //   setdeletedata("true");
    });
  }
  // const handleSubmit=(e)=>{
  //     e.preventDefault();
  //     console.log(e,editData)
  //     const methods = "/updatedata";
  //     new ServiceStore().UpdateData(methods, editData,editData._id).then((res) => {
  //         console.log(res);
  //     //   setdeletedata("true");
  //     });
  // }

  return (
    <React.Fragment>
      {/* <Head title="Form Validation">
     
        </Head> */}
      <Content page="component" style={{ marginTop: '5rem' }}>
        <BlockHead size="sm">
          <BlockBetween >
            <BlockHeadContent><h3 style={{ padding: '2rem' }}>User List</h3></BlockHeadContent>
            {/* <BlockHeadContent> <Button color="primary" size="lg" style={{ marginRight: '3rem' }}
                    onClick={() => {
                        setModal({ add: true });
                      }}
                        >
                            Create
                        </Button></BlockHeadContent> */}
          </BlockBetween>
        </BlockHead>
        {/* <div className="form-group" style={{ float: "right" }}>
            <Button color="primary" size="lg" onClick={home}>
              Create
            </Button>
          </div> */}
      </Content>
      {/* <Block size="lg">
            <BlockHead>
              <BlockHeadContent>
              
              </BlockHeadContent>
            </BlockHead> */}
      <PreviewCard className="card-table">
        <table className="table table-orders">
          <thead className="tb-odr-head">
            <tr className="tb-odr-item">
              <th className="tb-odr-info">
                <span className="tb-odr-id">NAME</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">EMAIL</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">MOBILE</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">GENDER</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Action</span>
              </th>
            </tr>
          </thead>
          <tbody className="tb-odr-body">
            {datalist.length > 0 ? datalist.map((item) => {
              return (
                <tr className="tb-odr-item"
                  key={item._id}
                >
                  <td className="tb-odr-info">
                    <span className="tb-odr-id">
                      <a
                        href="#id"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        {item.name}
                      </a>
                    </span>
                  </td>
                  <td className="tb-odr-info">
                    {item.email}
                  </td>
                  <td className="tb-odr-amount">
                    <span className="tb-odr-id">
                      {item.mobile}
                    </span>
                  </td>
                  <td className="tb-odr-info">
                    <span className="tb-odr-id">
                      {item.gender}
                    </span>
                  </td>
                  <td className="tb-odr-info">
                    <div className="tb-odr-id">
                      <span className="tb-odr-id">
                        {/* <Icon
                   onClick={() => {
                    setModal({ edit: true });
                    setEditData(item);
                  }}
                            name="edit-alt-fill"
                            style={{ cursor: "pointer" }}
                            // onClick={setModal({ edit: true })}
                          ></Icon> &nbsp;&nbsp;&nbsp; */}
                        <Icon
                          name="trash-empty-fill"
                          style={{ cursor: "pointer" }}
                          onClick={() => {setModal({ deleteUser: true });setDeleteId(item._id)}}
                        ></Icon>
                      </span>
                    </div>
                  </td>
                </tr>
              );
            }) : (
              <tr>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  <h3>No Record Found</h3>
                </td>
              </tr>
            )
            }
          </tbody>
        </table>

        <Modal isOpen={modal.deleteUser} toggle={() => setModal({ deleteUser: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5>Are you sure ?</h5>
              <div className="mt-4">

                <button style={{ marginTop: "23px" }} type="button" className="btn btn-danger" onClick={() => Deletedata()}>
                  Confirm
                </button>
                <button style={{ marginTop: "23px", marginLeft: '1rem' }} type="button" className="btn btn-primary" onClick={onFormCancel}>
                  Cancel
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>

      </PreviewCard>
      {/* </Block> */}

      {/* </Content> */}

      <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#close"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Add User</h5>
            <div className="mt-4">
              <Form className="row gy-4" autoComplete="off"
              //   onSubmit={handleSubmit}
              >
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="title"
                    //   value={formData.title}
                    //   onChange={handleChange}

                    // value={editItem.id}
                    // defaultValue={editItem.id}
                    // placeholder={editItem.id}
                    // readOnly={true}
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      className="form-control"
                      type="text"
                      name="title"
                    //   value={formData.title}
                    //   onChange={handleChange}

                    // value={editItem.id}
                    // defaultValue={editItem.id}
                    // placeholder={editItem.id}
                    // readOnly={true}
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Mobile</label>
                    <input
                      className="form-control"
                      type="text"
                      // name="id"
                      // onChange={handleImageChange}
                      name="imageInput"
                    //   onChange={handleImageChange}
                    // value={editItem.id}
                    // defaultValue={editItem.id}
                    // placeholder={editItem.id}
                    // readOnly={true}
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>

                <Col md="6">

                  <div className="form-group">

                    <label className="form-label">Select Gender</label>
                    <RSelect options={selectgender} placeholder="Select Gender"
                    // onChange={handleSelectChange} 
                    />

                  </div>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="md"
                        type="submit"
                        onClick={() => {
                          setModal({ add: false });
                        }}
                      >
                        Submit
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Edit User</h5>
            <div className="mt-4">
              <Form className="row gy-4" autoComplete="off"
                onSubmit={(e) => handleEditdata(e, editData)}
              >
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={handleChange}

                      value={editData.name}
                    // defaultValue={editItem.id}
                    // placeholder={editItem.id}
                    // readOnly={true}
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      className="form-control"
                      type="text"
                      name="email"
                      //   value={formData.title}
                      onChange={handleChange}
                      value={editData.email}

                    // value={editItem.id}
                    // defaultValue={editItem.id}
                    // placeholder={editItem.id}
                    // readOnly={true}
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Mobile</label>
                    <input
                      className="form-control"
                      type="text"
                      // name="id"
                      // onChange={handleImageChange}
                      name="mobile"
                      value={editData.mobile}
                      onChange={handleChange}

                    //   onChange={handleImageChange}
                    // value={editItem.id}
                    // defaultValue={editItem.id}
                    // placeholder={editItem.id}
                    // readOnly={true}
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>

                <Col md="6">

                  <div className="form-group">

                    <label className="form-label">Select Gender</label>
                    <RSelect
                      // options={selectgender} placeholder="Select Gender" 
                      // onChange={handleSelectChange} 
                      options={selectgender}
                      onChange={handleSelectChange}
                    // value={editData.name}

                    />

                  </div>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="md"
                        type="submit"
                        onClick={() => {
                          setModal({ add: false });
                        }}
                      >
                        Submit
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default Users;