import React from "react";
import Logo from "../../images/logopuneriver.png";
import LogoDark from "../../images/logopuneriver.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { Link } from "react-router-dom";
import { useState } from "react";
import ServiceStore from "../../utils/ServiceStore";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ForgotPassword = () => {
  const [email, setEmail]=useState();
  const [otp, setOTP]=useState();
  const [newPassword, setNewPassword]=useState();
  const [confirmNewPassword, setConfirmNewPassword]=useState();
  const [isError, setIsError]=useState();
  const [userData, setUserData]=useState();
  const [openotp, setOpenotp]=useState(false);
  const [showconfirmpassword, setShowconfirmpassword]=useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const history = useHistory()

  const handlePaste = (event) => {
    event.preventDefault();
  };
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handleOtp = (e) => {
    setOTP(e.target.value)
  }
  const handleNewPassword = (e) => {
    setNewPassword(e.target.value)
  }

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const handleConfirmNewPassword = (e) => {
    console.log("newPassword", newPassword)
    console.log("confirmNewPassword", e.target.value)
    if(newPassword !== e.target.value) {
      setIsError(false)
    }
    else{
      setIsError(true)
    }
    setConfirmNewPassword(e.target.value)
  }
  const handleSendEmail = (e)=>{
    e.preventDefault();
    const method = 'adminuser/forgotpasswordotp'
    const body = {
      email:email
    }
    new ServiceStore().InsertData(method,body).then((res) => {
      console.log("res", res)
      setUserData(res.response.data)
      if(res.response.status === 1)
      {
        setOpenotp(true)
      }
      else{
        alert("Something went wrong")
      }

    }).catch((err) => {
      alert("Something went wrong")
    })
  }

  const handleSendOTP = (e) => {
    e.preventDefault();
    const method = 'adminuser/matchotp'
    const body = {
      otp:otp
    }
    new ServiceStore().InsertData(method,body).then((res) => {
      console.log("res", res)
      if(res.response.status === 1)
      {
        setShowconfirmpassword(true)
      }
      else{
        alert("Something went wrong")
      }
    }).catch((err) => {
      alert("Something went wrong")
    })
  }

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    const method = 'adminuser/updatedata'

    console.log("userData : ", userData)
    const body = {
      password:newPassword,
      email:userData.email,
      aboutus:userData.aboutus,
      blog:userData.blog,
      communityform:userData.communityform,
      facilities:userData.facilities,
      gallary:userData.gallary,
      home:userData.home,
      otp:"",
      id:userData._id
    }
    new ServiceStore().UpdateData(method,body).then((res) => {
      console.log("res", res)
      if(res.response.status === 1)
      {
        history.push("/auth-login")
      }
      else{
        alert("Something went wrong")
      }
    }).catch((err) => {
      alert("Something went wrong")
    })

  }

  return (
    <React.Fragment>
      <Head title="Forgot-Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>


          {!showconfirmpassword && <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">Reset password</BlockTitle>
                <BlockDes>
                  <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <form>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="default-01"
                  placeholder="Enter your email address"
                  onChange={handleEmail}
                />
              </div>
              <div className="form-group">
                <Button color="primary" size="lg" className="btn-block" onClick={handleSendEmail}>
                  Send Reset Link
                </Button>
              </div>
            </form>
           {openotp && <form>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Enter OTP
                  </label>
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="default-01"
                  placeholder="Enter your email address"
                  onChange={handleOtp}
                />
              </div>
              <div className="form-group">
              {/* <Link className="link link-primary link-sm" 
              // to={`${process.env.PUBLIC_URL}/confirmpassword`}
              > */}
                <Button color="primary" size="lg" className="btn-block" onClick={handleSendOTP}>
                  Confirm OTP
                </Button>
                {/* </Link> */}
              </div>
            </form>}
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Return to login</strong>
              </Link>
            </div>
          </PreviewCard>}


         {showconfirmpassword&& <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h5">Reset password</BlockTitle>
              <BlockDes>
                <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <form>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  Enter New Password
                </label>
              </div>
              <input
                type={showNewPassword ? 'text' : 'password'}
                className="form-control form-control-lg"
                id="default-01"
                placeholder="Enter your email address"
                onChange={handleNewPassword}
              />
              {/* <div className="input-group-append">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={toggleShowNewPassword}
            >
              {showNewPassword ? 'Hide' : 'Show'}
            </button>
          </div> */}
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  Confirm New Password
                </label>
              </div>
              <input
                type={showConfirmNewPassword ? 'text' : 'password'}
                className="form-control form-control-lg"
                id="default-01"
                placeholder="Enter your email address"
                onPaste={handlePaste}
                onChange={handleConfirmNewPassword}
              />
              {/* <div className="input-group-append">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={toggleShowConfirmNewPassword}
            >
              {showConfirmNewPassword ? 'Hide' : 'Show'}
            </button>
          </div> */}
            </div>
            {isError === false ?  <div>Password not matched</div> : ''}
            <div className="form-group">
              <Button color="primary" size="lg" className="btn-block" onClick={handleUpdatePassword}>
                Update Password
              </Button>
            </div>
          </form>
          <div className="form-note-s2 text-center pt-4">
            <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
              <strong>Return to login</strong>
            </Link>
          </div>
        </PreviewCard>}
        </Block>
        {/* <AuthFooter /> */}
      </PageContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;
