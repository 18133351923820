import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Form, Modal, ModalBody, ModalHeader, ModalFooter, Row } from "reactstrap";
import { BlockBetween, BlockHead, BlockHeadContent, PreviewCard, RSelect } from "../../../components/Component";
import { Content } from "antd/es/layout/layout";
import Icon from "../../../components/icon/Icon";
import ServiceStore from "../../../utils/ServiceStore";
import { RequestListLanguageFilters } from "../../../utils/constant";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { CiViewList } from "react-icons/ci";
import { GrCompliance } from "react-icons/gr";
import { IoMdCloseCircle } from "react-icons/io";
import axios from "axios";
import AuthModal from "../../auth/AuthModal";

const UserUploadImageRequest = () => {



  const initialController = {
    page: 1,
    rowsPerPage: 3,
    // rowsPerPage: 10,
    language: "",
    status: "Pending",
  };
  const [datalist, setdatalist] = React.useState([]);
  const [deletedata, setdeletedata] = React.useState("");
  const [editData, setEditData] = React.useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [update, setUpdate] = useState(false);
  const [viewItem, setViewItem] = useState([]);
  const [Refresh, setRefresh] = useState(false)
  const [searchTerm, setSearchTerm] = React.useState("");
  const [controller, setController] = React.useState(initialController);
  const [loading, setIsLoading] = useState(false)

  const [status, setStatus] = useState("All")
  const [itemUpdateStatus, setItemUpdateStatus] = useState("")
  const [buttonStatus, setButtonStatus] = useState("")

  const [details, setDetails] = useState(null)

  const ImageUrl = "https://api.puneri.foxberry.link/";
  const token = localStorage.getItem("token");


  const [authCheckModal, setAuthCheckModal] = useState(false);

  const authCheckToggle = () => setAuthCheckModal(!authCheckModal);


  const getAlldataFile = async () => {
    await axios.get('https://api.puneri.foxberry.link/admin/UserUploadImage')
      .then((response) => {
        console.log("Updated Response", response)
        setdatalist(response?.data?.data?.list)
      })
      .catch(() => {
        console.log("Updated Error")
      })
  }


  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const searchTerm = e.target.value
    if (searchTerm) {
      const filterData = datalist.filter((data) => {
        const regex = new RegExp(searchTerm, 'i')
        return data.location.match(regex)
      });
      setdatalist(filterData);
    } else {
      getAlldataFile();
    }
  }

  const resetForm = () => {
    setSearchTerm('');
    getAlldataFile();
  }

  // useEffect(() => {
  //   getAlldataFile()
  // }, [update, Refresh])

  const Deletedatas = (e, data) => {
    // http://localhost:3001/admin/UserUploadImage/6489512b9b540d282c482e37
    const methods = `UserUploadImage/`;
    new ServiceStore().DeleteData(methods, data.userId).then((res) => {
      console.log("delete", res);
      if (res) {
        setRefresh(!Refresh)
      }
      // setdeletedata("true");
      setUpdate(!update)//this is new
    });
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleViewDetails = (item) => {
    setModalOpen(!modalOpen);
    setDetails(item)
  }




  // ------


  const handleUpdateRecord = async (id, userId, itmStatus) => {

    const updatedData = {
      status: itmStatus
    };


    setItemUpdateStatus(id)
    setButtonStatus(itmStatus)

    const methods = `UserUploadImage/statusupdate/${id}`;

    new ServiceStore().UpdateData(methods, updatedData, token).then((res) => {
      if (res) {
        setRefresh(!Refresh)
        setItemUpdateStatus("")



        if (status !== "All") {
          const updatedData = datalist.filter(item => item._id !== id);
          setdatalist(updatedData);
        } else {


          const itemIndex = datalist.findIndex(item => item._id === id);

          // Create a copy of the array to avoid mutating state directly
          const updatedItems = [...datalist];

          // Update the status of the item (assuming 'Approve' as the new status)
          updatedItems[itemIndex] = { ...updatedItems[itemIndex], status: itmStatus };

          // Update the state with the new array
          setdatalist(updatedItems);
        }
      }
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })

    const notification = {
      notification: `Your Image request is ${itmStatus}ed`
    }

    new ServiceStore().InsertData(`notification/addnotification/${userId}`, notification, token).then((res) => {
      // getAlldataFile();
      setItemUpdateStatus("")
    });
  }

  const getStatusRecordData = async (itmStatus) => {
    setIsLoading(true)
    await axios.post('https://api.puneri.foxberry.link/admin/UserUploadImage/filterRequest', { status: itmStatus }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
        setIsLoading(false)
        setdatalist(response?.data?.data?.rows)
      })
      .catch((error) => {
        setIsLoading(false)
        console.log("Filter Data Error", error)
      });
  }

  useEffect(() => {
    setStatus("All")
    getStatusRecordData(status)
  }, [])




  return (
    <>

      <Content page="component" style={{ marginTop: "5rem" }}>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <h3 style={{ padding: "2rem" }}>User upload image requests</h3>
            </BlockHeadContent>

          </BlockBetween>
        </BlockHead>

      </Content>

      <div>
        <form onSubmit={(e) => { }}>
          <div className="row" style={{ marginBottom: "10px", marginLeft: "16px" }}>
            <div className="col-md-3">
              <label>Status</label>
              <select
                name="status"
                // onChange={(e) => { handle(e); setStatus(e.target.value); }}
                onChange={(e) => { setStatus(e.target.value); getStatusRecordData(e.target.value) }}
                id="status"
                style={{ marginLeft: "5px" }}
                className="form-control"
                value={status}
              >
                <option value="All">All</option>
                <option value="Approve">Accepted</option>
                <option value="Pending" selected>
                  Pending
                </option>
                <option value="Reject">Rejected</option>
              </select>
            </div>
            <div className="col-md-3">
              <label>Search</label>
              <input
                className="form-control"
                placeholder="Search..."
                onChange={handleSearch}
                value={searchTerm}
              // ref={searchRef}
              />
            </div>

            <div className="col-md-3">
              <button style={{ marginTop: "23px" }} type="button" className="btn btn-danger"
                onClick={resetForm}
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </div>

      <PreviewCard className="card-table">

        <table className="table table-orders">
          <thead className="tb-odr-head">
            <tr className="tb-odr-item">
              <th className="tb-odr-info">
                <span className="tb-odr-id">TITLE</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">LOCATION</span>
              </th>
              {/* <th className="tb-odr-info">
                <span className="tb-odr-id" style={{ textAlign: 'center' }}>IMAGE</span>
              </th> */}

              <th className="tb-odr-info">
                <span className="tb-odr-id">STATUS </span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id" style={{ textAlign: 'center' }}>Action</span>
              </th>
            </tr>
          </thead>
          <tbody className="tb-odr-body">
            {
              loading
                ?
                <tr>
                  <td colSpan={4} style={{ textAlign: "center" }}>
                    <h4 style={{ marginTop: 12 }}>Loading....!</h4>
                  </td>
                </tr>
                :
                datalist.length > 0 ? (
                  datalist.map((item) => {
                    return (
                      <tr className="tb-odr-item" key={item._id}>
                        <td className="tb-odr-info">
                          <span className="tb-odr-id">
                            <a
                              href="#id"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              {item.title}
                            </a>
                          </span>
                        </td>
                        <td className="tb-odr-info">
                          <span className="tb-odr-id"> {item.location}</span>
                        </td>


                        <td className="tb-odr-info">
                          <p style={{ fontWeight: 700 }}>
                            {item?.status}
                          </p>
                        </td>

                        <td className="tb-odr-amount" style={{ textAlign: "center" }}>

                          <Row>
                            {
                              status !== "Approve" &&
                              <Col xs="12" md="3" lg="3">

                                {/* <p onClick={() => { handleViewDetails(item); handleStatusUpdate(item, "Approve") }}>Approve</p> */}

                                <Button
                                  style={{ padding: "5px", marginBottom: 10 }}
                                  color="primary"
                                  className={item?.status === "Approve" ? "btn btn-secondary" : "btn btn-success"}
                                  // onClick={(e) => handleStatusUpdate(item, "Approve")}
                                  onClick={() => { handleUpdateRecord(item?._id, item?.userId, "Approve") }}
                                  disabled={item?.status === "Approve" ? true : false}
                                >
                                  {item?._id === itemUpdateStatus && buttonStatus === "Approve" ? "Updating...!" : "Approve"}

                                </Button>
                              </Col>
                            }
                            {
                              status !== "Reject" &&
                              <Col xs="12" md="3" lg="3">
                                <Button
                                  style={{ padding: "5px", marginBottom: 10 }}

                                  color="primary"
                                  className={item?.status === "Reject" ? "btn btn-secondary" : "btn btn-warning"}
                                  // onClick={(e) => handleStatusUpdate(item, "Reject")}
                                  onClick={() => { handleUpdateRecord(item?._id, item?.userId, "Reject") }}
                                  disabled={item?.status === "Reject" ? true : false}
                                >

                                  {item?._id === itemUpdateStatus && buttonStatus === "Reject" ? "Updating...!" : "Reject"}
                                </Button>
                              </Col>
                            }
                            <Col xs="12" md="3" lg="3">

                              <Button
                                style={{ padding: "5px", marginBottom: 10 }}

                                color="primary"
                                className="btn btn-info"
                                onClick={(e) => handleViewDetails(item, "Reject")}
                              >
                                View
                              </Button>
                            </Col>

                            <Col xs="12" md="3" lg="3">

                              <Button
                                style={{ padding: "5px", marginBottom: 10 }}

                                color="primary"
                                className="btn btn-danger"
                                onClick={(e) => Deletedatas(e, item)}
                              >
                                Delete
                              </Button>
                            </Col>


                          </Row>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10} style={{ textAlign: "center" }}>
                      <h3>No Record Found</h3>
                    </td>
                  </tr>
                )}
          </tbody>
        </table>

      </PreviewCard>

      {
        details !== undefined &&
        details !== null &&
        <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
          {/* <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Uploaded Image Details</ModalHeader> */}
          <ModalBody>
            <p style={{ fontSize: 22, color: '#000', fontWeight: 600 }}>{details?.title}</p>
            <p style={{ fontSize: 16 }}>{details?.title}</p>
            <div>
              {
                details?.images !== undefined &&
                details?.images !== null &&
                details?.images.length > 0 &&
                details?.images.map((img, i) => {
                  return (


                    <div style={{ position: 'relative', height: 100, width: 100, border: '3px dashed  #ccc', padding: 6, justifyItems: 'center', margin: 6 }} >
                      <img src={ImageUrl + img} alt="Your Image" onClick={() => { window.open(ImageUrl + img, '_blank') }} />
                      {/* <div style={{ position: 'absolute', top: -10, right: -10, cursor: 'pointer' }}>
                        <IoMdCloseCircle size={24} color="red" onClick={() => handleDeleteImage(i)} />
                      </div> */}
                    </div>

                  )
                })
              }
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModalOpen(!modalOpen)}>
              Close
            </Button>
            {/* <Button color="primary" onClick={() => setModalOpen(!modalOpen)}>
              Save Changes
            </Button> */}
          </ModalFooter>
        </Modal >
      }

      {
        authCheckModal &&
        <AuthModal modalOpen={authCheckModal} modelClose={authCheckToggle} />
      }


    </>

  )
}

export default UserUploadImageRequest