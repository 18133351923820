import React, { useState } from 'react'
import { Row, Col, Label, Form } from "reactstrap";
import { Button } from "../../../components/Component";
import Content from "../../../layout/content/Content";
import classNames from "classnames";
import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
} from "../../../components/Component";
import ServiceStore from '../../../utils/ServiceStore';
import { useForm } from 'react-hook-form';
import AuthModal from '../../auth/AuthModal';

const Notifications = () => {
  const [title, setTitle] = useState('');
  const { errors, register, handleSubmit } = useForm();
  const token = localStorage.getItem("token");

  const [authCheckModal, setAuthCheckModal] = useState(false);

  const authCheckToggle = () => setAuthCheckModal(!authCheckModal);

  const formClass = classNames({
    "form-validate": true
  });

  const onFormSubmit = () => {
    const method = '/announcement/add'
    const body = {
      "title": title,
      "detail": "hello test announcement02"
    }
    console.log("data", body)
    new ServiceStore().InsertData(method, body, token).then((res) => {
      console.log("res", res)
      alert("Notification added successfully")
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })
  }

  return (
    <React.Fragment>
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BlockTitle tag="h2" className="fw-normal">
              Add Notification
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <BlockHead>
            <BlockHeadContent></BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <React.Fragment>
              <Form
                className={formClass} onSubmit={handleSubmit(onFormSubmit)}
              >
                <Row className="g-gs">
                  {/* <Col lg="7">
                    <div className="form-group">
                      <ul className="custom-control-group g-3 align-center flex-wrap">
                        <li>
                          <div className="custom-control custom-radio">
                            <input
                            //   ref={register({ required: true })}
                              type="radio"
                              className="custom-control-input"
                              defaultChecked
                              name="language"
                              id="reg-enable"
                              value="english"
                            />
                            <label className="custom-control-label" htmlFor="reg-enable">
                              English
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-radio">
                            <input
                            //   ref={register({ required: true })}
                              type="radio"
                              className="custom-control-input"
                              name="language"
                              id="reg-disable"
                              value="marathi"
                            />
                            <label className="custom-control-label" htmlFor="reg-disable">
                              Marathi
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col> */}

                  <Col lg="7">
                    <div className="row">
                      <div className="form-group">
                        <label>Enter Notification</label>
                        <input
                          className="form-control"
                          type="text"
                          name="title"
                          id="title"
                          placeholder=""
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <Button className="btn btn-success btn-lg">Save</Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </PreviewCard>
        </Block>
      </Content>

      {
        authCheckModal &&
        <AuthModal modalOpen={authCheckModal} modelClose={authCheckToggle} />
      }

    </React.Fragment>
  )
}

export default Notifications