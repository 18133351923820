/** @format */
import { makeObservable, action, observable } from "mobx";
import axios from "./axiosInterceptor";

export default class ServiceStore {
  planList = null;

  constructor() {
    makeObservable(this, {
      UploadImage: action,
      GetAllData: observable,
      InsertData: action,
      UpdateData: action,
      GetDataByID: action,
      DeleteData: action,
      Login: action,
      GetDataBYFilter: action,
      UploadFile: action,
      UploadFileOHIFServer: action,
    });
  }

  Login = (method, data) => {
    return axios
      .post(method, data)
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        if (error.response.data.status == 0) {
          const response = { data: error.response.data, status: error.response.data.status };
          return { response };
        }
        // return Promise.reject(error);
      });
  };
  
  GetAllData = (method) => {
    return axios
      .get(method)
      .then((response) => {

        // console.log("response------>",response)
        let data = [];
        let error = "success";
        if (response.status == 1) {
          error = "success";
          data = response.data;
        } else {
          error = "error";
        }
        return { data, error };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  InsertData = (method, data,token) => {
    return axios
      .post(method, data,{
        headers:{
          Authorization:`Bearer ${token}`,
        } 
      })
      .then((response) => {
        return { response };
        // let data = response;
        // let error = "success";
        // if (response.responseCode === 200) {
        // 	this.adminPropertyList = response.responseData;
        // } else {
        // 	error = "error";
        // }
        // return { data, error };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  UpdateData = (method, data,token) => {
    return axios
      .put(method, data,{
        headers:{
          Authorization:`Bearer ${token}`,
        } 
      })
      .then((response) => {


        console.log("response============>",response)
        return { response };
        // let data = response;
        // let error = "success";
        // if (response.responseCode === 200) {
        // 	this.adminPropertyList = response.responseData;
        // } else {
        // 	error = "error";
        // }
        // return { data, error };
      })
      .catch((error) => {
        console.log("error============>",error)
        return Promise.reject(error);
      });
  };

  GetDataByID = (method, id) => {
    return axios
      .get(method + id)
      .then((response) => {
        let data = [];
        let msg = "success";
        if (response.status == 1) {
          msg = "success";
          data = response.data;
        } else {
          msg = "error";
        }
        return { data, msg };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  UploadImage = (method, data) => {
    return axios
      .post(method, data)
      .then((response) => {
        let data = response;
        let error = "success";
        // if (response.responseCode === 200) {
        // 	this.adminPropertyList = response.responseData;
        // } else {
        // 	error = "error";
        // }
        return { data, error };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  DeleteData = (method, id,token) => {
    return axios
      .delete(method + id,{
        headers:{
          Authorization:`Bearer ${token}`,
        } 
      })
      .then((response) => {
        let data = [];
        let msg = "success";
        if (response.status == 1) {
          msg = "success";
          data = response.data;
        } else {
          msg = "error";
        }
        return { data, msg };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  GetDataBYFilter = (method, data,token) => {
    return axios
      .post(method, data,{
        headers:{
          Authorization:`Bearer ${token}`,
        } 
      })
      .then((response) => {
        return { response };
        // let data = response;
        // let error = "success";
        // if (response.responseCode === 200) {
        // 	this.adminPropertyList = response.responseData;
        // } else {
        // 	error = "error";
        // }
        // return { data, error };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  UploadFile = (method, data,token) => {
    return axios
      .post(method,data, {
        headers:{
          Authorization:`Bearer ${token}`
        }
      })
      .then((response) => {
        let data = response;
        let error = "success";
        // if (response.responseCode === 200) {
        // 	this.adminPropertyList = response.responseData;
        // } else {
        // 	error = "error";
        // }
        return { data, error };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
  
  UploadFileOHIFServer = (method, data) => {
    return axios
      .post(method, data)
      .then((response) => {
        let data = response;
        let error = "success";
        // if (response.responseCode === 200) {
        // 	this.adminPropertyList = response.responseData;
        // } else {
        // 	error = "error";
        // }
        return { data, error };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}
