import React, { useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";
import imgeurl from "../../../utils/constant";
import MultiImageInput from "react-multiple-image-input";

import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";
import { Data } from "@react-google-maps/api";
import PhotoViewer from "../../../components/GallaryPhotos/PhotoViewer";
import { removeFromArray } from "../../../utils/commonFunctions";
import AddPhoto from "../../../components/GallaryPhotos/AddPhoto";

const Gallary = () => {
  const { errors, register, handleSubmit } = useForm();
  const [id] = React.useState("form-1");
  const [file, setFile] = React.useState("");
  const [file4, setFile4] = React.useState("");
  const [file5, setFile5] = React.useState("");
  const [file6, setFile6] = React.useState("");
  const [isState, setIsState] = React.useState(false);
  const [isRefresh, setIsRefresh] = React.useState(true);

  const [detailsList, setDetailsList] = React.useState({});

  const history = useHistory();

  const onFormSubmit = (e) => {
    const dataid = history.location.state?.detail._id;
    const method = "gallary/";
    const data = e;
    const token = localStorage.getItem("token");
    if (dataid) {
      data.id = dataid;
      if (file) {
        const formData = new FormData();
        formData.append("file", file, file.name);
        new ServiceStore().UploadFile("gallary/ImageUpload", formData, token).then((res) => {
          data.line3 = res.data.data.imagename;
          new ServiceStore().UpdateData("gallary/updatedata", data, token).then((res) => {});
        });
      } else {
        data.line3 = detailsList.line3;
      }
      if (file4) {
        const formData4 = new FormData();
        for (const single_file of file4) {
          formData4.append("file", single_file);
        }
        new ServiceStore().UploadFile("gallary/ImageUpload", formData4, token).then((res) => {
          data.line4 = res.data.data;
          new ServiceStore().UpdateData("gallary/updatedata", data, token).then((res) => {});
        });
      } else {
        data.line4 = detailsList.line4;
      }
      if (file5) {
        const formData5 = new FormData();
        for (const single_file of file5) {
          formData5.append("file", single_file);
        }
        new ServiceStore().UploadFile("gallary/ImageUpload", formData5).then((res) => {
          data.line5 = res.data.data;
          new ServiceStore().UpdateData("gallary/updatedata", data).then((res) => {});
        });
      } else {
        data.line5 = detailsList.line5;
      }
      if (file6) {
        const formData6 = new FormData();
        for (const single_file of file6) {
          formData6.append("file", single_file);
        }
        new ServiceStore().UploadFile("gallary/ImageUpload", formData6).then((res) => {
          data.line6 = res.data.data;
          new ServiceStore().UpdateData("gallary/updatedata", data).then((res) => {});
        });
      } else {
        data.line6 = detailsList.line6;
      }
      new ServiceStore().UpdateData("gallary/updatedata", data).then((res) => {
        history.push("/gallarylist");
      });
    } else {
      const formData = new FormData();
      formData.append("file", file, file.name);

      const formData4 = new FormData();
      // formData4.append("file", file4, file4.name);
      for (const single_file of file4) {
        formData4.append("file", single_file);
      }

      const formData5 = new FormData();
      for (const single_file of file5) {
        formData5.append("file", single_file);
      }
      // formData5.append("file", file5, file5.name);

      const formData6 = new FormData();
      for (const single_file of file6) {
        formData6.append("file", single_file);
      }
      // formData6.append("file", file6, file6.name);
      new ServiceStore().UploadFile("gallary/ImageUpload", formData6).then((res) => {
        data.line6 = res.data.data;

        new ServiceStore().UploadFile("gallary/ImageUpload", formData4).then((res) => {
          data.line4 = res.data.data;

          new ServiceStore().UploadFile("gallary/ImageUpload", formData5).then((res) => {
            data.line5 = res.data.data;

            new ServiceStore().UploadFile("gallary/ImageUpload", formData).then((res) => {
              data.line3 = res.data.data.imagename;

              new ServiceStore().InsertData(method, data).then((res) => {
                history.push("/gallarylist");
              });
            });
          });
        });
      });
    }
  };

  const formClass = classNames({
    "form-validate": true,
  });
  const onFileSelect3 = (event) => {
    const file = event.target.files[0];
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      setFile(file);
    } else {
      this.imgeerror = "Please Select Max 100MB Size File";
    }
  };

  const onFileSelect4 = (event) => {
    const file = event.target.files;
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      setFile4(file);
    } else {
      this.imgeerror = "Please Select Max 100MB Size File";
    }
  };

  const onFileSelect5 = (event) => {
    const file = event.target.files;
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      setFile5(file);
    } else {
      this.imgeerror = "Please Select Max 100MB Size File";
    }
  };

  const onFileSelect6 = (event) => {
    const file = event.target.files;
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      setFile6(file);
    } else {
      this.imgeerror = "Please Select Max 100MB Size File";
    }
  };

  const handleRefresh = () => {
    console.log("handleRefresh is called");
    setIsRefresh(true);
  };

  const fetchData = async () => {
    const dataid = history.location.state?.detail._id;
    if (dataid) {
      new ServiceStore().GetDataByID("gallary/", dataid).then((res) => {
        setDetailsList(res?.data);
        setIsRefresh(false);
      });
    }
  };

  useEffect(() => {
    if (isRefresh) {
      fetchData();
    }
  }, [detailsList]);

  return (
    <React.Fragment>
      {/* <Head title="Form Validation"></Head> */}
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BlockTitle tag="h2" className="fw-normal">
              Gallary
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <BlockHead>
            <BlockHeadContent></BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <React.Fragment>
              <Form className={formClass} onSubmit={handleSubmit(onFormSubmit)}>
                <Row className="g-gs">
                  {!history.location.state?.detail._id && (
                    <Col lg="7">
                      <div className="form-group">
                        <ul className="custom-control-group g-3 align-center flex-wrap">
                          <li>
                            <div className="custom-control custom-radio">
                              <input
                                ref={register({ required: true })}
                                type="radio"
                                className="custom-control-input"
                                defaultChecked
                                name="language"
                                id="reg-enable"
                                value="english"
                              />
                              <label className="custom-control-label" htmlFor="reg-enable">
                                English
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-radio">
                              <input
                                ref={register({ required: true })}
                                type="radio"
                                className="custom-control-input"
                                name="language"
                                id="reg-disable"
                                value="marathi"
                              />
                              <label className="custom-control-label" htmlFor="reg-disable">
                                Marathi
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  )}
                  <div className="banner-tab2">
                    <h3>Banner</h3>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Line 1
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            defaultValue={detailsList?.line1}
                            type="text"
                            id="fv-full-name"
                            name="line1"
                            className="form-control"
                          />
                          {errors.line1 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Line 2
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-full-name"
                            name="line2"
                            className="form-control"
                            defaultValue={detailsList?.line2}
                          />
                          {errors.line2 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 3
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: false })}
                            type="file"
                            id="fv-subject"
                            name="line3"
                            className="form-control"
                            defaultValue={detailsList?.line3}
                            onChange={onFileSelect3}
                            accept="image/*"
                          />

                          {file ? (
                            <img
                              src={URL.createObjectURL(file)}
                              alt="Preview"
                              style={{ width: "100px", height: "100px", marginTop: "10px" }}
                            />
                          ) : (
                            <img src={imgeurl + detailsList?.line3} style={{ width: "100px", height: "100px" }} />
                          )}

                          {errors.line3 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                  </div>

                  <div className="banner-tab2">
                    <h3>Upload Images</h3>
                    {/* <Col md="6"> */}
                  </div>
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-subject">
                      Winners of Competition
                    </Label>
                    <div className="row">
                      {detailsList?.line4 &&
                        detailsList?.line4?.length > 0 &&
                        detailsList?.line4?.map((item, index) => (
                          <div className="col-md-4" key={index}>
                            <PhotoViewer
                              item={item}
                              key={index}
                              index={index}
                              arrayName="line4"
                              state={detailsList}
                              setState={setDetailsList}
                            />
                          </div>
                        ))}
                    </div>
                    <Row>
                      <div>
                        <AddPhoto
                          state={detailsList}
                          handleRefresh={handleRefresh}
                          setState={setDetailsList}
                          errors={errors.line4}
                          innerRef={register({ required: false })}
                          arrayName={"line4"}
                          language={history.location.state.detail.language}
                        />
                      </div>
                    </Row>
                    {/* <Col md="6"> */}
                    <br />
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-subject">
                        Vintage Pune
                      </Label>
                      <div className="row">
                        {detailsList?.line5 &&
                          detailsList?.line5?.length > 0 &&
                          detailsList?.line5?.map((item, index) => (
                            <div className="col-md-4" key={index + 1}>
                              <PhotoViewer
                                item={item}
                                key={index}
                                index={index}
                                arrayName="line5"
                                state={detailsList}
                                setState={setDetailsList}
                              />
                            </div>
                          ))}
                      </div>
                      <Row>
                        <div>
                          <AddPhoto
                            state={detailsList}
                            handleRefresh={handleRefresh}
                            setState={setDetailsList}
                            errors={errors.line5}
                            innerRef={register({ required: false })}
                            arrayName={"line5"}
                          />
                        </div>
                      </Row>
                    </div>
                    <br />

                    {/* </Col> */}
                    {/* <Col md="6"> */}

                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-subject">
                        Photos by PMC
                      </Label>
                      <div className="row">
                        {detailsList?.line6 &&
                          detailsList?.line6?.length > 0 &&
                          detailsList?.line6?.map((item, index) => (
                            <div className="col-md-4">
                              <PhotoViewer
                                item={item}
                                key={index}
                                index={index}
                                arrayName="line6"
                                state={detailsList}
                                setState={setDetailsList}
                              />
                            </div>
                          ))}
                      </div>
                      <Row>
                        <div>
                          <AddPhoto
                            state={detailsList}
                            handleRefresh={handleRefresh}
                            setState={setDetailsList}
                            errors={errors.line6}
                            innerRef={register({ required: false })}
                            arrayName={"line6"}
                          />
                        </div>
                      </Row>
                    </div>
                    <br />
                    {/* </Col> */}
                  </div>
                  <Col md="12">
                    <div className="form-group">
                      <Button color="primary" size="lg">
                        Save Information
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Gallary;
