import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";
import Icon from "../../../components/icon/Icon"
import { loginData, orderData, transactionData } from "../../../components/table/TableData";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge, ModalBody, Modal } from "reactstrap";

import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  BlockBetween,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const AdminUserList = () => {
  const { errors, register, handleSubmit } = useForm();

  const [id] = React.useState("form-1");
  const [file, setFile] = React.useState('')
  const [file20, setFile20] = React.useState('')
  const [file24, setFile24] = React.useState('')
  const [datalist, setdatalist] = React.useState([])
  const [deletedata, setdeletedata] = React.useState("");
  const [refresh, setRefresh] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState()


  const history = useHistory();
  const home = () => {
    history.push("/adminuser");
  }


  const Deletedata = () => {
    const methods = "adminuser/";
    new ServiceStore().DeleteData(methods, deleteId).then((res) => {
      setdeletedata("true");
      setModal({ deleteUser: false })
      setRefresh(!refresh)
    });
  };

  const Editdata = (index, e) => {
    console.log("e", index)
    // history.push("/demo1/home");
    history.push({
      pathname: '/adminuser',
      search: '?id=' + index._id,
      state: { detail: index },
    })
  }
  const [modal, setModal] = useState({
    deleteUser: false,
  });
  const onFormCancel = () => {
    setModal({ deleteUser: false });
  };
  React.useEffect(() => {

    getAlldataFile()


  }, [deletedata,refresh]);

  const getAlldataFile = () => {
    const methods = "adminuser/"
    new ServiceStore().GetAllData(methods).then((res) => {
      console.log("res.response", res)
      setdatalist(res.data)
    });
  }

  const DropdownTrans = () => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu end>
          <ul className="link-list-plain">
            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                View
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Invoice
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Print
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <React.Fragment>
      {/* <Head title="Form Validation">
   
      </Head> */}
      <Content page="component">

        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent><h3>Admin List</h3></BlockHeadContent>
            <BlockHeadContent> <Button color="primary" size="lg" onClick={home}>
              Create
            </Button></BlockHeadContent>
          </BlockBetween>
        </BlockHead>

      </Content>
      {/* <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
            
            </BlockHeadContent>
          </BlockHead> */}
      <PreviewCard className="card-table">

        <table className="table table-orders">
          <thead className="tb-odr-head">
            <tr className="tb-odr-item">
              <th className="tb-odr-info">
                <span className="tb-odr-id">Email</span>

              </th>
              <th className="tb-odr-action">&nbsp;
                <span className="tb-odr-id">Action</span>
              </th>
            </tr>
          </thead>
          <tbody className="tb-odr-body">
            {datalist.length > 0 ? datalist.map((item) => {
              return (
                <tr className="tb-odr-item" key={item._id}>
                  <td className="tb-odr-info">
                    <span className="tb-odr-id">
                      <a
                        href="#id"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        {item.email}
                      </a>
                    </span>

                  </td>



                  <td className="tb-odr-action">
                    <div className="tb-odr-id">
                      {/* <Button color="primary" className="btn-sm" onClick={e => Editdata(item, e) }>
                    Edit
                  </Button> */}
                      <Icon
                        name="edit-alt-fill"
                        style={{ cursor: "pointer" }}
                        onClick={e => Editdata(item, e)}
                      ></Icon> &nbsp;&nbsp;&nbsp;
                      <Icon
                        name="trash-empty-fill"
                        style={{ cursor: "pointer" }}
                        onClick={() => {setModal({ deleteUser: true });setDeleteId(item._id)}}
                      ></Icon>
                    </div>
                  </td>
                </tr>
              );
            }) : (
              <tr>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  <h3>No Record Found</h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <Modal isOpen={modal.deleteUser} toggle={() => setModal({ deleteUser: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5>Are you sure ?</h5>
              <div className="mt-4">

                <button style={{ marginTop: "23px" }} type="button" className="btn btn-danger" onClick={()=>Deletedata()}>
                  Confirm
                </button>
                <button style={{ marginTop: "23px", marginLeft: '1rem' }} type="button" className="btn btn-primary" onClick={onFormCancel}>
                  Cancel
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>

      </PreviewCard>
      {/* </Block> */}


      {/* </Content> */}
    </React.Fragment>
  );
};
export default AdminUserList;
