// Change this when you are using locally
const imgeurl = "https://api.puneri.foxberry.link/public/uploads/image/";
// const imgeurl = "https://apipune.techgigs.in/public/uploads/image/";
// const imgeurl = "http://localhost:3001/uploads/image/";
// const pdfurl = "http://localhost:3001/uploads/pdf/";

export default imgeurl;
export const RequestListLanguageFilters = [
  { key: 1, label: "All", value: "" },
  { key: 2, label: "English", value: "english" },
  { key: 3, label: "Marathi", value: "marathi" },
];
export const RequestListSearchKeys = ["title", "username"];
// export const CommunityTopicRequestListLanguageFilters = [
//   { key: 1, label: "All", value: "" },
//   { key: 2, label: "English", value: "english" },
//   { key: 3, label: "Marathi", value: "marathi" },
// ];
