import React from 'react'
import { BlockBetween, BlockHead, BlockHeadContent, PreviewCard, RSelect } from "../../../components/Component";
import { Content } from "antd/es/layout/layout";
import moment from 'moment'
import ServiceStore from '../../../utils/ServiceStore';

const AuditTrailAdmin = () => {
    const [datalist, setdatalist] = React.useState([])
    const [refresh, setRefresh] = React.useState(false);


    React.useEffect(() => {
      console.log("Called ")
   
        getAlldataFile()
              
        
      }, [refresh]);

    const getAlldataFile = () => {
        const methods = "/adminuser/getaudittrails?type=admin"
            new ServiceStore().GetAllData(methods).then((res) => {
                console.log("res.response",res.data)
                setdatalist(res.data)
            }) .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

  return (
    <React.Fragment>
    <Content page="component" style={{ marginTop: '5rem' }}>
        <BlockHead size="sm">
            <BlockBetween >
                <BlockHeadContent><h3 style={{ padding: '2rem' }}>Admin Audit Trail</h3></BlockHeadContent>
                {/* <BlockHeadContent> <Button color="primary" size="lg" style={{ marginRight: '3rem' }}
            onClick={() => {
                setModal({ add: true });
              }}
                >
                    Create
                </Button></BlockHeadContent> */}
            </BlockBetween>
        </BlockHead>

        <PreviewCard className="card-table">
        <table className="table table-orders">
        <thead className="tb-odr-head">
            <tr className="tb-odr-item">
              
              <th className="tb-odr-action">&nbsp;
                <span className="tb-odr-id">Email</span>
              </th>
              <th className="tb-odr-action">&nbsp;
                <span className="tb-odr-id">Message</span>
              </th>
              <th className="tb-odr-action">&nbsp;
                <span className="tb-odr-id">TimeStamp</span>
              </th>
            </tr>
          </thead>
            <tbody className="tb-odr-body">
                 {datalist.length > 0 ? datalist.map((item) => { 
                 return (  
                <tr className="tb-odr-item"
                  key={item._id}
                >
                    
                    <td className="tb-odr-amount">
                        <span className="tb-odr-id">
                            {item.meta.email}
                        </span>
                    </td>
                    <td className="tb-odr-info">
                        <span className="tb-odr-id">
                            {item.message}
                        </span>
                    </td>
                    <td className="tb-odr-info">
                        <span className="tb-odr-id">
                            {moment(item.timestamp).format('MMMM Do YYYY, h:mm:ss a')}
                        </span>
                    </td>
                    <td className="tb-odr-info">
        <div className="tb-odr-id">
        <span className="tb-odr-id">
          {/* <Icon
           onClick={() => {
            setModal({ edit: true });
            setEditData(item);
          }}
                    name="edit-alt-fill"
                    style={{ cursor: "pointer" }}
                    // onClick={setModal({ edit: true })}
                  ></Icon> &nbsp;&nbsp;&nbsp; */}
                   {/* <Icon
                    name="trash-empty-fill"
                    style={{ cursor: "pointer" }}
                    // onClick={e => Deletedata(e, item) }
                  ></Icon> */}
                   </span>
        </div>
      </td>
                </tr>
                  );   
                   })   
                : (   
                <tr>
                    <td colSpan={10} style={{ textAlign: "center" }}>
                        <h3>No Record Found</h3>
                    </td>
                </tr>
                )  
                  } 
            </tbody>
        </table>
    </PreviewCard>
    </Content>
</React.Fragment>
  )
}

export default AuditTrailAdmin