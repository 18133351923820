import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";

import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col,  Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";

const BlogCategory = () => {
    const { errors, register, handleSubmit } = useForm();
    const [id] = React.useState("form-1");
    const [file, setFile] = React.useState('')
    const [file20, setFile20] = React.useState('')
    const [file24, setFile24] = React.useState('')
    const [detailsList, setDetailsList] = React.useState({})
    const [title, setTitle] = React.useState();
    const history = useHistory();

    
    const onFormSubmit = (e) => {
      // e.preventDefault();
      const dataid = history.location.state?.detail._id
      const method  = 'blogcategory/'
      const data  = e
console.log(data)
     
      if(dataid){
          data.id = dataid
         
        console.log("data",data)
          new ServiceStore().UpdateData('blogcategory/updatedata',data).then((res) => {
            console.log("res",res)
            alert ("Data Updated Successfully")
            history.push("/blogcategorylist");
          });
         

      }else{
             new ServiceStore().InsertData(method,data).then((res) => {
               console.log("res",res)
               history.push("/blogcategorylist");
             });
      
      }

        
    };
    const formClass = classNames({
      "form-validate": true
    
    });
    React.useEffect(() => {
      const dataid = history.location.state?.detail._id
    // console.log("data",dataid)
    if(dataid){
      new ServiceStore().GetDataByID('blogcategory/',dataid).then((res) => {
        // console.log("res",res.data)
        setDetailsList(res.data)

      })
    }
  },[])
    
const handleTitle=(e) => {
  setTitle(e.target.value)
}
  return (
    <React.Fragment>
      {/* <Head title="Form Validation"></Head> */}
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BlockTitle tag="h2" className="fw-normal">
              Home
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
            
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
          <React.Fragment>
      <Form className={formClass} onSubmit={handleSubmit(onFormSubmit)}>
        <Row className="g-gs">
          {!history.location.state?.detail._id && 
        <Col lg="7">
                  <div className="form-group">
                    <ul className="custom-control-group g-3 align-center flex-wrap">
                      <li>
                        <div className="custom-control custom-radio">
                          <input
                          ref={register({ required: true })}
                            type="radio"
                            className="custom-control-input"
                            defaultChecked 
                            name="language"
                            id="reg-enable"
                            value="english"
                          />
                          <label className="custom-control-label" htmlFor="reg-enable">
                            English
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio">
                          <input
                          ref={register({ required: true })}
                            type="radio"
                            className="custom-control-input"
                            name="language"
                            id="reg-disable"
                            value="marathi"
                            
                          />
                          <label className="custom-control-label" htmlFor="reg-disable">
                            Marathi
                          </label>
                        </div>
                      </li>
                     
                    </ul>
                  </div>
                </Col>
                }
          <Col md="6">
            <div className="form-group">
              <Label className="form-label" htmlFor="fv-full-name">
               Topic
              </Label>
              <div className="form-control-wrap">
                <input
                   ref={register({ required: true })}
                  defaultValue={detailsList?.category} 
                  type="text"
                  id="fv-full-name"
                  name="category"
                  className="form-control"onChange={handleTitle}
                />
                {errors.category && <span className="invalid">This field is required</span>}
              </div>
            </div>
          </Col>
      
        
          <Col md="12">
            <div className="form-group">
              <Button color="primary" size="lg">
                Save Information
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
          </PreviewCard>
        </Block>

        
      </Content>
    </React.Fragment>
  );
};
export default BlogCategory;
