import React, { useEffect, useState } from 'react'
import ServiceStore from '../../../utils/ServiceStore'
import Content from '../../../layout/content/Content'
import { PreviewCard } from '../../../components/Component'

const SpacebookingList = () => {

    const [spacebookingData, setSpacebookingData] = useState()

  useEffect(() =>{
    getSpacebookingData()
  },[])

  const getSpacebookingData = () =>{
    const methods = "donations/getDonations";
    new ServiceStore().InsertData(methods,{paymentType: "spacebooking"})
    .then((response) =>{
        setSpacebookingData(response?.response?.data)
    })
    .catch((error) =>{
      console.log("error", error);
    })
  }

  return (
   <>
    <Content page="component">
      <h3>Spacebooking Data</h3>
    </Content>

    <PreviewCard className="card-table">
      <table className="table table-orders">
        <thead className="tb-odr-head">
          <tr className="tb-odr-item">
            <th className="tb-odr-info">
              <span className="tb-odr-id">No.</span>
            </th>

            <th className="tb-odr-info">
              <span className="tb-odr-id">Name</span>
            </th>

            <th className="tb-odr-amount">
              <span className="tb-odr-id">Mobile</span>
            </th>

            <th className="tb-odr-amount">
              <span className="tb-odr-id">Email</span>
            </th>

            <th className="tb-odr-amount">
              <span className="tb-odr-id">Amount</span>
            </th>

            <th className="tb-odr-action">&nbsp;
              <span className="tb-odr-id">Payment Status</span>
            </th>
        </tr>
      </thead>
      <tbody className="tb-odr-body">
        {spacebookingData?.length > 0 ? spacebookingData?.map((item, index) => {
          return (
            <tr className="tb-odr-item" key={item._id}>
              <td className="tb-odr-info">
                <span className="tb-odr-id">
                  {index + 1}
                </span>
              </td>

              <td className="tb-odr-amount">
                <span className="tb-odr-id">
                  <span className="amount">{item?.name}</span>
                </span>     
              </td>

              <td className="tb-odr-amount">
                <span className="tb-odr-id">{item?.mobile}</span>
              </td>

              <td className="tb-odr-action">
                <span className="tb-odr-id">{item?.email}</span>
              </td>

              <td className="tb-odr-action">
                <span className="tb-odr-id">{item?.amount}</span>
              </td>

              <td className="tb-odr-action">
              {item?.paymentStatus === "pending" ? <span className="tb-odr-id" style={{color:"#FFDB58"}}>Pending</span> :
               item?.paymentStatus === "success" ? <span className="tb-odr-id" style={{color:"green"}}>Success</span> :
               item?.paymentStatus === "failure" ? <span className="tb-odr-id" style={{color:"red"}}>Fail</span> : ""}
              </td>
              
              
            </tr>
          );
        }): (
          <tr>
            <td colSpan={10} style={{ textAlign: "center" }}>
              <h3>No Record Found</h3>
            </td>
          </tr>
        )}
      </tbody>
    </table>
    
    </PreviewCard>   
   </>
  )
}

export default SpacebookingList
