// Import necessary dependencies
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// AuthModal is a functional component
const AuthModal = ({ modalOpen, modelClose }) => {
    const history = useHistory()

    const handleSignout = () => {
        localStorage.removeItem("accessToken");
        sessionStorage.removeItem("user");
        history.push("/auth-login")
    };

    return (
        <div>
            {/* Modal component */}
            <Modal isOpen={modalOpen} toggle={modelClose}>
                <ModalHeader toggle={modelClose}>Login Error</ModalHeader>
                <ModalBody>
                    {/* Modal content goes here */}
                    <h5>Your session has expired. Please log in again to continue.</h5>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSignout}>
                        Ok
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

// Export the component
export default AuthModal;
