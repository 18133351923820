import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";
import Icon from "../../../components/icon/Icon"
import { loginData, orderData, transactionData } from "../../../components/table/TableData";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";

import {
    Block,
    BlockDes,
    PreviewCard,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    BackTo,
    BlockBetween,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";

const ReportAbuse = () => {
    const { errors, register, handleSubmit } = useForm();

    const [id] = React.useState("form-1");
    const [file, setFile] = React.useState('')
    const [file20, setFile20] = React.useState('')
    const [file24, setFile24] = React.useState('')
    const [datalist, setdatalist] = React.useState([])
    const [deletedata, setdeletedata] = React.useState("");

    const history = useHistory();
    const home = () => {
        history.push("/blogs");
    }

    const Deletedata = (e, index) => {
        const methods = "commentwriteblogtopic/";
        new ServiceStore().DeleteData(methods, e._id.commentId).then((res) => {
            getAlldataFile()
        });

        const data = { status: "Remove", id: e.id }
        new ServiceStore().UpdateData("reportabusecomment/updatedata", data).then((res) => {
            getAlldataFile()
        });
    };
    const Editdata = (index, e) => {
        const methods = "reportabusecomment/updatedata"
        const data = { status: "Ignore", id: index.id }
        new ServiceStore().UpdateData(methods, data).then((res) => {
            getAlldataFile()
        });
        // history.push("/demo1/home");
        // history.push({
        //     pathname: '/blogs',
        //     search: '?id='+index._id,
        //     state: { detail: index },
        //   })
    }
    React.useEffect(() => {

        getAlldataFile()


    }, []);

    const getAlldataFile = () => {
        const methods = "reportabusecomment"
        new ServiceStore().GetAllData(methods).then((res) => {
            setdatalist(res.data)
        });
    }

    const DropdownTrans = () => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu end>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                }}
                            >
                                View
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                }}
                            >
                                Invoice
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                }}
                            >
                                Print
                            </DropdownItem>
                        </li>
                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    return (
        <React.Fragment>
            {/* <Head title="Form Validation">
   
      </Head> */}
            <Content page="component">

                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent><h3>Report Abuse List</h3></BlockHeadContent>
                        <BlockHeadContent>
                            {/* <Button color="primary" size="lg" onClick={home}>
              Create
              </Button> */}
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                {/* <div className="form-group" style={{"float":"right"}}>
              <Button color="primary" size="lg" onClick={home}>
              Create
              </Button>
            </div> */}
            </Content>
            {/* <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
            
            </BlockHeadContent>
          </BlockHead> */}
            <PreviewCard className='card-table'>

                <table className="table table-orders">
                    <thead className="tb-odr-head">
                        <tr className="tb-odr-item">
                            <th className="tb-odr-info">
                                <span className="tb-odr-id">Language</span>
                            </th>
                            <th className="tb-odr-amount">
                                <span className="tb-odr-id">Blog</span>
                            </th>
                            <th className="tb-odr-amount">
                                <span className="tb-odr-id">Commnet</span>
                            </th>
                            <th className="tb-odr-amount">
                                <span className="tb-odr-id">Count</span>
                            </th>

                            <th className="tb-odr-amount">
                                <span className="tb-odr-id">Status</span>
                            </th>
                            <th className="tb-odr-action">
                                <span className="tb-odr-id">Ignore</span>
                            </th>
                            <th className="tb-odr-action">
                                <span className="tb-odr-id">Delete</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="tb-odr-body">
                        {datalist.length > 0 ? datalist.map((item) => {
                            return (
                                <tr className="tb-odr-item" key={item._id}>
                                    <td className="tb-odr-info">
                                        <span className="tb-odr-id">
                                            <a
                                                href="#id"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                }}
                                            >
                                                {item._id.language === 'english' ? 'English' : 'मराठी'}
                                            </a>
                                        </span>

                                    </td>
                                    <td className="tb-odr-amount">
                                        <span className="tb-odr-id">{item._id.blogname}</span>
                                    </td>
                                    <td className="tb-odr-amount">
                                        <span className="tb-odr-id">{item._id.comment}</span>
                                    </td>
                                    <td className="tb-odr-amount">
                                        <span className="tb-odr-id">{item.count}</span>
                                    </td>
                                    <td className="tb-odr-amount">
                                        <span className="tb-odr-id">{item.status}</span>
                                    </td>

                                    <td className="tb-odr-action">
                                        {/* <div className="tb-odr-id"> */}
                                            <button className="btn btn-primary" onClick={e => Editdata(item, e)}>
                                                ignore
                                            </button>
                                        {/* </div> */}
                                    </td>
                                    <td className="tb-odr-action">
                                        {/* <div className="tb-odr-id"> */}
                                            <button className="btn btn-primary" onClick={e => Deletedata(item, e)}>
                                                Remove
                                            </button>
                                        {/* </div> */}
                                        {/* <Icon
                            name="trash-empty-fill"
                            style={{ cursor: "pointer" }}
                            onClick={e => Deletedata(e, item) }
                          ></Icon> */}

                                    </td>
                                </tr>
                            );
                        }) : (
                            <tr>
                                <td colSpan={10} style={{ textAlign: "center" }}>
                                    <h3>No Record Found</h3>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </PreviewCard>
            {/* </Block> */}


            {/* </Content> */}
        </React.Fragment>
    );
};
export default ReportAbuse;
