import React, { useState } from 'react'
import { BlockBetween, BlockHead, BlockHeadContent, PreviewCard, RSelect } from "../../../components/Component";
import { Content } from "antd/es/layout/layout";
import ServiceStore from '../../../utils/ServiceStore';
import moment from 'moment'


const Subscribers = () => {

    const [datalist, setdatalist] = React.useState([])
    const [refresh, setRefresh] = React.useState(false);


    React.useEffect(() => {
   
        getAlldataFile()
              
        
      }, [refresh]);

    const getAlldataFile = () => {
        const methods = "/subscribemail"
            new ServiceStore().GetAllData(methods).then((res) => {
                console.log("res.response",res.data.list)
                setdatalist(res.data.list)
            }) .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

  return (
    <React.Fragment>
            <Content page="component" style={{ marginTop: '5rem' }}>
                <BlockHead size="sm">
                    <BlockBetween >
                        <BlockHeadContent><h3 style={{ padding: '2rem' }}>Subscribers List</h3></BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <PreviewCard className="card-table">
                <table className="table table-orders">
                    <thead className="tb-odr-head">
                        <tr className="tb-odr-item">
                            <th className="tb-odr-info">
                                <span className="tb-odr-id">Subscribers Email</span>
                            </th>
                            <th className="tb-odr-info">
                                <span className="tb-odr-id">Subscribed On</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="tb-odr-body">
                         {datalist.length > 0 ? datalist.map((item) => { 
                        return ( 
                        <tr className="tb-odr-item"
                          key={item._id}
                        >
                            <td className="tb-odr-info">
                                <span className="tb-odr-id">
                                    <a
                                        href="#id"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        {item.email}
                                    </a>
                                </span>
                            </td>
                          
                            <td className="tb-odr-info">
                                <span className="tb-odr-id">
                                    <a
                                        href="#id"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        {moment(item.createdAt).format("L")}
                                    </a>
                                </span>
                            </td>
                          
                        </tr>
                         ); 
                         })  
                        : (   
                        <tr>
                            <td colSpan={10} style={{ textAlign: "center" }}>
                                <h3>No Record Found</h3>
                            </td>
                        </tr>
                        )  
                          } 
                    </tbody>
                </table>
            </PreviewCard>
            </Content>
        </React.Fragment>
  )
}

export default Subscribers