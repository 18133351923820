import React, { useEffect, useRef, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";
import Icon from "../../../components/icon/Icon";
import { loginData, orderData, transactionData } from "../../../components/table/TableData";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge, Modal, ModalBody } from "reactstrap";

import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  BlockBetween,
  RSelect,
} from "../../../components/Component";
import { Card, Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from "@mui/material";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { RequestListLanguageFilters, RequestListSearchKeys } from "../../../utils/constant";
import { debounce } from "../../../utils/commonFunctions";
import fuzzysort from "fuzzysort";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import language from "react-syntax-highlighter/dist/esm/languages/hljs/1c";
import { CKEditor } from "ckeditor4-react";
import AuthModal from "../../auth/AuthModal";

const CommunityTopicRequestList = () => {
  const initialController = {
    page: 1,
    rowsPerPage: 10,
    // rowsPerPage: 10,
    language: "",
    status: "Pending",
  };
  const [datalist, setdatalist] = React.useState([]);
  const [filteredDatalist, setFilteredDatalist] = React.useState([]);
  const [datalistCount, setdatalistCount] = React.useState(0);
  const [controller, setController] = React.useState(initialController);
  const [categoryList, setCategoryList] = React.useState();
  const [newTopicRequest, setNewTopicRequest] = React.useState();
  const [data, setData] = useState([]);
  const [title, setTitle] = useState();
  const [categoryId, setCategoryId] = useState();
  const [language, setLanguage] = useState();

  const [search, setSearch] = React.useState("");
  const [Refresh, setRefresh] = useState(false)

  const [authCheckModal, setAuthCheckModal] = useState(false);

  const authCheckToggle = () => setAuthCheckModal(!authCheckModal);


  const searchRef = useRef(null);

  const [editorData, setEditorData] = useState("");
  const [flag, setFlag] = React.useState(false);


  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const Approvedata = (index, e) => {
    const data = { status: "Approve", id: index._id };
    const token = localStorage.getItem("token");

    new ServiceStore().UpdateData("conservationtopic/updatedata", data, token).then((res) => {
      getAlldataFile();
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })
    const notification = {
      notification: "Your Forum request is Approved"
    }
    new ServiceStore().InsertData(`notification/addnotification/${index.userID}`, notification, token).then((res) => {
      getAlldataFile();
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })
  };
  const Rejectdata = (index, e) => {
    const data = { status: "Reject", id: index._id };
    const token = localStorage.getItem("token");

    new ServiceStore().UpdateData("conservationtopic/updatedata", data, token).then((res) => {
      getAlldataFile();
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })
    const notification = {
      notification: "Your Forum request is rejected"
    }
    new ServiceStore().InsertData(`notification/addnotification/${index.userID}`, notification, token).then((res) => {
      getAlldataFile();
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })
  };
  React.useEffect(() => {
    getAlldataFile();
    getAllCommunityTopicList();
  }, [controller, flag, Refresh]);

  React.useEffect(() => {
    if (search?.trim()) {
      const searchResults = fuzzysort
        .go(search, datalist?.rows, { keys: RequestListSearchKeys })
        ?.map((result) => result?.obj)
        ?.filter((e) => e);

      setFilteredDatalist([...searchResults]);
    } else {
      const defaultValues = datalist?.rows ? [...datalist?.rows] : [];
      setFilteredDatalist([...defaultValues]);
    }
  }, [search, datalist]);

  const getAlldataFile = () => {
    const methods = "conservationtopic/filterRequest";
    const token = localStorage.getItem("token");

    const bodyData = {
      page: controller.page,
      limit: controller.rowsPerPage,
      language: controller.language,
      status: controller.status,
    };
    new ServiceStore().GetDataBYFilter(methods, bodyData, token).then((res) => {
      setdatalist(res.response.data);
      setdatalistCount(res.response.data.count);
    });
  };

  const handleLanguage = (e) => {
    setLanguage(e.value);
  };

  const categoryName = (e) => {
    setCategoryId(e.value);
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const logEvent = (e) => {
    setData({ ...data, ["subtitle"]: e.editor.getData() });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    const methods = "conservationtopic";
    const body = {
      title: title,
      subtitle: data.subtitle,
      categoryId: categoryId,
      language: language,
      status: "Pending",
      userID: "123",
      username: "Municipal Corporation",
    };
    if (language !== undefined && categoryId !== undefined && title !== undefined && data.subtitle !== undefined) {
      new ServiceStore().InsertData(methods, body, token).then((res) => {
        console.log("res===166", res);
        setModal({ add: false });
        setRefresh(!Refresh)
      });
    } else {
      alert("Please Fill All Empty Fields");
    }
  };

  const getAllCommunityTopicList = (language) => {
    const method = `communitytopic/${language ? language : "english"}`;
    new ServiceStore().GetAllData(method).then((res) => {
      const filterData = [];
      res.data.filter((data) => {
        filterData.push({ label: data.category, value: data._id });
      });
      setCategoryList(filterData);
    });
  };

  function handle(e) {
    const newdata = { ...controller };
    newdata[e.target.id] = e.target.value;
    setController({ ...newdata, page: 1 });
  }

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage + 1,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1,
    });
  };

  const resetForm = () => {
    searchRef.current.value = "";
    setSearch("");
    setController({ ...initialController });
  };

  const debounceSearch = debounce((query) => setSearch(query), 350);

  const handleSearch = (e) => debounceSearch(e?.target?.value);
  const [modal, setModal] = useState({
    add: false,
  });
  const onFormCancel = () => {
    setModal({ add: false });
    setModal({ edit: false });
  };
  const filterStatus = [
    { value: "english", label: "English" },
    { value: "marathi", label: "Marathi" },
  ];

  // const deleteData = (item) => {
  //   console.log("item : ", item);
  //   const method = `conservationtopic/`;
  //   new ServiceStore().DeleteData(method,item._id).then((res) => {
  //     console.log("mydata : ",res)
  //     setFlag(!flag)
  //   });
  // };

  return (
    <React.Fragment>
      <Content page="component">
        {/* <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent><h3>Community Topic Request</h3></BlockHeadContent>
          <BlockHeadContent>
             <Button color="primary" size="lg" 
          onClick={home}
          >
              Create
              </Button>
              </BlockHeadContent>
        </BlockBetween>
        </BlockHead> */}
      </Content>
      <div>
        <form onSubmit={(e) => { }}>
          <div className="row" style={{ marginBottom: "10px", marginLeft: "16px" }}>
            <div style={{ marginBottom: "1rem" }}>
              <h3>Community Topic Request</h3>
            </div>
            <div className="col-md-3">
              <label style={{ marginLeft: "4px" }}>Language</label>
              <select
                name="language"
                onChange={(e) => handle(e)}
                id="language"
                style={{ marginLeft: "5px" }}
                className="form-control"
                value={controller?.language}
              >
                {RequestListLanguageFilters?.map((ele) => (
                  <option value={ele?.value} key={ele?.key}>
                    {ele?.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <label>Status</label>
              <select
                name="status"
                onChange={(e) => handle(e)}
                id="status"
                style={{ marginLeft: "5px" }}
                className="form-control"
                value={controller?.status}
              >
                <option value="All">All</option>
                <option value="Approve">Accepted</option>
                <option value="Pending" selected>
                  Pending
                </option>
                <option value="Reject">Rejected</option>
              </select>
            </div>
            <div className="col-md-3">
              <label>Search</label>
              <input
                className="form-control"
                placeholder="Search..."
                onChange={handleSearch}
                defaultValue={search}
                ref={searchRef}
              />
            </div>

            <div className="col-md-3">
              <button style={{ marginTop: "23px" }} type="button" className="btn btn-danger" onClick={resetForm}>
                Reset
              </button>
              <button
                style={{ marginTop: "23px", marginLeft: "1rem" }}
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setModal({ add: true });
                }}
              >
                Create
              </button>
            </div>
            <div className="col-md-3"></div>
          </div>
        </form>
      </div>
      <PreviewCard className="card-table">
        <table className="table table-orders">
          <thead className="tb-odr-head">
            <tr className="tb-odr-item">
              <th className="tb-odr-info">
                <span className="tb-odr-id">Language</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">User</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Title</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Status</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Description</span>
              </th>
              <th className="tb-odr-info" style={{ textAlign: "center" }}>
                <span className="tb-odr-id">Date & Time</span>
              </th>
              <th className="tb-odr-action" style={{ textAlign: "center" }}>
                &nbsp;
                <span className="tb-odr-id">Action</span>
              </th>
              {/* <th className="tb-odr-action">
                &nbsp;
                <span className="tb-odr-id">Action</span>
              </th> */}
            </tr>
          </thead>

          <tbody className="tb-odr-body">
            {filteredDatalist?.length > 0 ? (
              filteredDatalist?.map((item) => {
                return (
                  <tr className="tb-odr-item" key={item._id}>
                    <td className="tb-odr-info">
                      <span style={{ color: "#007bff" }} className="tb-odr-id">
                        {item.language === "english" ? "English" : "मराठी"}
                      </span>
                    </td>
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">{item.username}</span>
                    </td>
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">{item.title}</span>
                    </td>
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">{item.status}</span>
                    </td>
                    {/* <td className="tb-odr-info">
                      <span className="tb-odr-id">{item.status}</span>
                    </td> */}
                    <td className="tb-odr-info">
                      <Popup
                        contentStyle={{ width: "auto", height: "auto" }}
                        trigger={
                          <button className=" btn btn-info" style={{ padding: "5px" }}>
                            View
                          </button>
                        }
                        position="center center"
                      >
                        <div dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                      </Popup>
                    </td>
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">{new Date(item.createdAt).toLocaleString()}</span>
                    </td>
                    <td className="tb-odr-action" style={{ textAlign: "center" }}>
                      {/* <div className="tb-odr-id"> */}
                      <Row>
                        <Col xs="12" sm="12" md="6" lg="6">
                          <Button
                            color="primary"
                            className="btn-sm btn-success"
                            onClick={(e) => Approvedata(item, e)}
                            style={{ padding: "5px", marginBottom: 10 }}
                          >
                            Approve
                          </Button>
                        </Col>
                        <Col xs="12" sm="12" md="6" lg="6">
                          <Button
                            color="primary"
                            className="btn-sm btn-danger"
                            onClick={(e) => Rejectdata(item, e)}
                            style={{ padding: "5px", marginBottom: 10 }}
                          >
                            Reject
                          </Button>
                        </Col>
                      </Row>
                    </td>
                    {/* <td className="tb-odr-action">
                      <div className="tb-odr-id">
                        <Button color="primary" className="btn-sm" onClick={(e) => Rejectdata(item, e)}>
                          Reject
                        </Button>
                      </div>
                    </td> */}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  <h3>No Record Found</h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {datalist?.count !== 0 && datalist?.rows?.length > 0 ? (
          <>
            <div className="row">
              <div className="col" style={{ textAlign: "end", paddingTop: "1rem" }}>
                Current Page : {controller.page}
              </div>
              <div className="col">
                <TablePagination
                  style={{ marginTop: "10px" }}
                  component="div"
                  onPageChange={handlePageChange}
                  page={controller.page - 1}
                  count={datalistCount}
                  rowsPerPage={controller.rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </>
        ) : null}
      </PreviewCard>
      {/* </Block> */}

      {/* </Content> */}
      <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#close"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Create Topic Request</h5>
            <div className="mt-4">
              <Form className="row gy-4" autoComplete="off" onSubmit={handleSubmit}>
                <Col size="6">
                  <div className="form-group">
                    <label className="form-label">Language</label>
                    <RSelect
                      options={filterStatus}
                      placeholder="Select Language"
                      name="language"
                      onChange={handleLanguage}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Category</label>
                    <RSelect
                      options={categoryList}
                      placeholder="Select Community Topic"
                      name="categoryId"
                      onChange={categoryName}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Title</label>
                    <input className="form-control" type="text" name="title" onChange={handleTitle} />
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>

                {/* <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <input
                      className="form-control"
                      type="text"
                      name="subtitle"
                    onChange={handleTitle}
                    />
                  </div>
                </Col> */}

                <Col md="12">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <CKEditor
                      style={{ fontSize: "15px" }}
                      onSelectionChange={logEvent}
                      data={data?.subtitle}
                      required
                    />
                  </div>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button color="primary" size="md" type="submit">
                        Submit
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {
        authCheckModal &&
        <AuthModal modalOpen={authCheckModal} modelClose={authCheckToggle} />
      }

    </React.Fragment>
  );
};
export default CommunityTopicRequestList;
