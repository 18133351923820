import React, { useEffect, useState } from "react";
import imageurl from "../../utils/constant";
import { removeFromArray } from "../../utils/commonFunctions";
import { Col, Row } from "reactstrap";
// rafce

const PhotoViewer = ({ item, index, state, setState, arrayName }) => {
  // const handleRemove = (indexOfItemToBeRemoved) => {
  //   let newArray = [];

  //   newArray = removeFromArray(state[arrayName], indexOfItemToBeRemoved);

  //   const newDetailList = { ...state };
  //   newDetailList[arrayName] = newArray;
  //   setState({ ...newDetailList });
  // };

  const removeFromArray = (arr, indexToRemove) => {
    return arr.filter((item, index) => index !== indexToRemove);
  };

  const handleRemove = (indexOfItemToBeRemoved) => {
    let newArray = removeFromArray(state[arrayName], indexOfItemToBeRemoved);

    const newDetailList = { ...state };
    newDetailList[arrayName] = newArray;
    setState({ ...newDetailList });

    // Update localStorage
    localStorage.setItem('detailsLists', JSON.stringify(newDetailList));
  };

  // When the component mounts
    // useEffect(() => {
    //   const storedDetailsList = JSON.parse(localStorage.getItem('detailsLists'));
    //   if (storedDetailsList !== null && storedDetailsList !== undefined) {
    //     setState(storedDetailsList);
    //   }
    // }, [setState]);

  return (
    <div className="container" style={{ position: "relative", width: "100%", maxWidth: "400px", marginBottom: "10px" }}>
      <img
        src={imageurl + item?.originalname?.toLowerCase()}
        style={{ width: "100%", height: "auto" }}
        alt={`${arrayName} ${index}`}
      />
      <button
        className="btn btn-outline-danger"
        style={{
          position: "absolute",
          top: "10%",
          left: "87%",
          transform: "translate(-50%, -50%)",
          backgroundcolor: "#555",
          color: "white",
          fontsize: "10px",
          padding: "10px 18px",
          border: "none",
          cursor: "pointer",
          borderradius: "5px",
          textalign: "center",
          fontSize: "16px",
        }}
        onClick={(e) => {
          e.preventDefault();
          handleRemove(index);
        }}
      >
        x
      </button>

      {/* <div>
        <button className="btn btn-outline-danger"
          onClick={(e) => {
            e.preventDefault();
            handleRemove(index);
          }}
        >
          Delete
        </button>
      </div> */}
    </div>
  );
};

export default PhotoViewer;
