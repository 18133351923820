import React from "react";
import { Switch, Route, withRouter ,Redirect } from "react-router-dom";
import { RedirectAs404 } from "./utils/Utils";
import PrivateRoute from "./route/PrivateRoute";

import Layout from "./layout/Index";

import Error404Classic from "./pages/error/404-classic";
import Error404Modern from "./pages/error/404-modern";
import Error504Modern from "./pages/error/504-modern";
import Error504Classic from "./pages/error/504-classic";

import Faq from "./pages/others/Faq";
import Terms from "./pages/others/Terms";
import AdminUserList from "./pages/components/admin/adminuserlist";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Success from "./pages/auth/Success";
import InvoicePrint from "./pages/pre-built/invoice/InvoicePrint";

const App = () => {
  return (
    <Switch>
      {/* Auth Pages */}
      <Route exact path={`/auth-success`} component={Success}></Route>
      <Route exact path={`/auth-reset`} component={ForgotPassword}></Route>
      <Route exact path={`/auth-register`} component={Register}></Route>
      <Route  path={`/auth-login`} component={Login}></Route>


      <PrivateRoute  path="" component={Layout}></PrivateRoute>
    </Switch>
  );
};
export default withRouter(App);
