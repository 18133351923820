import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";
import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";
import imageurl from "../../../utils/constant";


const Blogs = () => {
  const { errors, register, handleSubmit } = useForm();
  const [id] = React.useState("form-1");
  const [file, setFile] = React.useState('')
  const [file20, setFile20] = React.useState('')
  const [file24, setFile24] = React.useState('')
  const [detailsList, setDetailsList] = React.useState({})
  const history = useHistory();


  const onFormSubmit = (e) => {
    const dataid = history.location.state?.detail._id
    const method = 'blog/'
    const data = e


    if (dataid) {
      data.id = dataid
      if (file) {
        const formData = new FormData();
        formData.append("file", file, file.name);
        new ServiceStore().UploadFile('blog/ImageUpload', formData).then((res) => {
          data.line16 = res.data.data.imagename
          new ServiceStore().UpdateData('blog/updatedata', data).then((res) => {
            history.push("/blogslist");
          });
        })
      } else {
        data.line16 = detailsList.line16
      }

      new ServiceStore().UpdateData('blog/updatedata', data).then((res) => {
        alert("data updated successfully")
        history.push("/blogslist");
      });
    } else {
      const formData = new FormData();
      formData.append("file", file.name);
      new ServiceStore().UploadFile('blog/ImageUpload', formData).then((res) => {
        data.line16 = res.data.data.imagename

        new ServiceStore().InsertData(method, data).then((res) => {
          alert("Data Updated Successfully")
          history.push("/blogslist");
        });
      })
    }
  };

  const formClass = classNames({
    "form-validate": true
  });
  const onFileSelect = event => {
    const file = event.target.files[0];
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // toBase64
      setFile(file)
    } else {
      this.imgeerror = 'Please Select Max 100MB Size File'
    }
  }

  const onFileSelect20 = event => {
    const file = event.target.files[0];
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // toBase64
      setFile20(file)
    } else {
      this.imgeerror = 'Please Select Max 100MB Size File'
    }
  }
  const onFileSelect24 = event => {
    const file = event.target.files[0];
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // toBase64
      setFile24(file)
    } else {
      this.imgeerror = 'Please Select Max 100MB Size File'
    }
  }
  React.useEffect(() => {
    const dataid = history.location.state?.detail._id
    if (dataid) {
      new ServiceStore().GetDataByID('blog/', dataid).then((res) => {
        setDetailsList(res.data)
      })
    }
  }, [])

  return (
    <React.Fragment>
      {/* <Head title="Form Validation"></Head> */}
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BlockTitle tag="h2" className="fw-normal">
              Blogs
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>

            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <React.Fragment>
              <Form className={formClass} onSubmit={handleSubmit(onFormSubmit)}>
                <Row className="g-gs">
                  {!history.location.state?.detail._id &&
                    <Col lg="7">
                      <div className="form-group">
                        <ul className="custom-control-group g-3 align-center flex-wrap">
                          <li>
                            <div className="custom-control custom-radio">
                              <input
                                ref={register({ required: true })}
                                type="radio"
                                className="custom-control-input"
                                defaultChecked
                                name="language"
                                id="reg-enable"
                                value="english"
                              />
                              <label className="custom-control-label" htmlFor="reg-enable">
                                English
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-radio">
                              <input
                                ref={register({ required: true })}
                                type="radio"
                                className="custom-control-input"
                                name="language"
                                id="reg-disable"
                                value="marathi"

                              />
                              <label className="custom-control-label" htmlFor="reg-disable">
                                Marathi
                              </label>
                            </div>
                          </li>

                        </ul>
                      </div>
                    </Col>
                  }

                  <div className="banner-tab">
                    <h3>Top Headings</h3>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Line 1
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            defaultValue={detailsList?.line1}
                            type="text"
                            id="fv-full-name"
                            name="line1"
                            className="form-control"
                          />
                          {errors.line1 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">

                        <Label className="form-label" htmlFor="fv-full-name">
                          Line 2
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-full-name"
                            name="line2"
                            className="form-control"
                            defaultValue={detailsList?.line2}
                          />
                          {errors.line2 && <span className="invalid">This field is required</span>}
                        </div>

                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 3
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line3"
                            className="form-control"
                            defaultValue={detailsList?.line3}
                          />
                          {errors.line3 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                  </div>

                  <div className="banner-tab">
                    <h3>Latest Blogs</h3>

                    <Col md="6">

                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 4
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line4"
                            className="form-control"
                            defaultValue={detailsList?.line4}
                          />
                          {errors.line4 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                  </div>

                  <div className="banner-tab">
                    <h3>Blog of The Month</h3>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 5
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line5"
                            className="form-control"
                            defaultValue={detailsList?.line5}
                          />
                          {errors.line5 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 6
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line6"
                            className="form-control"
                            defaultValue={detailsList?.line6}
                          />
                          {errors.line6 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 7
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line7"
                            className="form-control"
                            defaultValue={detailsList?.line7}
                          />
                          {errors.line7 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 8
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line8"
                            className="form-control"
                            defaultValue={detailsList?.line8}
                          />
                          {errors.line8 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 9
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line9"
                            className="form-control"
                            defaultValue={detailsList?.line9}
                          />
                          {errors.line9 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 10
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line10"
                            className="form-control"
                            defaultValue={detailsList?.line10}
                          />
                          {errors.line10 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 11
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line11"
                            className="form-control"
                            defaultValue={detailsList?.line11}
                          />
                          {errors.line11 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 12
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line12"
                            className="form-control"
                            defaultValue={detailsList?.line12}
                          />
                          {errors.line12 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 13
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line13"
                            className="form-control"
                            defaultValue={detailsList?.line13}
                          />
                          {errors.line13 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 14
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line14"
                            className="form-control"
                            defaultValue={detailsList?.line14}
                          />
                          {errors.line14 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 15
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line15"
                            className="form-control"
                            defaultValue={detailsList?.line15}
                          />
                          {errors.line15 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Line 16
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: false })}
                            type="file"
                            id="fv-subject"
                            name="line16"
                            className="form-control"
                            onChange={onFileSelect}
                            defaultValue={detailsList?.line16}
                            accept="image/*"
                          />
                          {file ? (
                            <img
                              src={URL.createObjectURL(file)}
                              alt="Preview"
                              style={{ width: '30px', height: '30px', marginTop: '10px' }}
                            />
                          ) : <img src={imageurl + detailsList?.line16} style={{ width: "100px", height: "100px" }} />}

                          {errors.line16 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                  </div>

                  <Col md="12">
                    <div className="form-group">
                      <Button color="primary" size="lg">
                        Save Information
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </PreviewCard>
        </Block>


      </Content>
    </React.Fragment>
  );
};
export default Blogs;
