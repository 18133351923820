import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";

import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";
import axios from "axios";

const BlogsInfo = () => {
  const { errors, register, handleSubmit } = useForm();
  const [detailsList, setDetailsList] = React.useState({});
  const history = useHistory();

  const onFormSubmit = (e) => {
    const language = history.location.state?.detail.language;
    const token = localStorage.getItem("token");
    // const method = "http://localhost:3001/admin/blogInfo/create-update";
    const method = "/blogInfo/create-update";
    const data = e;
    const body = {
      language: language,
      header: data.header,
      body: data.body,
      buttonText: data.buttonText ? data.buttonText : "",
      midBodyHeader: data.midBodyHeader ? data.midBodyHeader : "",
      midBodySubHeader: data.midBodySubHeader ? data.midBodySubHeader : "",
      midBodyLink: data.midBodyLink ? data.midBodyLink : "",
      midBodyLikeText: data.midBodyLikeText ? data.midBodyLikeText : "",
      midBodyCommentText: data.midBodyCommentText ? data.midBodyCommentText : "",
      midBodySharesText: data.midBodySharesText ? data.midBodySharesText : "",
      midBodyLikeCount: data.midBodyLikeCount ? data.midBodyLikeCount : "",
      midBodyCommentCount: data.midBodyCommentCount ? data.midBodyCommentCount : "",
      midBodySharesCount: data.midBodySharesCount ? data.midBodySharesCount : "",
      lastButtonText: data.lastButtonText ? data.lastButtonText : "",
    };
    new ServiceStore().InsertData(method, body, token).then((res) => {
      console.log("res", res);
      alert("Blog information updated successfully");
      history.push("/blogsInfolist");
    });
  };
  const formClass = classNames({
    "form-validate": true,
  });
  React.useEffect(() => {
    const language = history.location.state?.detail.language;
    if (language) {
      // const method = `http://localhost:3001/admin/blogInfo?language=${language}`;
      const method = `/blogInfo?language=${language}`;
      new ServiceStore().GetAllData(method).then((res) => {
        console.log("res", res.data[0]);
        setDetailsList(res.data[0]);
      });
    }
  }, []);

  return (
    <React.Fragment>
      {/* <Head title="Form Validation"></Head> */}
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BlockTitle tag="h2" className="fw-normal">
              Blog Information
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <BlockHead>
            <BlockHeadContent></BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <React.Fragment>
              <Form className={formClass} onSubmit={handleSubmit(onFormSubmit)}>
                <Row className="g-gs">
                  {!history.location.state?.detail._id && (
                    <Col lg="7">
                      <div className="form-group">
                        <ul className="custom-control-group g-3 align-center flex-wrap">
                          <li>
                            <div className="custom-control custom-radio">
                              <input
                                ref={register({ required: true })}
                                type="radio"
                                className="custom-control-input"
                                defaultChecked
                                name="language"
                                id="reg-enable"
                                value="english"
                              />
                              <label className="custom-control-label" htmlFor="reg-enable">
                                English
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-radio">
                              <input
                                ref={register({ required: true })}
                                type="radio"
                                className="custom-control-input"
                                name="language"
                                id="reg-disable"
                                value="marathi"
                              />
                              <label className="custom-control-label" htmlFor="reg-disable">
                                Marathi
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  )}
                  <div className="banner-tab">
                    <h3>Main Block</h3>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          header
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            defaultValue={detailsList?.header}
                            type="text"
                            id="fv-full-name"
                            name="header"
                            className="form-control"
                          />
                          {errors.header && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Body
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-full-name"
                            name="body"
                            className="form-control"
                            defaultValue={detailsList?.body}
                          />
                          {errors.body && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          buttonText
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="buttonText"
                            className="form-control"
                            defaultValue={detailsList?.buttonText}
                          />
                          {errors.buttonText && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                  </div>
                  <div className="banner-tab">
                    <h3>Middle Block</h3>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Header
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="midBodyHeader"
                            className="form-control"
                            defaultValue={detailsList?.midBodyHeader}
                          />
                          {errors.midBodyHeader && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Sub-Header
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="midBodySubHeader"
                            className="form-control"
                            defaultValue={detailsList?.midBodySubHeader}
                          />
                          {errors.midBodySubHeader && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Link or Button
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="midBodyLink"
                            className="form-control"
                            defaultValue={detailsList?.midBodyLink}
                          />
                          {errors.midBodyLink && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Like Text
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="midBodyLikeText"
                            className="form-control"
                            defaultValue={detailsList?.midBodyLikeText}
                          />
                          {errors.midBodyLikeText && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Like Count
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="midBodyLikeCount"
                            className="form-control"
                            defaultValue={detailsList?.midBodyLikeCount}
                          />
                          {errors.midBodyLikeCount && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Comments Text
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="midBodyCommentText"
                            className="form-control"
                            defaultValue={detailsList?.midBodyCommentText}
                          />
                          {errors.midBodyCommentText && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Comments Count
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="midBodyCommentCount"
                            className="form-control"
                            defaultValue={detailsList?.midBodyCommentCount}
                          />
                          {errors.midBodyCommentCount && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Shares Text
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="midBodySharesText"
                            className="form-control"
                            defaultValue={detailsList?.midBodySharesText}
                          />
                          {errors.midBodySharesText && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Shares Count
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="midBodySharesCount"
                            className="form-control"
                            defaultValue={detailsList?.midBodySharesCount}
                          />
                          {errors.midBodySharesCount && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                  </div>
                  <div className="banner-tab">
                    <h3>Footer Block</h3>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                          Button Text
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            defaultValue={detailsList?.lastButtonText}
                            type="text"
                            id="fv-full-name"
                            name="lastButtonText"
                            className="form-control"
                          />
                          {errors.lastButtonText && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                  </div>

                  <Col md="12">
                    <div className="form-group">
                      <Button color="primary" size="lg">
                        Save Information
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default BlogsInfo;
