import React, { useState } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import SaleRevenue from "../components/partials/default/sale-revenue/SaleRevenue";
import ActiveSubscription from "../components/partials/default/active-subscription/ActiveSubscription";
import AvgSubscription from "../components/partials/default/avg-subscription/AvgSubscription";
import SalesOverview from "../components/partials/default/sales-overview/SalesOverview";
import TransactionTable from "../components/partials/default/transaction/Transaction";
import RecentActivity from "../components/partials/default/recent-activity/Activity";
import NewsUsers from "../components/partials/default/new-users/User";
import Support from "../components/partials/default/support-request/Support";
import Notifications from "../components/partials/default/notification/Notification";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem, Container } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewCard,
  PreviewAltCard,
  BlockBetween,
} from "../components/Component";
import ServiceStore from "../utils/ServiceStore";
import { CardTitle } from "reactstrap";
import { TooltipComponent } from "../components/Component";
const Homepage = () => {
  const [sm, updateSm] = useState(false);
  const [datalist, setdatalist] = React.useState([])
  const [englishblogcountlist, setenglishblogcountlist] = React.useState([])

  const [marathiblogcountlist, setmarathiblogcountlist] = React.useState([])
  const [englishconvercountlist, setenglishconvercountlist] = React.useState([])

  const [marathiconvercountlist, setmarathiconvercountlist] = React.useState([])
  const [authCheckModal, setAuthCheckModal] = useState(false);

  const authCheckToggle = () => setAuthCheckModal(!authCheckModal);
  React.useEffect(() => {

    getAlldataFile()


  }, []);

  const getAlldataFile = () => {
    const methods = "user/usercount"
    new ServiceStore().GetAllData(methods).then((res) => {
      setdatalist(res.data)
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })

    new ServiceStore().GetAllData('writeblogtopic/englishblogcount').then((res) => {
      setenglishblogcountlist(res.data)
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })

    new ServiceStore().GetAllData('writeblogtopic/marathiblogcount').then((res) => {
      setmarathiblogcountlist(res.data)
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })


    new ServiceStore().GetAllData('conservationtopic/englishcountlist').then((res) => {
      setenglishblogcountlist(res.data)
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })

    new ServiceStore().GetAllData('conservationtopic/marathicountlist').then((res) => {
      setmarathiblogcountlist(res.data)
    })
      .catch((err) => {
        if (err?.response?.data?.error === "Please authenticate.") {
          setAuthCheckModal(true)
        }
      })
  }
  return (
    <React.Fragment>
      <Head title="Homepage"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Dashboard
              </BlockTitle>
              {/* <BlockDes className="text-soft">
                <p>Welcome to DashLite Dashboard Template</p>
              </BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                {/* <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                          <Icon className="d-none d-sm-inline" name="calender-date" />
                          <span>
                            <span className="d-none d-md-inline">Last</span> 30 Days
                          </span>
                          <Icon className="dd-indc" name="chevron-right" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                                href="#!"
                              >
                                <span>Last 30 days</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                                href="#dropdownitem"
                              >
                                <span>Last 6 months</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                                href="#dropdownitem"
                              >
                                <span>Last 3 weeks</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button color="primary">
                        <Icon name="reports" />
                        <span>Reports</span>
                      </Button>
                    </li>
                  </ul>
                </div> */}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>

          <Container>
            <PreviewAltCard>
              <Row>

                <Col sm={12} md={6} lg={6} xxl={6} className="mb-4">

                  <PreviewCard>
                    <div className="card-title-group align-start mb-2">
                      <CardTitle>
                        <h6 className="title">Total User</h6>
                      </CardTitle>
                      <div className="card-tools">
                        <TooltipComponent
                          icon="help-fill"
                          iconClass="card-hint"
                          direction="left"
                          id="Tooltip-1"
                          text="Revenue from subscription"
                        />
                      </div>
                    </div>
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div className="nk-sale-data">
                        <span className="amount">{datalist}</span>

                      </div>

                    </div>
                  </PreviewCard>
                </Col>

                <Col sm={12} md={6} lg={6} xxl={6} className="mb-4">
                  <PreviewCard>
                    <div className="card-title-group align-start mb-2">
                      <CardTitle>
                        <h6 className="title">English Blog Count</h6>
                      </CardTitle>
                      <div className="card-tools">
                        <TooltipComponent
                          icon="help-fill"
                          iconClass="card-hint"
                          direction="left"
                          id="Tooltip-2"
                          text="Daily Avg. subscription"
                        />
                      </div>
                    </div>
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div className="nk-sale-data">
                        <span className="amount">{englishblogcountlist}</span>

                      </div>

                    </div>
                  </PreviewCard>
                </Col>

                <Col sm={12} md={6} lg={6} xxl={6} className="mb-4">
                  <PreviewCard>
                    <div className="card-title-group align-start mb-2">
                      <CardTitle>
                        <h6 className="title">Marathi Blog Count</h6>
                      </CardTitle>
                      <div className="card-tools">
                        <TooltipComponent
                          icon="help-fill"
                          iconClass="card-hint"
                          direction="left"
                          id="Tooltip-3"
                          text="Daily Avg. subscription"
                        />
                      </div>
                    </div>
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div className="nk-sale-data">
                        <span className="amount">{marathiblogcountlist}</span>

                      </div>

                    </div>
                  </PreviewCard>
                </Col>

              </Row>
            </PreviewAltCard>

            <PreviewAltCard>
              <Row>
                <Col sm={12} md={6} lg={6} xxl={6} className="mb-4">
                  <PreviewCard>
                    <div className="card-title-group align-start mb-2">
                      <CardTitle>
                        <h6 className="title">English Conservation Topic </h6>
                      </CardTitle>
                      <div className="card-tools">
                        <TooltipComponent
                          icon="help-fill"
                          iconClass="card-hint"
                          direction="left"
                          id="Tooltip-4"
                          text="Daily Avg. subscription"
                        />
                      </div>
                    </div>
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div className="nk-sale-data">
                        <span className="amount">{englishblogcountlist}</span>

                      </div>

                    </div>
                  </PreviewCard>

                </Col>
                <Col sm={12} md={6} lg={6} xxl={6} className="mb-4">
                  <PreviewCard>
                    <div className="card-title-group align-start mb-2">
                      <CardTitle>
                        <h6 className="title">Marathi Conservation Topic </h6>
                      </CardTitle>
                      <div className="card-tools">
                        <TooltipComponent
                          icon="help-fill"
                          iconClass="card-hint"
                          direction="left"
                          id="Tooltip-4"
                          text="Daily Avg. subscription"
                        />
                      </div>
                    </div>
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div className="nk-sale-data">
                        <span className="amount">{marathiblogcountlist}</span>

                      </div>

                    </div>
                  </PreviewCard>
                </Col>
              </Row>
            </PreviewAltCard>

          </Container>

        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Homepage;
