import React, { useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";
import Icon from "../../../components/icon/Icon"
import { loginData, orderData, transactionData } from "../../../components/table/TableData";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge, Modal, ModalBody } from "reactstrap";
// import { RequestListLanguageFilters, RequestListSearchKeys } from "../../../utils/constant"

import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  BlockBetween,
  RSelect,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";
import { RequestListLanguageFilters } from "../../../utils/constant";

const CommunityForumList = () => {
  const { errors, register, handleSubmit } = useForm();

  const [id] = React.useState("form-1");
  const [file, setFile] = React.useState('')
  const [file20, setFile20] = React.useState('')
  const [file24, setFile24] = React.useState('')
  const [datalist, setdatalist] = React.useState([])
  const [deletedata, setdeletedata] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedValue, setSelectedValue] = useState('');
  const [refresh, setRefresh] = React.useState(false);
  const [categoryName, setCategoryName] = useState('');


  const history = useHistory();
  const home = () => {
    history.push("/communityforum");
  }

  const handleSearch1 = (e) => {
    setSearchTerm(e.target.value);
    const searchTerm = e.target.value
    if (searchTerm) {
      const filterData = datalist.filter((data) => {
        const regex = new RegExp(searchTerm, 'i')
        return data.category.match(regex)
      });
      setdatalist(filterData);
    } else {
      getAlldataFile();
    }
  }

  const resetForm = () => {
    setSearchTerm('');
    getAlldataFile();
  }

  const Deletedata = (e, index) => {
    console.log("indexxx : ", index)
    const methods = "communitytopic/";
    new ServiceStore().DeleteData(methods, index._id).then((res) => {
      setdeletedata("true");
    });
  };
  const Editdata = (index, e) => {
    console.log("e", index)
    // history.push("/demo1/home");
    history.push({
      pathname: '/communityforum',
      search: '?id=' + index._id,
      state: { detail: index },
    })
  }
  const [modal, setModal] = useState({
     
    add: false,
  });
  React.useEffect(() => {

    getAlldataFile()


  }, [deletedata,modal]);

  const getAlldataFile = () => {
    const methods = "communitytopic/"
    new ServiceStore().GetAllData(methods).then((res) => {
      console.log("res.response", res)
      setdatalist(res.data)
    });
  }
  
  const onFormCancel=() => {
    setModal({ add: false });
  }
  const filterStatus = [
    { value: "english", label: "English" },
    { value: "marathi", label: "Marathi" },
  ];
  const handleSelectChange = (value) => {
    console.log(value)
    setSelectedValue(value.value);
  };
  const handleChange = (e) => {
    setCategoryName(e.target.value)
  }

  const onSubmit = (e) => {
    // e.preventDefault();
    if (!setSelectedValue) {
      alert("Please select")
    }
    console.log("categoryName", categoryName)
    console.log("selectedValue", selectedValue)
    const data = {
      language: selectedValue,
      category: categoryName
    }
    if (!data.category && !data.language) {
      alert("Please fill empty fields")
    }
    else if(data.language == null || data.language == "undefined"){
      alert("Please select Language")
    }
    else if (data.category == null || data.category == "undefined" || data.category == '') {
      alert("Category is empty")
    }
    else {
      new ServiceStore().InsertData('/communitytopic', data).then((res) => {
        console.log("res", res)
        setRefresh(!refresh)
        data.language = ''
        data.category = ''
        setModal({ add: false })
      });
    }
  }

  const DropdownTrans = () => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu end>
          <ul className="link-list-plain">
            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                View
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Invoice
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Print
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <React.Fragment>
      {/* <Head title="Form Validation">
   
      </Head> */}
      <Content page="component">

      </Content>
      <div>
        {/* <form onSubmit1={(e) => { }}> */}
          <div className="row" style={{ marginBottom: "10px", marginLeft: "16px" }}>
            <div style={{ marginBottom: '1rem' }}><h3>Community Topic</h3></div>
            <div className="col-md-3">
              <label style={{ marginLeft: '4px' }}>Language</label>
              <select
                name="language"
                // onChange={(e) => handle(e)}
                id="language"
                style={{ marginLeft: "5px" }}
                className="form-control"
              // value={controller?.language}
              >
                {RequestListLanguageFilters?.map((ele) => (
                  <option value={ele?.value} key={ele?.key}>
                    {ele?.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <label>Search</label>
              <input
                className="form-control"
                placeholder="Search..."
                onChange={handleSearch1}
                value={searchTerm}
              // ref={searchRef}
              />
            </div>

            <div className="col-md-3">
              <button style={{ marginTop: "23px" }} type="button" className="btn btn-danger"
                onClick={resetForm}
              >
                Reset
              </button>
              <button style={{ marginTop: "23px", marginLeft: '1rem' }} type="button" className="btn btn-primary"
              onClick={() =>{setModal({ add: true })}} 
              >
                Create
              </button>
            </div>
          </div>
        {/* </form> */}
      </div>
      
      <PreviewCard className='card-table'>
        <table className="table table-orders">
          <thead className="tb-odr-head">
            <tr className="tb-odr-item">
              <th className="tb-odr-info">
                <span className="tb-odr-id">Language</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Category</span>
              </th>
              <th className="tb-odr-action">&nbsp;
                <span className="tb-odr-id">Action</span>
              </th>
            </tr>
          </thead>
          <tbody className="tb-odr-body">
            {datalist.length > 0 ? datalist.map((item) => {
              return (
                <tr className="tb-odr-item" key={item._id}>
                  <td className="tb-odr-info">
                    <span className="tb-odr-id">
                      <a
                        href="#id"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        {item.language === 'english' ? 'English' : 'मराठी'}
                      </a>
                    </span>
                  </td>
                  <td className="tb-odr-info">
                    <span className="tb-odr-id">{item.category}</span>
                  </td>


                  <td className="tb-odr-action">
                    <div className="tb-odr-id">
                      {/* <Button color="primary" className="btn-sm" onClick={e => Editdata(item, e) }>
                    Edit
                  </Button> */}
                      <Icon
                        name="edit-alt-fill"
                        style={{ cursor: "pointer" }}
                        onClick={e => Editdata(item, e)}
                      ></Icon>
                      &nbsp;&nbsp;&nbsp;
                      <Icon
                        name="trash-empty-fill"
                        style={{ cursor: "pointer" }}
                        onClick={e => Deletedata(e, item)}
                      ></Icon>
                    </div>
                  </td>
                </tr>
              );
            }) : (
              <tr>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  <h3>No Record Found</h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>

      </PreviewCard>
      <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#close"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Create Topic</h5>
            <div className="mt-4">
              <Form className="row gy-4" autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Select Language</label>
                    <RSelect options={filterStatus} placeholder="Select Language" onChange={handleSelectChange} />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Category</label>
                    <input
                      className="form-control"
                      type="text"
                      name="title"
                      // value={formData.title}
                      onChange={handleChange}
                    // value={editItem.id}
                    // defaultValue={editItem.id}
                    // placeholder={editItem.id}
                    // readOnly={true}
                    // ref={register({ required: "This field is required" })}
                    />
                    {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                  </div>
                </Col>
                <Col md="6">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button color="primary" size="md" type="submit" >
                        Submit
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* </Block> */}
      {/* </Content> */}
    </React.Fragment>
  );
};
export default CommunityForumList;