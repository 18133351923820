import React, { useRef } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";
import Icon from "../../../components/icon/Icon";
import { loginData, orderData, transactionData } from "../../../components/table/TableData";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";

import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";
import { RequestListLanguageFilters, RequestListSearchKeys } from "../../../utils/constant";
import { debounce } from "../../../utils/commonFunctions";
import fuzzysort from "fuzzysort";
import { Card, Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from "@mui/material";

// const PublicationList = () => {
//   const { errors, register, handleSubmit } = useForm();
//   const [datalist, setdatalist] = React.useState([]);
//   const [deletedata, setdeletedata] = React.useState("");

//   const history = useHistory();
//   const home = () => {
//     history.push("/publication");
//   };

//   const Editdata = (index, e) => {
//     console.log("e", index);
//     // history.push("/demo1/home");
//     history.push({
//       pathname: "/publication",
//       search: "?id=" + index._id,
//       state: { detail: index },
//     });
//     console.log({ history });
//   };
//   const Deletedata = (index, e) => {
//     const methods = "publication/";
//     new ServiceStore().DeleteData(methods, index._id).then((res) => {
//       setdeletedata("true");
//     });
//   };
//   React.useEffect(() => {
//     getAlldataFile();
//   }, [deletedata]);

//   const getAlldataFile = () => {
//     const methods = "publication/";
//     new ServiceStore().GetAllData(methods).then((res) => {
//       console.log("res.response", res);
//       setdatalist(res.data);
//     });
//   };

//   return (
//     <React.Fragment>
//       {/* <Head title="Form Validation">

//       </Head> */}
//       <Content page="component">
//         <div className="form-group" style={{ float: "right" }}>
//           <Button color="primary" size="lg" onClick={home}>
//             Create
//           </Button>
//         </div>
//       </Content>
//       {/* <Block size="lg">
//           <BlockHead>
//             <BlockHeadContent>

//             </BlockHeadContent>
//           </BlockHead> */}
//       <PreviewCard className="card-table">
//         <table className="table table-orders">
//           <thead className="tb-odr-head">
//             <tr className="tb-odr-item">
//               <th className="tb-odr-info">
//                 <span className="tb-odr-id">Language</span>
//               </th>
//               <th className="tb-odr-info">
//                 <span className="tb-odr-id">Title</span>
//               </th>
//               <th className="tb-odr-action">
//                 &nbsp;
//                 <span className="tb-odr-id">Action</span>
//               </th>
//             </tr>
//           </thead>
//           <tbody className="tb-odr-body">
//             {datalist.map((item) => {
//               return (
//                 <tr className="tb-odr-item" key={item._id}>
//                   <td className="tb-odr-info">
//                     <span className="tb-odr-id">
//                       <a
//                         href="#id"
//                         onClick={(ev) => {
//                           ev.preventDefault();
//                         }}
//                       >
//                         {item.language}
//                       </a>
//                     </span>
//                   </td>
//                   <td className="tb-odr-info">
//                     <span className="tb-odr-id">
//                       <a
//                         href="#id"
//                         onClick={(ev) => {
//                           ev.preventDefault();
//                         }}
//                       >
//                         {item.title}
//                       </a>
//                     </span>
//                   </td>

//                   <td className="tb-odr-action">
//                     <div className="tb-odr-id">
//                       <Button color="primary" className="btn-sm" onClick={(e) => Editdata(item, e)}>
//                         Edit
//                       </Button>
//                       &nbsp;&nbsp;&nbsp;
//                       <Button color="danger" className="btn-sm" onClick={(e) => Deletedata(item, e)}>
//                         Delete
//                       </Button>
//                     </div>
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </PreviewCard>
//       {/* </Block> */}

//       {/* </Content> */}
//     </React.Fragment>
//   );
// };
// export default PublicationList;

const PublicationList = () => {
  const initialController = {
    page: 1,
    rowsPerPage: 10,
    language: "",
  };
  const [datalist, setdatalist] = React.useState([]);
  const [filteredDatalist, setFilteredDatalist] = React.useState([]);
  const [datalistCount, setdatalistCount] = React.useState(0);
  const [controller, setController] = React.useState(initialController);
  const [deletedata, setdeletedata] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [refresh, setRefresh] = React.useState(false)

  const searchRef = useRef(null);

  const history = useHistory();
  const home = () => {
    history.push("/publication");
  };

  const Editdata = (index, e) => {
    history.push({
      pathname: "/publication",
      search: "?id=" + index._id,
      state: { detail: index },
    });
  };

  const Deletedata = (e, index) => {
    const methods = "publication/";
    new ServiceStore().DeleteData(methods, index._id).then((res) => {
      setdeletedata("true");
      setRefresh(!refresh)
      alert ("Publication deleted successfully")
    });
  };

  React.useEffect(() => getAlldataFile(), [controller, deletedata,refresh]);
  React.useEffect(() => {
    if (search?.trim()) {
      const searchResults = fuzzysort
        .go(search, datalist?.rows, { keys: ["title"] })
        ?.map((result) => result?.obj)
        ?.filter((e) => e);

      setFilteredDatalist([...searchResults]);
    } else {
      const defaultValues = datalist?.rows ? [...datalist?.rows] : [];
      setFilteredDatalist([...defaultValues]);
    }
  }, [search, datalist]);

  const getAlldataFile = () => {
    const methods = "publication/filterlist";
    const bodyData = {
      page: controller.page,
      limit: controller.rowsPerPage,
      language: controller.language,
    };
    new ServiceStore().GetDataBYFilter(methods, bodyData).then((res) => {
      setdatalist(res.response.data);
      setdatalistCount(res.response.data.count);
    });
  };

  function handle(e) {
    const newdata = { ...controller };
    newdata[e.target.id] = e.target.value;
    setController({ ...newdata, page: 1 });
  }

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage + 1,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1,
    });
  };

  const resetForm = () => {
    searchRef.current.value = "";
    setSearch("");
    setController({ ...initialController });
  };

  const debounceSearch = debounce((query) => setSearch(query), 350);

  const handleSearch = (e) => debounceSearch(e?.target?.value);

  return (
    <React.Fragment>
      <Content page="component">

        {/* <div className="form-group" style={{ float: "right" }}>
          <Button color="primary" size="md" onClick={home}>
            Create
          </Button>
        </div> */}
      </Content>
      <div>
        <form onSubmit={(e) => { }}>
          <div className="row" style={{ marginBottom: "10px", marginLeft: "16px" }}>
            <div style={{ marginBottom: '1rem' }}>
              <h3>Publication </h3>
            </div>
            <div className="col-md-3">
              <label style={{ marginLeft: '4px' }}>Language</label>
              <select
                name="language"
                onChange={(e) => handle(e)}
                id="language"
                style={{ marginLeft: "5px" }}
                className="form-control"
                value={controller?.language}
              >
                {RequestListLanguageFilters?.map((ele) => (
                  <option value={ele?.value} key={ele?.key}>
                    {ele?.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <label>Status</label>
              <select
                name="status"
                onChange={(e) => handle(e)}
                id="status"
                style={{ marginLeft: "5px" }}
                className="form-control"
                value={controller?.status}
              >
                <option value="All">All</option>
                <option value="Approve">Accepted</option>
                <option value="Pending" selected>
                  Pending
                </option>
                <option value="Reject">Rejected</option>
              </select>
            </div>
            <div className="col-md-3">
              <label>Search</label>
              <input
                className="form-control"
                placeholder="Search..."
                onChange={handleSearch}
                defaultValue={search}
                ref={searchRef}
              />
            </div>

            <div className="col-md-3">
              <button style={{ marginTop: "23px" }} type="button" className="btn btn-danger" onClick={resetForm}>
                Reset
              </button>
              <button style={{ marginTop: "23px", marginLeft: '1rem' }} type="button" className="btn btn-primary"
                onClick={home}
              >
                Create
              </button>
            </div>
            <div className="col-md-3">

            </div>
          </div>
        </form>
      </div>

      <PreviewCard className="card-table">
        <table className="table table-orders">
          <thead className="tb-odr-head">
            <tr className="tb-odr-item">
            <th className="tb-odr-info">
                <span className="tb-odr-id">No.</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Language</span>
              </th>
              <th className="tb-odr-info">
                <span className="tb-odr-id">Title</span>
              </th>
              <th className="tb-odr-action">
                &nbsp;
                <span className="tb-odr-id">Action</span>
              </th>
            </tr>
          </thead>
          <tbody className="tb-odr-body">
            {filteredDatalist?.length > 0 ? (
              filteredDatalist?.map((item, index) => {
                return (
                  <tr className="tb-odr-item" key={item._id}>
                  <td className="tb-odr-info">
                    <span className="tb-odr-id">
                      {index + 1}
                    </span>
                  </td>
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">
                        <a
                          href="#id"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          {item.language === 'english' ? 'English' : 'मराठी'}
                        </a>
                      </span>
                    </td>
                    <td className="tb-odr-info">
                      <span className="tb-odr-id">
                        <a
                          href="#id"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          {item.title}
                        </a>
                      </span>
                    </td>

                    <td className="tb-odr-action">
                      <div className="tb-odr-id">
                        {/* <Button color="primary" className="btn-sm" onClick={(e) => Editdata(item, e)}>
                          Edit
                        </Button> */}
                        <Icon
                          name="edit-alt-fill"
                          style={{ cursor: "pointer" }}
                          onClick={e => Editdata(item, e)}
                        ></Icon>
                        &nbsp;&nbsp;&nbsp;
                        {/* <Button color="danger" className="btn-sm" onClick={(e) => Deletedata(item, e)}>
                          Delete
                        </Button> */}
                        <Icon
                          name="trash-empty-fill"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => Deletedata(e, item)}
                        ></Icon>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  <h3>No Record Found</h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {datalist?.count !== 0 && datalist?.rows?.length > 0 ? (
            <>
            <div className="row">
            <div className="col" style={{textAlign:"end",paddingTop:"1rem"}}>Current Page : {controller.page}</div>
            <div className="col">
            <TablePagination
              style={{ marginTop: "10px" }}
              component="div"
              onPageChange={handlePageChange}
              page={controller.page - 1}
              count={datalistCount}
              rowsPerPage={controller.rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /></div>
            </div>
            </>
        ) : null}
      </PreviewCard>
      {/* </Block> */}

      {/* </Content> */}
    </React.Fragment>
  );
};
export default PublicationList;