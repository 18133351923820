import React from "react";
import ServiceStore from "../../utils/ServiceStore";
import { Row } from "reactstrap";

const AddPhoto = ({ state, setState, errors, innerRef, arrayName, handleRefresh }) => {
  const uploadFileToStorage = async (file) => {
    console.log("file", file);
    const formData = new FormData();
    formData.append("file", file, file.name?.replaceAll(" ", "")?.toLowerCase());
    formData.append("language", state.language);
    formData.append("line", arrayName);

    try {
      console.log("trying");
      const response = await new ServiceStore().UploadFile("gallary/ImageUpload", formData);
      console.log("add image response : ", response);
      // if response is valid then push that in returnArray
      if (response && response?.data?.data?.length > 0) {
        const responseArray = response?.data?.data;
        return responseArray[0];
      } else {
        handleRefresh();
        return null;
      }
    } catch (error) {
      console.log("In Upload File: ", { error });
      return null;
    }
  };

  const handleAdd = async (arrayName, itemsToAdd) => {
    // what we are going to do here.
    // 1. add files in mongodb
    // 2. in return we will get url of that file
    // 3. we will add that urls in array which are in state
    // 4. user clicks on submit button at that time we will update database.

    let newArray = [];
    const returnArr = await Promise.all(Array.from(itemsToAdd).map(async (file) => await uploadFileToStorage(file)));
    newArray = [...state[arrayName], ...returnArr];

    const newDetailList = { ...state };
    newDetailList[arrayName] = newArray;
    setState({ ...newDetailList });
  };

  return (
    <div style={{ textAlign: "-webkit-center" }}>
      <input
        ref={innerRef}
        type="file"
        id={`fv-subject-${arrayName}`}
        className="form-control"
        onChange={(e) => handleAdd(arrayName, e?.target?.files)}
        style={{ display: "none" }}
        multiple
        accept="image/*"
      />
      <br />
      <button
        className="col border-end d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "#8080803b",
          fontsize: "10px",
          padding: "12px 24px",
          border: "none",
          cursor: "pointer",
          borderRadius: "5px",
          textalign: "center",
          fontSize: "14px",
          textAlign: "center",
        }}
        onClick={(e) => {
          e.preventDefault();
          document?.getElementById(`fv-subject-${arrayName}`)?.click();
        }}
      >
        + Add Image
      </button>
      {errors && <span className="invalid">This field is required</span>}
    </div>
  );
};

export default AddPhoto;
