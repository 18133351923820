import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";
import "./common.css"

import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";

const AdminUser = () => {
  const { errors, register, handleSubmit } = useForm();
  const [id] = React.useState("form-1");
  const [file, setFile] = React.useState('')
  const [detailsList, setDetailsList] = React.useState({})
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const history = useHistory();

  const home = () => {
    history.push("/adminuserlist");
  }
  const onFormSubmit = (e) => {
    const dataid = history.location.state?.detail._id
    const token = localStorage.getItem("token");

    const data = e


    if (dataid) {
      data.id = dataid

      console.log("data", data)
      new ServiceStore().UpdateData('adminuser/updatedata', data,token).then((res) => {
        console.log("res", res)
        history.push("/adminuserlist");
      });
    } else {

      const method = 'adminuser/'
      const body = {
        aboutus: data.aboutus,
        blog: data.blog,
        communityform: data.communityform,
        email: data.email,
        facilities: data.facilities,
        gallary: data.gallary,
        home: data.home,
        password: data.password,
        otp: ""
      }
      console.log("data", body)
      new ServiceStore().InsertData(method, body,token).then((res) => {
        console.log("res", res)
        alert("Admin user added successfully")
        history.push("/adminuserlist");
      });
    }

  };
  const formClass = classNames({
    "form-validate": true

  });
  const onFileSelect = event => {
    const file = event.target.files
    console.log("file", file)
    setFile(file)

    // if (event.target.files[0].size < 100000000) {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file); // toBase64
    //     setFile(file)
    // }else{
    //   this.imgeerror = 'Please Select Max 100MB Size File'
    // }
  }

  React.useEffect(() => {
    const dataid = history.location.state?.detail._id
    // console.log("data",dataid)
    if (dataid) {
      new ServiceStore().GetDataByID('adminuser/', dataid).then((res) => {
        console.log("res", res.data)
        setDetailsList(res.data)

      })
    }
  }, [])

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault(); // Prevent spacebar from adding a space
    }
  };

  return (
    <React.Fragment>
      {/* <Head title="Form Validation"></Head> */}
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BlockTitle tag="h2" className="fw-normal">
              User
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>

            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <React.Fragment>
              <Form className={formClass} onSubmit={handleSubmit(onFormSubmit)}>
                <Row className="g-gs">

                  <Col md="6">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Email
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({
                            required: true,
                            pattern: {
                              value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                              message: 'Invalid email format',
                            },
                          })}
                          defaultValue={detailsList?.email}
                          type="text"
                          id="fv-full-name"
                          name="email"
                          className="form-control"
                        />

                        {errors.email && <span className="invalid">Enter valid Email Address</span>}
                      </div>
                    </div>
                  </Col>
                  <Col md="5">
                    <div className="form-group password-input">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Password
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({
                            required: true,
                            validate: (value) => !value.includes(' ') && value.length > 4,
                          })}
                          type={passwordVisible ? 'text' : 'password'}
                          id="fv-full-name"
                          name="password"
                          className="form-control"
                          defaultValue={detailsList?.password}
                          onKeyDown={handleKeyDown}
                        />
                        <span
                          className={`password-toggle-icon ni ${passwordVisible ? 'ni-eye-off' : 'ni-eye'}`}
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        ></span>
                        {errors.password && <span className="invalid">Password length is should be greater than 4 characters</span>}
                      </div>
                    </div>
                  </Col>
                  <Col lg="7">
                    <div className="form-group">
                      <div className="custom-control custom-switch">
                        <input
                          ref={register({ required: false })}
                          type="checkbox"
                          className="custom-control-input"
                          name="home"
                          id="site-off"
                          defaultChecked={detailsList?.home}

                        />
                        <label className="custom-control-label" htmlFor="site-off">
                          Home
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col lg="7">
                    <div className="form-group">
                      <div className="custom-control custom-switch">
                        <input
                          ref={register({ required: false })}

                          type="checkbox"
                          className="custom-control-input"
                          name="aboutus"
                          id="aboutus-off"
                          defaultChecked={detailsList?.aboutus}


                        />
                        <label className="custom-control-label" htmlFor="aboutus-off">
                          About Us
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col lg="7">
                    <div className="form-group">
                      <div className="custom-control custom-switch">
                        <input
                          ref={register({ required: false })}
                          type="checkbox"
                          className="custom-control-input"
                          name="facilities"
                          id="facilities-off"
                          defaultChecked={detailsList?.facilities}


                        />
                        <label className="custom-control-label" htmlFor="facilities-off">
                          Facilities
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col lg="7">
                    <div className="form-group">
                      <div className="custom-control custom-switch">
                        <input
                          ref={register({ required: false })}
                          type="checkbox"
                          className="custom-control-input"
                          name="gallary"
                          id="gallary-off"
                          defaultChecked={detailsList?.gallary}

                        />
                        <label className="custom-control-label" htmlFor="gallary-off">
                          Gallary
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col lg="7">
                    <div className="form-group">
                      <div className="custom-control custom-switch">
                        <input
                          ref={register({ required: false })}
                          type="checkbox"
                          className="custom-control-input"
                          name="blog"
                          id="blog-off"
                          defaultChecked={detailsList?.blog}

                        />
                        <label className="custom-control-label" htmlFor="blog-off">
                          Blog
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col lg="7">
                    <div className="form-group">
                      <div className="custom-control custom-switch">
                        <input
                          ref={register({ required: false })}
                          type="checkbox"
                          className="custom-control-input"
                          name="communityform"
                          id="communityform-off"
                          defaultChecked={detailsList?.communityform}

                        />
                        <label className="custom-control-label" htmlFor="communityform-off">
                          Community Forum
                        </label>
                      </div>
                    </div>
                  </Col>



                  <Col md="12">
                    <div className="form-group">
                      <Button color="primary" size="lg">
                        Save Information
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </PreviewCard>
        </Block>


      </Content>
    </React.Fragment>
  );
};
export default AdminUser;
