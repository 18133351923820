import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormValidationComponent from "../../../components/partials/form/FormValidation";
import ServiceStore from "../../../utils/ServiceStore";
import imgeurl from "../../../utils/constant"
import {
  Block,
  BlockDes,
  PreviewCard,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
} from "../../../components/Component";
import classNames from "classnames";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Component";
import { useHistory } from "react-router-dom";

const Facilities = () => {
  const { errors, register, handleSubmit } = useForm();
  const [id] = React.useState("form-1");
  const [file, setFile] = React.useState('')
  const [file20, setFile20] = React.useState('')
  const [file24, setFile24] = React.useState('')
  const [detailsList, setDetailsList] = React.useState({})
  const history = useHistory();
  const [file4, setFile4] = React.useState('')
  const [file13, setFile13] = React.useState('')
  const [line8show, setline8show] = React.useState(false)
  const [line9show, setline9show] = React.useState(false)
  const [line10show, setline10show] = React.useState(false)




  const onFormSubmit = (e) => {
    const dataid = history.location.state?.detail._id
    const token = localStorage.getItem("token");

    const method = 'facilities/'
    const data = e


    if (dataid) {
      data.id = dataid
      console.log("file4", file4)
      console.log("detailsList.line4", detailsList)
      if (file4) {
        const formData = new FormData();
        formData.append("file", file4, file4.name);
        new ServiceStore().UploadFile('facilities/ImageUpload', formData).then((res) => {
          data.line4 = res.data.data.imagename
          new ServiceStore().UpdateData('facilities/updatedata', data,token).then((res) => {
            console.log("res", res)

          });
        })
      } else {
        data.line4 = detailsList.line4
      }
      if (file13) {
        const formData = new FormData();
        formData.append("file", file13, file13.name);
        new ServiceStore().UploadFile('facilities/ImageUpload', formData).then((res) => {
          data.file13 = res.data.data.imagename
          new ServiceStore().UpdateData('facilities/updatedata', data,token).then((res) => {
            console.log("res", res)

          });
        })
      } else {
        data.file13 = detailsList.file13
      }
      if (file) {
        const formData = new FormData();
        formData.append("file", file, file.name);
        new ServiceStore().UploadFile('facilities/ImageUpload', formData).then((res) => {
          data.line5 = res.data.data.imagename
          new ServiceStore().UpdateData('facilities/updatedata', data,token).then((res) => {
            console.log("res", res)

          });
        })
      } else {
        data.line5 = detailsList.line5
      }
      if (file20) {
        const formData20 = new FormData();
        formData20.append("file", file20, file20.name);
        new ServiceStore().UploadFile('facilities/ImageUpload', formData20).then((res) => {
          data.line6 = res.data.data.imagename
          new ServiceStore().UpdateData('facilities/updatedata', data,token).then((res) => {
            console.log("res", res)

          });
        })
      } else {
        data.line6 = detailsList.line6
      }
      if (file24) {
        const formData24 = new FormData();
        formData24.append("file", file24, file24.name);
        new ServiceStore().UploadFile('facilities/ImageUpload', formData24).then((res) => {
          data.line7 = res.data.data.imagename
          new ServiceStore().UpdateData('facilities/updatedata', data,token).then((res) => {
            console.log("res", res)

          });
        })
      } else {
        data.line7 = detailsList.line7
        new ServiceStore().UpdateData('facilities/updatedata', data,token).then((res) => {
          console.log("res", res)

        });
      }
      new ServiceStore().UpdateData('facilities/updatedata', data,token).then((res) => {
        console.log("res", res)
        history.push("/facilitiesList");
      });


    } else {
      const formData4 = new FormData();
      formData4.append("file", file4, file4.name);


      const formData13 = new FormData();
      formData13.append("file", file13, file13.name);


      const formData = new FormData();
      formData.append("file", file, file.name);


      const formData20 = new FormData();
      formData20.append("file", file20, file20.name);


      const formData24 = new FormData();
      formData24.append("file", file24, file24.name);

      new ServiceStore().UploadFile('facilities/ImageUpload', formData13).then((res) => {
        data.line13 = res.data.data.imagename

        new ServiceStore().UploadFile('facilities/ImageUpload', formData4).then((res) => {
          data.line4 = res.data.data.imagename

          new ServiceStore().UploadFile('facilities/ImageUpload', formData20).then((res) => {
            data.line6 = res.data.data.imagename

            new ServiceStore().UploadFile('facilities/ImageUpload', formData24).then((res) => {
              data.line7 = res.data.data.imagename

              new ServiceStore().UploadFile('facilities/ImageUpload', formData).then((res) => {
                console.log('imagename', res.data.data)
                data.line5 = res.data.data.imagename

                new ServiceStore().InsertData(method, data).then((res) => {
                  console.log("res", res)
                  history.push("/facilitiesList");
                });

              })
            })
          })
        })
      })
    }


  };
  const formClass = classNames({
    "form-validate": true

  });
  const onFileSelect = event => {
    const file = event.target.files[0];
    console.log("file", file)
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // toBase64
      setFile(file)
    } else {
      this.imgeerror = 'Please Select Max 100MB Size File'
    }
  }
  const onFileSelect4 = event => {
    const file = event.target.files[0];
    console.log("file", file)
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // toBase64
      setFile4(file)
    } else {
      this.imgeerror = 'Please Select Max 100MB Size File'
    }
  }
  const onFileSelect13 = event => {
    const file = event.target.files[0];
    console.log("file", file)
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // toBase64
      setFile13(file)
    } else {
      this.imgeerror = 'Please Select Max 100MB Size File'
    }
  }
  const onFileSelect20 = event => {
    const file = event.target.files[0];
    console.log("file", file)
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // toBase64
      setFile20(file)
    } else {
      this.imgeerror = 'Please Select Max 100MB Size File'
    }
  }
  const onFileSelect24 = event => {
    const file = event.target.files[0];
    console.log("file", file)
    if (event.target.files[0].size < 100000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // toBase64
      setFile24(file)
    } else {
      this.imgeerror = 'Please Select Max 100MB Size File'
    }
  }
  const onchangeline8 = data => {
    setline8show(false)
    console.log("sdfsdfsdf", data.target.value)
    if (data.target.value != "") {
      setline8show(true)
    }
  }
  const onchangeline9 = data => {
    setline9show(false)
    console.log("sdfsdfsdf", data.target.value)
    if (data.target.value != "") {
      setline9show(true)
    }
  }
  const onchangeline10 = data => {
    setline10show(false)
    console.log("sdfsdfsdf", data.target.value)
    if (data.target.value != "") {
      setline10show(true)
    }
  }



  React.useEffect(() => {
    const dataid = history.location.state?.detail._id
    // console.log("data",dataid)
    if (dataid) {
      new ServiceStore().GetDataByID('facilities/', dataid).then((res) => {
        console.log("res", res.data)
        setline8show(true)
        setline9show(true)
        setline10show(true)
        setDetailsList(res.data)

      })
    }
  }, [])

  return (
    <React.Fragment>
      {/* <Head title="Form Validation"></Head> */}
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BlockTitle tag="h2" className="fw-normal">
              Facilities
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>

            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <React.Fragment>
              <Form className={formClass} onSubmit={handleSubmit(onFormSubmit)}>
                <Row className="g-gs">
                  {!history.location.state?.detail._id &&
                    <Col lg="7">
                      <div className="form-group">
                        <ul className="custom-control-group g-3 align-center flex-wrap">
                          <li>
                            <div className="custom-control custom-radio">
                              <input
                                ref={register({ required: true })}
                                type="radio"
                                className="custom-control-input"
                                defaultChecked
                                name="language"
                                id="reg-enable"
                                value="english"
                              />
                              <label className="custom-control-label" htmlFor="reg-enable">
                                English
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-radio">
                              <input
                                ref={register({ required: true })}
                                type="radio"
                                className="custom-control-input"
                                name="language"
                                id="reg-disable"
                                value="marathi"

                              />
                              <label className="custom-control-label" htmlFor="reg-disable">
                                Marathi
                              </label>
                            </div>
                          </li>

                        </ul>
                      </div>
                    </Col>
                  }

                  <div className="banner-tab">
                  <h3>Banner</h3>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 1 Main Title
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            defaultValue={detailsList?.line1}
                            type="text"
                            id="fv-full-name"
                            name="line1"
                            className="form-control"
                          />
                          {errors.line1 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">

                        <Label className="form-label" htmlFor="fv-full-name">
                        Banner 1 Main Description
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-full-name"
                            name="line2"
                            className="form-control"
                            defaultValue={detailsList?.line2}
                          />
                          {errors.line2 && <span className="invalid">This field is required</span>}
                        </div>

                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                         Banner 1 Button Text
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line3"
                            className="form-control"
                            defaultValue={detailsList?.line3}
                          />
                          {errors.line3 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Banner 1 Image
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            type="file"
                            id="fv-subject"
                            name="line4"
                            className="form-control"
                            onChange={onFileSelect4}
                            defaultValue={detailsList?.line4}
                          />
                          {file4 ? (
                            <img
                              src={URL.createObjectURL(file4)}
                              alt="Preview"
                              style={{ width: '100px', height: '100px', marginTop: '10px' }}
                            />
                          ) : <img src={imgeurl + detailsList?.line4} style={{ width: "100px", height: "100px", marginTop:"10px" }} />}
                          {errors.line4 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                  </div>

                  <div className="banner-tab">
                  <h3>Slider Images</h3>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Image 1
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            type="file"
                            id="fv-subject"
                            name="line5"
                            className="form-control"
                            onChange={onFileSelect}
                            multiple
                            defaultValue={detailsList?.line5}
                          />
                          {file ? (
                            <img
                              src={URL.createObjectURL(file)}
                              alt="Preview"
                              style={{ width: '100px', height: '100px', marginTop: '10px' }}
                            />
                          ) : <img src={imgeurl + detailsList?.line5} style={{ width: "100px", height: "100px", marginTop:"10px" }} />}

                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Image 2
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            type="file"
                            id="fv-subject"
                            name="line6"
                            className="form-control"
                            onChange={onFileSelect20}
                            multiple
                            defaultValue={detailsList?.line6}
                          />
                          {file20 ? (
                            <img
                              src={URL.createObjectURL(file20)}
                              alt="Preview"
                              style={{ width: '100px', height: '100px', marginTop: '10px' }}
                            />
                          ) : <img src={imgeurl + detailsList?.line6} style={{ width: "100px", height: "100px", marginTop:"10px" }} />}

                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Image 3
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            type="file"
                            id="fv-subject"
                            name="line7"
                            className="form-control"
                            onChange={onFileSelect24}
                            multiple
                            defaultValue={detailsList?.line7}
                          />
                          {file24 ? (
                            <img
                              src={URL.createObjectURL(file24)}
                              alt="Preview"
                              style={{ width: '100px', height: '100px', marginTop: '10px' }}
                            />
                          ) : <img src={imgeurl + detailsList?.line7} style={{ width: "100px", height: "100px", marginTop:"10px" }} />}

                        </div>
                      </div>
                    </Col>

                    <div className="banner-tab">
                    <h5>Slider Image 1 Description</h5>
                      <Col md="6">
                        <div className="form-group">
                          <Label className="form-label" htmlFor="fv-subject">
                            Heading On Image 1
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line8"
                              className="form-control"
                              defaultValue={detailsList?.line8}
                              onChange={onchangeline8}

                            />
                            {errors.line8 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>
                      {line8show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                              Image 1 Data 1 Title
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subline18"
                                className="form-control"
                                defaultValue={detailsList?.subline18}


                              />
                              {errors.subline18 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line8show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 1 Data 2 Title
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subline28"
                                className="form-control"
                                defaultValue={detailsList?.subline28}


                              />
                              {errors.subline28 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line8show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 1 Data 3 Title
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subline38"
                                className="form-control"
                                defaultValue={detailsList?.subline38}


                              />
                              {errors.subline38 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line8show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 1 Data 1 Description
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subofsubline18"
                                className="form-control"
                                defaultValue={detailsList?.subofsubline18}


                              />
                              {errors.subofsubline18 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line8show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 1 Data 2 Description
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subofsubline28"
                                className="form-control"
                                defaultValue={detailsList?.subofsubline28}


                              />
                              {errors.subofsubline28 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line8show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 1 Data 3 Description
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subofsubline38"
                                className="form-control"
                                defaultValue={detailsList?.subofsubline38}


                              />
                              {errors.subofsubline38 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                    </div>

                    <div className="banner-tab">
                    <h5>Slider Image 1 Description</h5>
                      <Col md="12">
                        <div className="form-group">
                          <Label className="form-label" htmlFor="fv-subject">
                          Heading On Image 2
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line9"
                              className="form-control"
                              defaultValue={detailsList?.line9}
                              onChange={onchangeline9}

                            />
                            {errors.line9 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>

                      {line9show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 2 Data 1 Title
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subline19"
                                className="form-control"
                                defaultValue={detailsList?.subline19}


                              />
                              {errors.subline19 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line9show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 2 Data 2 Title
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subline29"
                                className="form-control"
                                defaultValue={detailsList?.subline29}


                              />
                              {errors.subline29 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line9show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 2 Data 3 Title
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subline39"
                                className="form-control"
                                defaultValue={detailsList?.subline39}


                              />
                              {errors.subline39 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line9show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 2 Data 1 Description
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subofsubline19"
                                className="form-control"
                                defaultValue={detailsList?.subofsubline19}


                              />
                              {errors.subofsubline19 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line9show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 2 Data 2 Description
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subofsubline29"
                                className="form-control"
                                defaultValue={detailsList?.subofsubline29}


                              />
                              {errors.subofsubline29 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line9show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 2 Data 3 Description
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subofsubline39"
                                className="form-control"
                                defaultValue={detailsList?.subofsubline39}


                              />
                              {errors.subofsubline39 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                    </div>

                    <div className="banner-tab">
                    <h5>Slider Image 3 Description</h5>
                      <Col md="12">
                        <div className="form-group">
                          <Label className="form-label" htmlFor="fv-subject">
                          Heading On Image 3
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              ref={register({ required: true })}
                              type="text"
                              id="fv-subject"
                              name="line10"
                              className="form-control"
                              defaultValue={detailsList?.line10}
                              onChange={onchangeline10}
                            />
                            {errors.line10 && <span className="invalid">This field is required</span>}
                          </div>
                        </div>
                      </Col>

                      {line10show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 3 Data 1 Title
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subline110"
                                className="form-control"
                                defaultValue={detailsList?.subline110}


                              />
                              {errors.subline110 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line10show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 3 Data 2 Title
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subline210"
                                className="form-control"
                                defaultValue={detailsList?.subline210}


                              />
                              {errors.subline210 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line10show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 3 Data 3 Title
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subline310"
                                className="form-control"
                                defaultValue={detailsList?.subline310}
                              />
                              {errors.subline310 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line10show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 3 Data 1 Description
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subofsubline110"
                                className="form-control"
                                defaultValue={detailsList?.subofsubline110}

                              />
                              {errors.subofsubline110 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line10show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 3 Data 2 Description
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subofsubline210"
                                className="form-control"
                                defaultValue={detailsList?.subofsubline210}


                              />
                              {errors.subofsubline210 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                      {line10show &&
                        <Col md="4">
                          <div className="form-group">
                            <Label className="form-label" htmlFor="fv-subject">
                            Image 3 Data 3 Description
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                ref={register({ required: true })}
                                type="text"
                                id="fv-subject"
                                name="subofsubline310"
                                className="form-control"
                                defaultValue={detailsList?.subofsubline310}


                              />
                              {errors.subofsubline310 && <span className="invalid">This field is required</span>}
                            </div>
                          </div>
                        </Col>
                      }
                    </div>
                  </div>


                  <div className="banner-tab">
                  <h3>Body Date</h3>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Body Heading
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line11"
                            className="form-control"
                            defaultValue={detailsList?.line11}
                          />
                          {errors.line11 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Body Description
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            ref={register({ required: true })}
                            type="text"
                            id="fv-subject"
                            name="line12"
                            className="form-control"
                            defaultValue={detailsList?.line12}
                          />
                          {errors.line12 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label" htmlFor="fv-subject">
                          Body Image
                        </Label>
                        <div className="form-control-wrap">
                          <input
                            type="file"
                            id="fv-subject"
                            name="line13"
                            className="form-control"
                            onChange={onFileSelect13}
                            defaultValue={detailsList?.line13}
                          />
                          {file13 ? (
                            <img
                              src={URL.createObjectURL(file13)}
                              alt="Preview"
                              style={{ width: '100px', height: '100px', marginTop: '10px' }}
                            />
                          ) : <img src={imgeurl + detailsList?.line13} style={{ width: "100px", height: "100px", marginTop:"10px" }} />}

                          {errors.line13 && <span className="invalid">This field is required</span>}
                        </div>
                      </div>
                    </Col>
                  </div>


                  <Col md="12">
                    <div className="form-group">
                      <Button color="primary" size="lg">
                        Save Information
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </PreviewCard>
        </Block>


      </Content>
    </React.Fragment>
  );
};
export default Facilities;
