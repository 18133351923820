import React, { useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import ServiceStore from "../../utils/ServiceStore";
import './login.css'
import 'react-toastify/dist/ReactToastify.css';

import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, Spinner, Alert, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [errorVal, setError] = useState("");
  const history = useHistory();


  React.useEffect(() => {

    const auth = localStorage.getItem("accessToken");
    if (auth != null) {
      //  history.push(`/adminuserlist`);

    }
    // localStorage.setItem("accessToken","Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDVhNDMzMWU3ZTAxMTc2NmVhZWM0NjkiLCJpYXQiOjE2ODM3MTk3Njh9.QRn-02FN2ruf5sW--G8aVbeHQ0rKemCJbdpYU5_Qu9A");


  }, []);
  const onFormSubmit = (formData) => {
    setLoading(true);
    if (isSuperAdmin) {
      new ServiceStore().Login('superadmin/login', formData).then((res) => {
        if (res.response.status === 1) {
          setLoading(false);
          localStorage.setItem("accessToken", JSON.stringify(res.response.data));
          sessionStorage.setItem("user", JSON.stringify(res.response.data));
        }
        else{
          toast.error("Login failed")
          setLoading(false);
        }
        // Redirect user on successful login
        // window.history.pushState(
        //   `${process.env.PUBLIC_URL ? "/adminuserlist" : "/adminuserlist"}`,
        //   "auth-login",
        //   `${process.env.PUBLIC_URL ? "/adminuserlist" : "/adminuserlist"}`
        // );
        // window.location.reload();
      }).catch((error) => {
        setLoading(false);
        setError("Cannot login with credentials"); // Set error message here
      });
    }
    else {
      console.log("Normal Admin API")
      const body = {
        email: formData.email,
        password: formData.password,
      }
      new ServiceStore().Login('/adminuser/login', body).then((res) => {
        console.log("res ---78 ", res.response.data.message)
        if (res.response.status === 1) {
          setLoading(false);
          localStorage.setItem("accessToken", JSON.stringify(res.response.data));
          localStorage.setItem("token", res.response.token);
          //Redirect user on successful login
          window.history.pushState(
            `${process.env.PUBLIC_URL ? "/adminuserlist" : "/adminuserlist"}`,
            "auth-login",
            `${process.env.PUBLIC_URL ? "/adminuserlist" : "/adminuserlist"}`
          );

          window.location.reload();
        }else if(res?.response?.data?.message === "Password is inavlid"){
          toast.error("Invalid User Name OR Password !.", {
            autoClose: 2000,
          });
          setLoading(false);
        }
        else {
          toast.error("Login failed")
          setLoading(false);
        }
      }).catch((error) => {
        setLoading(false);
        setError("Cannot login with credentials"); // Set error message here
      });
    }
  };

  const handleAdmin = (e) => {
    setIsSuperAdmin(e.target.checked);
  }

  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
    <ToastContainer />
      <Head title="Login" />
      <PageContainer >
        <Block className="nk-block-middle nk-auth-body  wide-xs background-img">
          {/* <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div> */}

          <PreviewCard className="card-bordered card-css" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Log-In</BlockTitle>
                <BlockDes className="image-text">
                  <img src="../../images/logopuneriver.png" className="login-logo" />
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> Unable to login with credentials{" "}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email or Username
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    name="email"
                    ref={register({ required: "This field is required" })}
                    placeholder="Enter your email address or username"
                    className="form-control-lg form-control"
                  />
                  {errors.email && <span className="invalid">{errors.email.message}</span>}
                </div>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Passcode
                  </label>
                  <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                    Forgot Code?
                  </Link>
                </div>
                <div className="form-control-wrap">
                  <a

                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    name="password"
                    // defaultValue="123456"
                    ref={register({ required: "This field is required" })}
                    placeholder="Enter your password"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
                  {errors.password && <span className="invalid">{errors.password.message}</span>}
                </div>
              </div>
              <div className="form-group">
                <input type="checkbox" onChange={handleAdmin} name="Admin" /> <Label>Are you Super Admin</Label>
              </div>
              <div className="form-group">
              {
                loading ? <Spinner size="sm" color="light" /> : 
                <Button size="lg" className="btn-block" type="submit" color="primary">
                  Sign in
                </Button>
              }
                
              </div>
            </Form>


          </PreviewCard>
        </Block>
        {/* <AuthFooter /> */}
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
